.pedigree--info_width {
    margin-left: 2%;
}

.pedigree--website--desktop {
    margin-bottom: 17px;
    font-size: 13px;
}

.pedigree--website--print {
    top: 25px;
    left: 190px;
    height: 50px;
}

.pedigree--fancier_info--desktop {
    bottom: 20px;
    left: 20px;
    width: 180px;
}

.pedigree--fancier_info--print {
    bottom: 40px;
    left: -10px;
    width: 200px;
}

.pedigree--watermark_desktop {
    bottom: 150px;
    left: 20px;
    width: 180px;
}

.pedigree--watermark_print {
    bottom: 180px;
    left: -10px;
    width: 180px;
}

@media (min-width: 640px) {
    .pedigree--info_width {
        margin-left: 5%;
    }
}

@media (min-width: 1024px) {
    .pedigree--info_width {
        margin-left: 2%;
    }
}

@media (min-width: 1280px) {
    .pedigree--info_width {
        margin-left: 10%;
    }
}

@media (min-width: 1404px) {
    .pedigree--info_width {
        margin-left: 16.7%;
    }
}

.pedigree--pigeon_gender {
    position: absolute;
    top: 0;
    left: 150px;
    line-height: 23px;
}

.pedigree--pigeon_ringnumber {
    display: block;
    margin-top: -5px;
    color: #e53e3e;
    font-size: 14px;
    font-weight: 700;
}

.pedigree--pigeon_name {
    display: block;
    font-size: 13.5px;
    font-weight: 700;
}

.pedigree--pigeon_owner {
    display: block;
    margin-top: 0.1rem;
    font-size: 13px;
}

.pedigree--pigeon_color {
    display: block;
    margin-top: 0.1rem;
    font-size: 13px;
}

.pedigree--pigeon_info {
    display: block;
    overflow: hidden;
    margin-top: 0.1rem;
    padding: 1px;
    border-radius: 5px;
    background-color: #f5f5f5 !important;
    font-size: 13px;
    overflow-wrap: break-word;
}

.pedigree--pigeon_info blockquote {
    font-size: 13px;
}

.gparent-height {
    overflow: hidden;
    max-height: 220px !important;
}

.gparent-height .pedigree--pigeon_info {
    overflow: hidden;
}

.ggparent {
    overflow: hidden;
    max-height: 40px;
    font-size: 12px;
}

.ggparent-height {
    min-height: 90px !important;
    max-height: 140px !important;
}

.ggparent-height-2 {
    min-height: 90px !important;
    max-height: 108px !important;
}

#tree-simple blockquote {
    overflow: hidden;
    margin-right: 0;
    margin-left: 0;
    margin-left: 0.5em;
    padding-right: 0.5em;
    padding-left: 0.5em;
    border-left: 5px solid #ccc;
    font-size: 12px;
    font-style: italic;
}

#tree-simple,
#pedigree-preview {
    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 17px;
    }

    h4 {
        font-size: 15px;
    }

    ul {
        list-style-position: inside;
        list-style-type: disc;
    }

    ol {
        list-style-position: inside;
        list-style-type: decimal;
    }

    li {
        list-style: unset;
    }
}
