.blocks-view {
    z-index: 1;
    overflow: hidden;
    transition: all 0.2s ease;
}

/* Block specific resets */
.blocks-view a {
    color: #212529;
}

.blocks-view a:hover {
    text-decoration: none;
}

.blocks-view .container .row a {
    width: 100%;
}

// .blocks-view p {
//     margin-top: 0.2rem;
//     margin-bottom: 0.7rem;
// }

.blocks-view hr {
    width: 100%;
}

@media (min-width: 768px) {
    .blocks-view-row {
        padding: 15px;
        border: 2px solid white;
        background-color: #9e9e9e;
    }
    .node-height {
        min-height: 300px !important;
        max-height: 300px !important;
    }
    .parent-height {
        min-height: 200px !important;
        max-height: 200px !important;
    }
    .grandparent-height {
        min-height: 120px !important;
        max-height: 120px !important;
    }
    .greatgrandparent-height {
        min-height: 72px !important;
        max-height: 72px !important;
    }

    .mt-ggrandparent-inner {
        margin-top: 1.9rem;
    }
    .mt-ggrandparent-middle {
        margin-top: 4rem;
    }

    .mb-grandparent {
        margin-bottom: 70px;
    }
    .mb-parent {
        margin-bottom: 100px;
    }
}
