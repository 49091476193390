
// Container that the modal scrolls within
.modal {
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;
    display: none;
    overflow: hidden;
    width: 100%;
    height: 100%;
    // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
    // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
    // See also https://github.com/twbs/bootstrap/issues/17695
    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;
}

.modal-open {
    // Kill the scroll on the body
    overflow: hidden;

    .modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

body.modal-open {
    overflow: inherit;
    padding-right: 0 !important;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    // allow clicks to pass through for custom click handling to close modal
    pointer-events: none;

    // When fading in the modal, animate it to slide down
    .modal.fade & {
        transform: translate(0, -50px);
    }
    .modal.show & {
        transform: none;
    }
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
    pointer-events: auto;
    // counteract the pointer-events: none; in the .modal-dialog
    color: #545669;
    border: 1px solid rgba(#000, 0.2);
    border-radius: 2.5px;
    // Remove focus outline from opened modal
    outline: 0;
    background-color: #fff;
    background-clip: padding-box;
}

// Modal background
.modal-backdrop {
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    border-radius: 2.5px;
    background-color: #000;

    // Fade for backdrop
    &.fade { opacity: 0; }
    &.show { opacity: 0.5; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    display: flex;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    align-items: flex-start; // so the close btn always stays on the upper right corner
    justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends

    .close {
        // auto on the left force icon to the right even when there is no .modal-title
        margin: (-1rem) (-1rem) (-1rem) auto;
        padding: 1rem;
    }
}

// Title text within header
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    position: relative;
    padding: 1rem;
    border-radius: 2.5px;
    // Enable `flex-grow: 1` so that the body take up as much space as possible
    // when should there be a fixed height on `.modal-dialog`.
    flex: 1 1 auto;
}

// Footer (for actions)
.modal-footer {
    display: flex;
    padding: 1rem;
    border-top: 1px solid #e3e8ee;
    align-items: center; // vertically center
    justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items

    // Easily place margin between footer elements
    > :not(:first-child) { margin-left: 0.25rem; }
    > :not(:last-child) { margin-right: 0.25rem; }
}

.modal-dialog-scrollable {
    display: flex; // IE10/11
    max-height: calc(100% - #{0.5rem * 2});

    .modal-content {
        overflow: hidden;
        max-height: calc(100vh - #{0.5rem * 2}); // IE10/11
    }

    .modal-header,
    .modal-footer {
        flex-shrink: 0;
    }

    .modal-body {
        overflow-y: auto;
    }
}

.modal-dialog-centered {
    display: flex;
    min-height: calc(100% - #{0.5rem * 2});
    align-items: center;

    // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
    &::before {
        display: block; // IE10
        height: calc(100vh - #{0.5rem * 2});
        content: "";
    }

    // Ensure `.modal-body` shows scrollbar (IE10/11)
    &.modal-dialog-scrollable {
        flex-direction: column;
        height: 100%;
        justify-content: center;

        .modal-content {
            max-height: none;
        }

        &::before {
            content: none;
        }
    }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    overflow: scroll;
    width: 50px;
    height: 50px;
}

// Scale up the modal
@media (min-width: 640px) {
    // Automatically set modal's width for larger viewports
    .modal-dialog {
        max-width: 500px;
        margin: 0.5rem auto;
    }

    .modal-content {
        box-shadow: 0 0.5rem 1rem rgba(#000, 0.5);
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - #{1.75rem * 2});

        .modal-content {
            max-height: calc(100vh - #{1.75rem * 2});
        }
    }

    .modal-dialog-centered {
        min-height: calc(100% - #{1.75rem * 2});

        &::before {
            height: calc(100vh - #{1.75rem * 2});
        }
    }

    .modal-sm { max-width: 300px; }
}

@media (min-width: 1024px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1280px) {
    .modal-xl { max-width: 1140px; }
}
