.text-right {
    text-align: right;
}

.comments {
    margin-top: 10px;
}

.comments-app {
    width: 100%;
}

.comments-app h1 {
    margin-bottom: 1.5em;
    text-align: center;
    color: #191919;
    text-shadow: 0 0 2px rgba(152, 152, 152, 1);
}

.comment-v {
    margin-left: 70px;
}

.comment-form .form .form-row:last-child {
    margin-bottom: 0;
}

.comment-form .form .input {
    width: 100%;
    min-height: 60px;
    padding: 5px 10px;
    -webkit-transition: 350ms box-shadow;
    -moz-transition: 350ms box-shadow;
    -ms-transition: 350ms box-shadow;
    -o-transition: 350ms box-shadow;
    transition: 350ms box-shadow;
    color: #555f77;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    outline: none;
    background-color: #fcfcfc;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    font-size: 14px;
}

.comment-form .form textarea.input {
    height: 100px;
    padding: 15px;
}

.comment-form .form label {
    color: #555f77;
    font-size: 14px;
}

.comment-form .form input[type=submit] {
    display: block;
    margin-left: auto;
    padding: 6px 15px;
    cursor: pointer;
    -webkit-transition: 350ms box-shadow;
    -moz-transition: 350ms box-shadow;
    -ms-transition: 350ms box-shadow;
    -o-transition: 350ms box-shadow;
    transition: 350ms box-shadow;
    color: #fff;
    border: none;
    outline: none;
    background-color: #555f77;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.comment-form .form .input:focus,
.comment-form .form input[type=submit]:focus,
.comment-form .form input[type=submit]:hover {
    box-shadow: 0 6px 20px 4px rgba(121, 137, 148, 0.55);
}

.comment-form .form.ng-submitted .input.ng-invalid,
.comment-form .form .input.ng-dirty.ng-invalid {
    box-shadow: 0 2px 6px rgba(212, 47, 47, 0.55) !important;
}

.comment-form .form .input.disabled {
    background-color: #e8e8e8;
}

.comment-form,
.comment {
    position: relative;
    z-index: 0;
}

.comment-form .comment-avatar,
.comment .comment-avatar {
    position: absolute;
    top: 15px;
    left: 0;
    overflow: hidden;
    width: 60px;
    height: 60px;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.comment-form .comment-avatar img,
.comment .comment-avatar img {
    display: block;
    width: 100%;
    height: auto;
}

.comment .comment-box {
    position: relative;
    min-height: 60px;
    margin-left: 55px;
    padding: 15px;
    border-radius: 4px;
    background-color: #fcfcfc;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.comment .comment-box::before,
.comment .comment-box::after {
    position: absolute;
    top: 20px;
    left: -10px;
    content: "";
    border-width: 10px 10px 10px 0;
    border-style: solid;
    border-color: transparent #fcfcfc;
}

.comment .comment-box::before {
    top: 22px;
    border-color: transparent rgba(0, 0, 0, 0.05);
}

.comment .comment-text {
    color: #555f77;
    font-size: 15px;
}

.comment-actions li a {
    cursor: pointer;
}

.comment .comment-footer {
    color: #acb4c2;
    font-size: 13px;
}

.comment .comment-footer::after {
    display: table;
    clear: both;
    content: "";
}

.comment .comment-footer a {
    -webkit-transition: 350ms color;
    -moz-transition: 350ms color;
    -ms-transition: 350ms color;
    -o-transition: 350ms color;
    transition: 350ms color;
    text-decoration: none;
    color: #acb4c2;
}

.comment .comment-footer a:hover {
    text-decoration: underline;
    color: #555f77;
}

.comment .comment-info {
    float: left;
    width: 60%;
}

#app .comment-actions li a:hover {
    color: #4e566d;
}

.reply {
    margin-top: 5px;
    margin-left: 60px;
}

@media screen and (max-width: 766px) {
    .comment-form .comment-avatar,
    .comment .comment-avatar {
        width: 40px;
        height: 40px;
    }

    .comment .comment-box::before,
    .comment .comment-box::after {
        position: absolute;
        top: 20px;
        left: -10px;
        content: "";
    }
    .comment-actions {
        text-align: left;
    }
    .comment .comment-info {
        width: 100%;
    }
    .comment-actions li {
        width: 100%;
    }
    .reply {
        margin-left: 15px;
    }
}

@media screen and (min-width: 766px) {
    .comment .comment-box {
        margin-left: 70px;
    }
}

.vanboven {
    display: none; /* Temporary */
}
