::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    transition: all 0.3s ease;
    border-radius: 4px;
    background-color: #fff;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #cacdd1;
}

::-webkit-scrollbar:hover {
    background-color: #eee;
}
