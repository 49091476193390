// Base class
.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    // Allow breaking very long words so they don't overflow the tooltip's bounds
    word-wrap: break-word;
    opacity: 0;
    // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
    // So reset our font and text properties to avoid inheriting weird values.

    &.show {
        opacity: 0.9;
    }

    .arrow {
        position: absolute;
        display: block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 5px;
        font-size: 0.8em;

        &::before {
            position: absolute;
            content: "";
            border-style: solid;
            border-color: transparent;
        }
    }
}

.bs-tooltip-top {
    padding: 0.4rem 0;

    .arrow {
        bottom: 0;

        &::before {
            top: 0;
            border-width: 0.4rem calc(0.8rem / 2) 0;
            border-top-color: #000;
        }
    }
}

.bs-tooltip-right {
    padding: 0 0.4rem;

    .arrow {
        left: 0;
        width: 0.4rem;
        height: 0.8rem;

        &::before {
            right: 0;
            border-width: calc(0.8rem / 2) 0.4rem calc(0.8rem / 2) 0;
            border-right-color: #000;
        }
    }
}

.bs-tooltip-bottom {
    padding: 0.4rem 0;

    .arrow {
        top: 0;

        &::before {
            bottom: 0;
            border-width: 0 calc(0.8rem / 2) 0.4rem;
            border-bottom-color: #000;
        }
    }
}

.bs-tooltip-left {
    padding: 0 0.4rem;

    .arrow {
        right: 0;
        width: 0.4rem;
        height: 0.8rem;

        &::before {
            left: 0;
            border-width: calc(0.8rem / 2) 0 calc(0.8rem / 2) 0.4rem;
            border-left-color: #000;
        }
    }
}

.bs-tooltip-auto {
    &[x-placement^="top"] {
        .bs-tooltip-top {
            padding: 0.4rem 0;

            .arrow {
                bottom: 0;

                &::before {
                    top: 0;
                    border-width: 0.4rem calc(0.8rem / 2) 0;
                    border-top-color: #000;
                }
            }
        }
    }

    &[x-placement^="right"] {
        .bs-tooltip-right {
            padding: 0 0.4rem;

            .arrow {
                left: 0;
                width: 0.4rem;
                height: 0.8rem;

                &::before {
                    right: 0;
                    border-width: calc(0.8rem / 2) 0.4rem calc(0.8rem / 2) 0;
                    border-right-color: #000;
                }
            }
        }
    }

    &[x-placement^="bottom"] {
        .bs-tooltip-bottom {
            padding: 0.4rem 0;

            .arrow {
                top: 0;

                &::before {
                    bottom: 0;
                    border-width: 0 calc(0.8rem / 2) 0.4rem;
                    border-bottom-color: #000;
                }
            }
        }
    }

    &[x-placement^="left"] {
        .bs-tooltip-left {
            padding: 0 0.4rem;

            .arrow {
                right: 0;
                width: 0.4rem;
                height: 0.8rem;

                &::before {
                    left: 0;
                    border-width: calc(0.8rem / 2) 0 calc(0.8rem / 2) 0.4rem;
                    border-left-color: #000;
                }
            }
        }
    }
}

// Wrapper for the tooltip content
.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    text-align: center;
    color: #fff;
    background-color: #000;
}