ul {
    margin: 0;
    padding: 0;
}

h1 { margin: 0; }
li { list-style: none; }
a { text-decoration: none; }

hr {
    height: 0;
    margin: 0.5rem 0;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.header {
    &__nav {
        position: relative;
    }
    &__navbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
    &__item {
        position: relative;
        //padding: 1rem;
        padding: 8px 13px;
    }
    &--icon {
        display: inline-flex;
        width: 1.65rem;
        height: 1.65rem;
        fill: currentcolor;
    }
    &--btn {
        display: inline-block;
        padding: 0;
        cursor: pointer;
        border: 1px solid transparent;
        background-color: transparent;
        appearance: none;
        user-select: none;
    }
    &__link {
        .header--btn {
            position: absolute;
            top: 0;
            right: 0;
            &:focus {
                outline: 0;
            }
        }
    }
    &__dropdown-menu {
        position: absolute;
        z-index: 100;
        top: 100%;
        right: 0;
        overflow-y: auto;
        min-width: 280px;
        margin-top: 1.62rem;
        // border: 1px solid #dadce0;
        border-radius: 8px;
        background-color: white;
        background-clip: padding-box;
    }
    &__dropdown-menu-link {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.4rem;
        padding: 0.6rem 1.5rem 0.6rem 1.5rem;
        color: #3c4043;
        border-radius: 0 50px 50px 0;
        line-height: 0;
        align-items: center;
        justify-content: flex-start;
        &:hover {
            background-color: rgba(0, 0, 0, 0.039);
        }
    }
    &__dropdown-menu-svg {
        padding-right: 16px;
        .header--icon {
            width: 1.4rem;
            height: 1.4rem;
        }
    }
}

// Animation slide button
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.6s;
}

.slide-fade-enter,
.slide-fade-leave-active {
    opacity: 0;
}

.slide-fade-enter {
    transform: translateX(31px);
}

.slide-fade-leave-active {
    transform: translateX(-31px);
}

// Dropdown animation
.dropdown-enter-active,
.dropdown-leave-active {
    transition: all 1s;
}

.dropdown-enter,
.dropdown-leave-to {
    transform: translateY(30px);
    opacity: 0;
}

// .wrapper {
//     display: flex;
//     flex-direction: column;
//     height: 70vh;
//     align-items: center;
//     justify-content: center;
//     .social {
//         margin-top: 1rem;
//     }
//     a {
//         padding: 0.6rem;
//     }
//     h1 {
//         color: #42b983;
//         font-size: 2rem;
//         font-weight: 300;
//     }
//     p {
//         color: #7f8c8d;
//         font-weight: 100;
//     }
//     svg {
//         display: inline-flex;
//         width: 1.65rem;
//         height: 1.65rem;
//         color: #b8c2cc;
//         fill: currentcolor;
//     }
// }

@media (max-width: 767px) {
    .header__dropdown-menu {
        position: absolute;
        z-index: 100;
        // top: 100%;
        // right: 0;
        left: -244px;
        overflow-y: auto;
        width: 100vw;
        margin-top: 11px;
        // margin-right: auto;
        // margin-left: auto;
        // border: 1px solid #dadce0;
        border-radius: 0.25rem;
        background-color: white;
        background-clip: padding-box;
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    }

    .header__dropdown-menu-profile {
        left: -301px;
    }
}
