.single-user ul li {
    list-style: none;
}

.single-user ul li a {
    text-decoration: none;
}

.single-user ul li a:hover {
    opacity: 0.7;
}

.single-user h2 {
    display: inline-block;
}

.single-user .gender-symbol {
    font-size: 75px;
}

.modal-dialog ul li {
    list-style: none;
}

/* Have to overwrite standard CSS to make the JS work */
.action-follow strong {
    font-weight: normal;
}

.follow {
    display: block;
}

.follow-active {
    border-radius: 5px;
    background-color: #e8e8e8;
}

.profileImg {
    // border: 2px solid #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card-avatar {
    display: inline;
    float: left;
    width: 20%;
}

@media (min-width: 0) and (max-width: 767px) {
    .single-user img {
        display: block;
        width: 50%;
        margin: 0 auto;
    }
}

@media (min-width: 576px) {
    /* Overwrite bootstrap */
    .modal-dialog {
        margin: 5rem auto !important;
    }
}

#app .post-create textarea {
    border: 1px solid #fff !important;
    border-radius: 0;
    outline: none;
    background-color: #eee;
}
