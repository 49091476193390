.avatar-container .avatar-edit-button {
    position: absolute;
    bottom: 0;
    // left: 0;
    // display: none;
    // width: 100%;
    // height: 25%;
    cursor: pointer;
    color: #fff;
}

.avatar-container:hover .avatar-edit-button {
    display: block;
    padding: 0 25px;
    background: rgba(0, 0, 0, 0.6);
    font-size: 30px;
}

.edit-slide-text {
    display: none;
    transition: display 2s linear;
}

.avatar-container .edit-slide-text {
    display: inline;
    transition: display 2s linear;
}

.vue-position {
    position: inherit;
}

.avatar {
    overflow: hidden;
    width: 40px;
    height: 40px;
    padding: 0;
    transition-property: none;
    background: #fdfdfd;
}

// .s160.avatar {
//     width: 160px;
//     height: 160px;
//     margin-right: 20px;
// }

// .s20.avatar {
//     width: 20px;
//     height: 20px;
//     margin-right: 5px;
//     border-radius: 50%;
// }

@media (min-width: 576px) {
    .avatar-image {
        float: left;
        margin-bottom: 0;
    }
}
