.outline-box-shadow {
    outline: 0 0 0 0.2rem rgba(84, 86, 105, 0.25);
}

.landcode {
    display: inline-block;
    width: 23px;
    font-weight: bold;
}

.disabled:disabled {
    background: #ddd;
}

.block-important {
    display: block !important;
}

.flex-basis {
    flex-basis: 100%;
}

.vicp-range {
    display: none;
}

.user-favorite-result-list .table-wrapper {
    overflow-x: auto;
}
