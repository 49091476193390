
/* Restyle checkbox switches */
input.apple-switch {
    position: relative;
    width: 50px !important;
    height: 24px;
    cursor: pointer;
    transition-duration: 500ms;
    border: 1px solid #d9dadc !important;
    border-radius: 50px;
    outline: none;
    background-color: #d9dadc;
    box-shadow: inset -20px 0 0 0 #fff;
    appearance: none;
}

.apple-switch {
    position: relative;
    overflow: hidden;
    width: 13px;
    height: 13px;
    margin: 0;
    padding: 0;
    vertical-align: bottom;
}

input.apple-switch::after {
    top: 1px;
    left: 1px;
    width: 26px;
    height: 26px;
    content: "";
    border-radius: 50%;
    background-color: transparent;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.apple-switch:checked {
    border-color: #579380;
    box-shadow: inset 20px 0 0 0 #579380;
}

input.apple-switch:checked::after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}

#app .form-check,
#stripe .form-check {
    margin: 10px 0 10px 0;
}

#app .form-check-label,
#stripe .form-check-label {
    margin-bottom: 0.5rem;
    font-size: 11px;
}
