.notifications-wrapper {
    min-width: 300px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 640px) {
    .notifications-wrapper {
        min-width: 300px;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
    .notifications-wrapper {
        width: 400px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1024px) {
    .notifications-wrapper {
        width: 500px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1280px) {
    .notifications-wrapper {
        width: 550px;
    }
}
