.top-media {
    top: 42px;
}

@media (min-width: 640px) {
    .top-media {
        top: 47px;
    }
}

@media (min-width: 1024px) {
    .top-media {
        top: 47px;
    }
}

@media (min-width: 1280px) {
    .top-media {
        top: 47px;
    }
}

@media (min-width: 1404px) {
    .top-media {
        top: 47px;
    }
}
