#app .table-sm th,
#app .table-sm td {
    padding: 0;
}

#app .table .thead-dark th {
    padding: 5px 0;
    color: #4f566b;
    border-top: 1px solid rgb(128, 128, 128);
    border-bottom: 1px solid rgb(128, 128, 128);
    background-color: #f7fafc;
    font-weight: normal;
}

#app .table .thead-dark th a {
    color: #4f566b;
    font-weight: normal;
}

.Table-cell--wrap--noWrap {
    white-space: nowrap;
}

#app .hover-cell .btn {
    width: 100%;
    height: 100%;
    padding: 4px;
}

/* table fit helpers */
.table td.fit,
.table th.fit {
    white-space: nowrap;
}

.dataTables_wrapper .dataTables_filter input {
    @apply shadow;
}

.table-responsive select {
    @apply shadow;
}

@media screen and (max-width: 768px) {
    .table td.fit,
    .table th.fit {
        width: 1%;
    }
}
