.help-tip {
    position: absolute;
    top: -2px;
    right: -15px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    text-align: center;
    border-radius: 50%;
    background-color: #59c9a5;
    font-size: 9px;
}

.help-tip::before {
    content: '?';
    color: #fff;
    font-weight: bold;
}

.exclamation-mark {
    position: absolute;
    top: 4px;
    right: -14px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    text-align: center;
    border-radius: 50%;
    background-color: #e3781e;
    font-size: 9px;
}

.exclamation-mark::before {
    content: '!';
    color: #fff;
    font-weight: bold;
}

.help-tip p {    /* The tooltip */
    position: absolute;
    top: 35px;
    right: -8px;
    display: none;
    width: 300px;
    padding: 5px;
    text-align: left;
    color: #fff;
    border-radius: 3px;
    background-color: rgba(30, 32, 33, 0.7);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    font-size: 13px;
    line-height: 1.4;
}

.help-tip:hover p {
    display: block;
    transform-origin: 100% 0%;
    -webkit-animation: fadeIn 0.3s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;
}

.help-tip p::before { /* The pointer of the tooltip */
    position: absolute;
    top: -12px;
    right: 10px;
    width: 0;
    height: 0;
    content: '';
    border: 6px solid transparent;
    border-bottom-color: #1e2021;
}

.help-tip p::after { /* Prevents the tooltip from being hidden */
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 40px;
    content: '';
}

/* CSS animation */

@-webkit-keyframes fadeIn {
    0% {
        transform: scale(0.6);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 100%;
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 100%; }
}
