@function hsb($h-hsb, $s-hsb, $b-hsb, $a: 1) {
    @if $b-hsb==0 {
        @return hsla(0, 0, 0, $a);
    }

    @else {
        $l-hsl: calc($b-hsb / 2) * (2 - calc($s-hsb / 100));
        $s-hsl: calc(($b-hsb * $s-hsb) / if($l-hsl < 50, $l-hsl * 2, 200 - $l-hsl * 2));
        @return hsla(calc($h-hsb / 1%) * 1%, $s-hsl * 1%, $l-hsl * 1%, $a);
    }
}

@function hsb-saturate($s, $a) {
    @if $s==0 {
        @return 0;
    }

    @else {
        @return $s+$a;
    }
}

@mixin buttonize($h, $s, $b, $light: 0) {

    $color: hsb($h, $s, $b);
    $color-dark: hsb($h, hsb-saturate($s, 10), $b);
    $inset-top: inset 0 1px 0 hsb($h, $s - 20, $b + 10);
    $inset-bottom: inset 0 -1px 0 hsb($h, hsb-saturate($s, 10), $b - 10);
    $inset: inset 0 0 0 1px hsb($h, hsb-saturate($s, - 10), $b);

    border: 1px solid hsb($h, hsb-saturate($s, 20), $b - 20);
    background-color: $color;
    background-image: linear-gradient(to bottom, $color, $color-dark);
    box-shadow: $inset-top, $inset-bottom, $inset, 0 2px 4px rgba(black, 0.2);

    @if $light==1 {
        color: hsb($h, hsb-saturate($s, 40), $b - 40);
        text-shadow: 0 1px 0 rgba(white, 0.5);
    }

    @else {
        color: white;
        text-shadow: 0 1px 2px rgba(black, 0.3);
    }

    &:hover,
    &:focus {
        border-color: hsb($h, hsb-saturate($s, 40), $b - 40);
        background: $color-dark;
        box-shadow: $inset-top, $inset-bottom, $inset;
    }

    &:active {
        background: $color-dark;
        box-shadow: inset 0 2px 3px rgba(black, 0.2);
    }

    .fa {
        color: hsb($h, hsb-saturate($s, 20), $b - 20);
        text-shadow: 0 1px 0 rgba(white, 0.3);
    }

}

.button-metallic {
    display: inline-block;
    padding: 5px 7px;
    cursor: pointer;
    transition: all 20ms ease-out;
    vertical-align: top;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    @include buttonize(0, 0, 95, 1);
}

.button-metallic--blue {
    padding: 5px 7px;
    border-radius: 3px;
    @include buttonize(199, 71, 89);
}

.button-metallic--green {
    padding: 5px 7px;
    border-radius: 3px;
    @include buttonize(97, 42, 80);
}

// .button-metallic--purple{ @include buttonize(249, 34, 73);}
// .button-metallic--orange{ @include buttonize(26, 77, 96);}
// .button-metallic--red{ @include buttonize(4, 58, 93);}
// .button-metallic--yellow{ @include buttonize(49, 54, 99, 1);}

.button-metallic .fa {
    float: left;
    margin: -1px 8px 0 -4px;
    vertical-align: top;
    font-size: 14px;
    line-height: 20px;
}