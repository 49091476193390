.red-icon {
    color: #e3342f;
}

.green-icon {
    color: #38c172;
}

.purple-icon {
    color: #9561e2;
}

.yellow-icon {
    color: #ffa500;
}

.btn-middle {
    display: block !important;
    margin: 0 auto;
    font-size: 16px !important;
}

#app .btn-lg-tooltip {
    padding: 0;
    font-size: 1.5rem;
}
