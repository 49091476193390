.disabled {
    cursor: default;
    pointer-events: none;
}

.lds-ellipsis {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
}

.lds-ellipsis div {
    position: absolute;
    top: 2px;
    width: 11px;
    height: 11px;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
}

.lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}
