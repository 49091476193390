.light-search-prepend {
    color: $primary !important;
    background-color: #fff !important;
}

.dark-search-prepend {
    color: #fff !important;
    background-color: $primary !important;
}

#app .input-group-text {
    border: none;
    border-radius: 0.25rem;
}

#app .dropdown-toggle::after {
    margin-left: 0.05em;
    vertical-align: 0;
}

.user-search-results .card:hover {
    border: 1px solid #333;
}

.user-name {
    font-size: 13px;
}
