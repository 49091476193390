@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");
/**
 * Base
 */
/**
 * Bootstrap components
 */
.modal {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  display: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  outline: 0;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

body.modal-open {
  overflow: inherit;
  padding-right: 0 !important;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transform: translate(0, -50px);
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  color: #545669;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2.5px;
  outline: 0;
  background-color: #fff;
  background-clip: padding-box;
}

.modal-backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border-radius: 2.5px;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  align-items: flex-start;
  justify-content: space-between;
}
.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  padding: 1rem;
  border-radius: 2.5px;
  flex: 1 1 auto;
}

.modal-footer {
  display: flex;
  padding: 1rem;
  border-top: 1px solid #e3e8ee;
  align-items: center;
  justify-content: flex-end;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  overflow: hidden;
  max-height: calc(100vh - 1rem);
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  min-height: calc(100% - 1rem);
  align-items: center;
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  overflow: scroll;
  width: 50px;
  height: 50px;
}

@media (min-width: 640px) {
  .modal-dialog {
    max-width: 500px;
    margin: 0.5rem auto;
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 1024px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1280px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.popover {
  position: absolute;
  z-index: 1060;
  top: 0;
  left: 0;
  display: block;
  max-width: 276px;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
  background-clip: padding-box;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5em;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-style: solid;
  border-color: transparent;
}

.tooltip .arrow {
  border-right: 5px solid transparent;
  border-bottom: 6px solid #545669;
  border-left: 1px solid transparent;
}

.tooltip-inner {
  border-radius: 5px;
  background-color: #545669 !important;
  font-size: 0.8em;
}

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #545669 !important;
}

.bs-popover-top {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.2);
}
.bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.2);
}
.bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -1rem/2;
  content: "";
  border-bottom: 1px solid #fff;
}

.bs-popover-left {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.2);
}
.bs-popover-left > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.2);
}
.bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.2);
}
.bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -1rem/2;
  content: "";
  border-bottom: 1px solid #fff;
}
.bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.2);
}
.bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  margin-bottom: 0;
  padding: 0.5rem 0.75rem;
  color: #212529;
  border-bottom: 1px solid #f2f2f2;
  background-color: #fff;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 5px;
  font-size: 0.8em;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-style: solid;
  border-color: transparent;
}

.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.bs-tooltip-auto[x-placement^=top] .bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^=top] .bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^=top] .bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-auto[x-placement^=right] .bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^=right] .bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^=right] .bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-auto[x-placement^=bottom] .bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^=bottom] .bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^=bottom] .bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^=left] .bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^=left] .bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^=left] .bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  text-align: center;
  color: #fff;
  background-color: #000;
}

/**
 * Browser
 */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  transition: all 0.3s ease;
  border-radius: 4px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #cacdd1;
}

::-webkit-scrollbar:hover {
  background-color: #eee;
}

/**
 * Buefy
 */
/**
 * Overrides
 */
.control.has-icons-left .icon,
.control.has-icons-right .icon {
  width: 2.75em !important;
  height: 3.1em !important;
}

/**
 * Components
 */
/* Restyle checkbox switches */
input.apple-switch {
  position: relative;
  width: 50px !important;
  height: 24px;
  cursor: pointer;
  transition-duration: 500ms;
  border: 1px solid #d9dadc !important;
  border-radius: 50px;
  outline: none;
  background-color: #d9dadc;
  box-shadow: inset -20px 0 0 0 #fff;
  appearance: none;
}

.apple-switch {
  position: relative;
  overflow: hidden;
  width: 13px;
  height: 13px;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
}

input.apple-switch::after {
  top: 1px;
  left: 1px;
  width: 26px;
  height: 26px;
  content: "";
  border-radius: 50%;
  background-color: transparent;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.apple-switch:checked {
  border-color: #579380;
  box-shadow: inset 20px 0 0 0 #579380;
}

input.apple-switch:checked::after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}

#app .form-check,
#stripe .form-check {
  margin: 10px 0 10px 0;
}

#app .form-check-label,
#stripe .form-check-label {
  margin-bottom: 0.5rem;
  font-size: 11px;
}

.ck-content h2 {
  font-size: 19px;
}
.ck-content h3 {
  font-size: 17px;
}
.ck-content h4 {
  font-size: 15px;
}
.ck-content p {
  font-size: 13px;
}
.ck-content ul {
  list-style-position: inside;
  list-style-type: disc;
}
.ck-content ol {
  list-style-position: inside;
  list-style-type: decimal;
}
.ck-content li {
  list-style: unset;
  font-size: 13px;
}

.top-media {
  top: 42px;
}

@media (min-width: 640px) {
  .top-media {
    top: 47px;
  }
}
@media (min-width: 1024px) {
  .top-media {
    top: 47px;
  }
}
@media (min-width: 1280px) {
  .top-media {
    top: 47px;
  }
}
@media (min-width: 1404px) {
  .top-media {
    top: 47px;
  }
}
.disabled {
  cursor: default;
  pointer-events: none;
}

.lds-ellipsis {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
}

.lds-ellipsis div {
  position: absolute;
  top: 2px;
  width: 11px;
  height: 11px;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
.help-tip {
  position: absolute;
  top: -2px;
  right: -15px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  background-color: #59c9a5;
  font-size: 9px;
}

.help-tip::before {
  content: "?";
  color: #fff;
  font-weight: bold;
}

.exclamation-mark {
  position: absolute;
  top: 4px;
  right: -14px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  background-color: #e3781e;
  font-size: 9px;
}

.exclamation-mark::before {
  content: "!";
  color: #fff;
  font-weight: bold;
}

.help-tip p { /* The tooltip */
  position: absolute;
  top: 35px;
  right: -8px;
  display: none;
  width: 300px;
  padding: 5px;
  text-align: left;
  color: #fff;
  border-radius: 3px;
  background-color: rgba(30, 32, 33, 0.7);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 13px;
  line-height: 1.4;
}

.help-tip:hover p {
  display: block;
  transform-origin: 100% 0%;
  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
}

.help-tip p::before { /* The pointer of the tooltip */
  position: absolute;
  top: -12px;
  right: 10px;
  width: 0;
  height: 0;
  content: "";
  border: 6px solid transparent;
  border-bottom-color: #1e2021;
}

.help-tip p::after { /* Prevents the tooltip from being hidden */
  position: absolute;
  top: -40px;
  left: 0;
  width: 100%;
  height: 40px;
  content: "";
}

/* CSS animation */
@-webkit-keyframes fadeIn {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 100%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
.red-icon {
  color: #e3342f;
}

.green-icon {
  color: #38c172;
}

.purple-icon {
  color: #9561e2;
}

.yellow-icon {
  color: #ffa500;
}

.btn-middle {
  display: block !important;
  margin: 0 auto;
  font-size: 16px !important;
}

#app .btn-lg-tooltip {
  padding: 0;
  font-size: 1.5rem;
}

.button-metallic {
  display: inline-block;
  padding: 5px 7px;
  cursor: pointer;
  transition: all 20ms ease-out;
  vertical-align: top;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid hsl(0, 0%, 75%);
  background-color: hsl(0, 0%, 95%);
  background-image: linear-gradient(to bottom, hsl(0, 0%, 95%), hsl(0, 0%, 95%));
  box-shadow: inset 0 1px 0 hsl(0, 67.7419354839%, 100%), inset 0 -1px 0 hsl(0, 0%, 85%), inset 0 0 0 1px hsl(0, 0%, 95%), 0 2px 4px rgba(0, 0, 0, 0.2);
  color: hsl(0, 0%, 55%);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.button-metallic:hover, .button-metallic:focus {
  border-color: hsl(0, 0%, 55%);
  background: hsl(0, 0%, 95%);
  box-shadow: inset 0 1px 0 hsl(0, 67.7419354839%, 100%), inset 0 -1px 0 hsl(0, 0%, 85%), inset 0 0 0 1px hsl(0, 0%, 95%);
}
.button-metallic:active {
  background: hsl(0, 0%, 95%);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
}
.button-metallic .fa {
  color: hsl(0, 0%, 75%);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
}

.button-metallic--blue {
  padding: 5px 7px;
  border-radius: 3px;
  border: 1px solid hsl(199, 83.4862385321%, 37.605%);
  background-color: hsl(199, 74.1753726963%, 57.405%);
  background-image: linear-gradient(to bottom, hsl(199, 74.1753726963%, 57.405%), hsl(199, 76.6181315762%, 52.955%));
  box-shadow: inset 0 1px 0 hsl(199, 96.1897504287%, 73.755%), inset 0 -1px 0 hsl(199, 68.0672268908%, 47.005%), inset 0 0 0 1px hsl(199, 71.1626687639%, 61.855%), 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.button-metallic--blue:hover, .button-metallic--blue:focus {
  border-color: hsl(199, 100%, 21.805%);
  background: hsl(199, 76.6181315762%, 52.955%);
  box-shadow: inset 0 1px 0 hsl(199, 96.1897504287%, 73.755%), inset 0 -1px 0 hsl(199, 68.0672268908%, 47.005%), inset 0 0 0 1px hsl(199, 71.1626687639%, 61.855%);
}
.button-metallic--blue:active {
  background: hsl(199, 76.6181315762%, 52.955%);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
}
.button-metallic--blue .fa {
  color: hsl(199, 83.4862385321%, 37.605%);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
}

.button-metallic--green {
  padding: 5px 7px;
  border-radius: 3px;
  border: 1px solid hsl(97, 44.9275362319%, 41.4%);
  background-color: hsl(97, 45.652173913%, 63.2%);
  background-image: linear-gradient(to bottom, hsl(97, 45.652173913%, 63.2%), hsl(97, 50.9803921569%, 59.2%));
  box-shadow: inset 0 1px 0 hsl(97, 49.7487437186%, 80.1%), inset 0 -1px 0 hsl(97, 37.7593360996%, 51.8%), inset 0 0 0 1px hsl(97, 39.0243902439%, 67.2%), 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.button-metallic--green:hover, .button-metallic--green:focus {
  border-color: hsl(97, 69.4915254237%, 23.6%);
  background: hsl(97, 50.9803921569%, 59.2%);
  box-shadow: inset 0 1px 0 hsl(97, 49.7487437186%, 80.1%), inset 0 -1px 0 hsl(97, 37.7593360996%, 51.8%), inset 0 0 0 1px hsl(97, 39.0243902439%, 67.2%);
}
.button-metallic--green:active {
  background: hsl(97, 50.9803921569%, 59.2%);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
}
.button-metallic--green .fa {
  color: hsl(97, 44.9275362319%, 41.4%);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
}

.button-metallic .fa {
  float: left;
  margin: -1px 8px 0 -4px;
  vertical-align: top;
  font-size: 14px;
  line-height: 20px;
}

.notifications-wrapper {
  min-width: 300px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 640px) {
  .notifications-wrapper {
    min-width: 300px;
  }
}
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .notifications-wrapper {
    width: 400px;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 1024px) {
  .notifications-wrapper {
    width: 500px;
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1280px) {
  .notifications-wrapper {
    width: 550px;
  }
}
.light-search-prepend {
  color: #59c9a5 !important;
  background-color: #fff !important;
}

.dark-search-prepend {
  color: #fff !important;
  background-color: #59c9a5 !important;
}

#app .input-group-text {
  border: none;
  border-radius: 0.25rem;
}

#app .dropdown-toggle::after {
  margin-left: 0.05em;
  vertical-align: 0;
}

.user-search-results .card:hover {
  border: 1px solid #333;
}

.user-name {
  font-size: 13px;
}

#app .table-sm th,
#app .table-sm td {
  padding: 0;
}

#app .table .thead-dark th {
  padding: 5px 0;
  color: #4f566b;
  border-top: 1px solid rgb(128, 128, 128);
  border-bottom: 1px solid rgb(128, 128, 128);
  background-color: #f7fafc;
  font-weight: normal;
}

#app .table .thead-dark th a {
  color: #4f566b;
  font-weight: normal;
}

.Table-cell--wrap--noWrap {
  white-space: nowrap;
}

#app .hover-cell .btn {
  width: 100%;
  height: 100%;
  padding: 4px;
}

/* table fit helpers */
.table td.fit,
.table th.fit {
  white-space: nowrap;
}

.dataTables_wrapper .dataTables_filter input {
  @apply shadow;
}

.table-responsive select {
  @apply shadow;
}

@media screen and (max-width: 768px) {
  .table td.fit,
  .table th.fit {
    width: 1%;
  }
}
.mce-label.mce-charactercount {
  margin: 2px 0 2px 2px;
  padding: 8px;
  font-size: 12px;
}

.mce-path {
  display: none !important;
}

.pedigree--info_width {
  margin-left: 2%;
}

.pedigree--website--desktop {
  margin-bottom: 17px;
  font-size: 13px;
}

.pedigree--website--print {
  top: 25px;
  left: 190px;
  height: 50px;
}

.pedigree--fancier_info--desktop {
  bottom: 20px;
  left: 20px;
  width: 180px;
}

.pedigree--fancier_info--print {
  bottom: 40px;
  left: -10px;
  width: 200px;
}

.pedigree--watermark_desktop {
  bottom: 150px;
  left: 20px;
  width: 180px;
}

.pedigree--watermark_print {
  bottom: 180px;
  left: -10px;
  width: 180px;
}

@media (min-width: 640px) {
  .pedigree--info_width {
    margin-left: 5%;
  }
}
@media (min-width: 1024px) {
  .pedigree--info_width {
    margin-left: 2%;
  }
}
@media (min-width: 1280px) {
  .pedigree--info_width {
    margin-left: 10%;
  }
}
@media (min-width: 1404px) {
  .pedigree--info_width {
    margin-left: 16.7%;
  }
}
.pedigree--pigeon_gender {
  position: absolute;
  top: 0;
  left: 150px;
  line-height: 23px;
}

.pedigree--pigeon_ringnumber {
  display: block;
  margin-top: -5px;
  color: #e53e3e;
  font-size: 14px;
  font-weight: 700;
}

.pedigree--pigeon_name {
  display: block;
  font-size: 13.5px;
  font-weight: 700;
}

.pedigree--pigeon_owner {
  display: block;
  margin-top: 0.1rem;
  font-size: 13px;
}

.pedigree--pigeon_color {
  display: block;
  margin-top: 0.1rem;
  font-size: 13px;
}

.pedigree--pigeon_info {
  display: block;
  overflow: hidden;
  margin-top: 0.1rem;
  padding: 1px;
  border-radius: 5px;
  background-color: #f5f5f5 !important;
  font-size: 13px;
  overflow-wrap: break-word;
}

.pedigree--pigeon_info blockquote {
  font-size: 13px;
}

.gparent-height {
  overflow: hidden;
  max-height: 220px !important;
}

.gparent-height .pedigree--pigeon_info {
  overflow: hidden;
}

.ggparent {
  overflow: hidden;
  max-height: 40px;
  font-size: 12px;
}

.ggparent-height {
  min-height: 90px !important;
  max-height: 140px !important;
}

.ggparent-height-2 {
  min-height: 90px !important;
  max-height: 108px !important;
}

#tree-simple blockquote {
  overflow: hidden;
  margin-right: 0;
  margin-left: 0;
  margin-left: 0.5em;
  padding-right: 0.5em;
  padding-left: 0.5em;
  border-left: 5px solid #ccc;
  font-size: 12px;
  font-style: italic;
}

#tree-simple h2,
#pedigree-preview h2 {
  font-size: 20px;
}
#tree-simple h3,
#pedigree-preview h3 {
  font-size: 17px;
}
#tree-simple h4,
#pedigree-preview h4 {
  font-size: 15px;
}
#tree-simple ul,
#pedigree-preview ul {
  list-style-position: inside;
  list-style-type: disc;
}
#tree-simple ol,
#pedigree-preview ol {
  list-style-position: inside;
  list-style-type: decimal;
}
#tree-simple li,
#pedigree-preview li {
  list-style: unset;
}

/**
 * Element UI
 */
#application .el-table th {
  color: #2e2d29;
  background-color: #f1f5f8;
  font-weight: 700;
}

.el-loading-spinner {
  left: 50%;
}

#application .pagination-bar {
  overflow-y: auto;
}

#application .el-date-editor.el-input,
#application .el-date-editor.el-input__inner {
  width: initial;
  border-radius: 0.25em;
}

#application .el-el-date-editor input {
  display: block;
  padding: 8px 8px 8px 30px;
  color: #4a5568;
  border-width: 1px;
  border-color: #edf2f7;
  border-radius: 0.25rem;
  background-color: #edf2f7;
  font-size: 0.875rem;
  line-height: 1.25;
}

#application button:focus {
  outline: 0;
}

#application .theme--light.v-application {
  background: transparent;
}

#application .v-application a {
  color: #2c2c2c;
}

#application .v-application ol,
#application .v-application ul {
  padding-left: 0;
}

#application .v-application p {
  margin-bottom: 3px;
}

/**
 * Helpers
 */
.j-cloak {
  display: none;
}

.e-cloak {
  display: none !important;
}

.modal-open {
  overflow: inherit;
}

.content-m-top {
  margin-top: 60px;
}

.tw-z-100 {
  position: relative;
  z-index: 100;
}

/* Remove AM/PM from time input */
.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}

input[type=time]::-webkit-clear-button {
  margin: -10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

/* Remove date field arrows */
input[type=date]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

#paginationLinks {
  margin-bottom: 50px;
}

/* Vue hiding helper, hides everything untill Vue is loaded */
[v-cloak] {
  display: none;
}

[v-cloak]::before {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 80px;
  height: 80px;
  content: " ";
  background-image: url(/images/svg/loader.svg);
  background-size: cover;
}

.text-start {
  text-align: left;
}

@media screen and (max-width: 768px) {
  .clickable-icon {
    display: block;
    font-size: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .content-m-bottom {
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 1024px) {
  .content-m-top {
    margin-top: 100px;
  }
}
/**
 * Layouts
 */
.avatar-container .avatar-edit-button {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  color: #fff;
}

.avatar-container:hover .avatar-edit-button {
  display: block;
  padding: 0 25px;
  background: rgba(0, 0, 0, 0.6);
  font-size: 30px;
}

.edit-slide-text {
  display: none;
  transition: display 2s linear;
}

.avatar-container .edit-slide-text {
  display: inline;
  transition: display 2s linear;
}

.vue-position {
  position: inherit;
}

.avatar {
  overflow: hidden;
  width: 40px;
  height: 40px;
  padding: 0;
  transition-property: none;
  background: #fdfdfd;
}

@media (min-width: 576px) {
  .avatar-image {
    float: left;
    margin-bottom: 0;
  }
}
.notification-counter-wrapper {
  position: relative;
  width: 50px;
  height: 24px;
}

.notification-counter {
  position: absolute;
  top: -15px;
  right: 5px;
  padding: 0 12px;
  transition: opacity, -webkit-transform;
  transition: transform, opacity;
  transition: transform, opacity, -webkit-transform;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  color: white;
  border-radius: 60px;
  background: #3498db;
  font-size: 1.5em;
  font-weight: 700;
}

.notification.notify::before {
  -webkit-animation: ring 1.5s ease;
  animation: ring 1.5s ease;
}

.notification.show-count::after {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 1;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(35deg);
  }
  12.5% {
    -webkit-transform: rotate(-30deg);
  }
  25% {
    -webkit-transform: rotate(25deg);
  }
  37.5% {
    -webkit-transform: rotate(-20deg);
  }
  50% {
    -webkit-transform: rotate(15deg);
  }
  62.5% {
    -webkit-transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes ring {
  0% {
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
  }
  12.5% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
  25% {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
  37.5% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
  50% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  62.5% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
/**
 * Auth
 */
.switch-form button {
  padding: 12px 45px;
  transition: transform 80ms ease-in;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #59c9a5;
  border-radius: 20px;
  background-color: #59c9a5;
  font-size: 12px;
  font-weight: bold;
}

.switch-form .btn-outline {
  cursor: pointer;
  color: #59c9a5;
  border-color: #59c9a5;
  background-color: #fff;
}

.switch-form .btn-outline:hover {
  color: #fff;
  border-color: #59c9a5;
  background-color: #59c9a5;
}

.switch-form button:active {
  transform: scale(0.95);
}

.switch-form button:focus {
  outline: none;
}

.switch-form button.ghost {
  border-color: #fff;
  background-color: transparent;
}

.switch-form form {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 50px;
  text-align: center;
  background-color: #fff;
  align-items: center;
  justify-content: center;
}

.switch-form input {
  width: 100%;
  margin: 8px 0 4px 0;
  padding: 9px 16px;
  border: none;
  background-color: #eee;
}

.switch-form {
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  border-radius: 10px;
  background-color: #fff;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  z-index: 2;
  left: 0;
  width: 50%;
}

.switch-form.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  z-index: 1;
  left: 0;
  width: 50%;
  opacity: 0;
}

.switch-form.right-panel-active .sign-up-container {
  z-index: 5;
  transform: translateX(100%);
  animation: show 0.6s;
  opacity: 1;
}

@keyframes show {
  0%, 49.99% {
    z-index: 1;
    opacity: 0;
  }
  50%, 100% {
    z-index: 5;
    opacity: 1;
  }
}
.overlay-container {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 50%;
  overflow: hidden;
  width: 50%;
  height: 100%;
  transition: transform 0.6s ease-in-out;
}

.switch-form.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  position: relative;
  left: -100%;
  width: 200%;
  height: 100%;
  transition: transform 0.6s ease-in-out;
  transform: translateX(0);
  color: #fff;
  background: #59c9a5;
  background: -webkit-linear-gradient(to right, #59c9a5, #465775);
  background: linear-gradient(to right, #59c9a5, #465775);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
}

.switch-form.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding: 0 40px;
  transition: transform 0.6s ease-in-out;
  transform: translateX(0);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.overlay-left {
  transform: translateX(-20%);
}

.switch-form.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.switch-form.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  display: inline-flex;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.switch-info {
  display: none;
}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/
/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/
/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .overlay-container {
    display: none;
  }
  .switch-form {
    position: initial;
  }
  .sign-in-container {
    width: 100%;
  }
  .sign-up-container {
    width: 100%;
  }
  .switch-form.right-panel-active .sign-in-container {
    transform: initial;
  }
  .switch-form.right-panel-active .sign-up-container {
    transform: initial;
  }
  .switch-info {
    display: block;
    margin-top: 50px;
  }
  .switch-form form {
    padding: 0 20px;
  }
}
/* Pages - Pedigree */
.Treant {
  overflow: auto !important;
}

.blocks-view {
  z-index: 1;
  overflow: hidden;
  transition: all 0.2s ease;
}

/* Block specific resets */
.blocks-view a {
  color: #212529;
}

.blocks-view a:hover {
  text-decoration: none;
}

.blocks-view .container .row a {
  width: 100%;
}

.blocks-view hr {
  width: 100%;
}

@media (min-width: 768px) {
  .blocks-view-row {
    padding: 15px;
    border: 2px solid white;
    background-color: #9e9e9e;
  }
  .node-height {
    min-height: 300px !important;
    max-height: 300px !important;
  }
  .parent-height {
    min-height: 200px !important;
    max-height: 200px !important;
  }
  .grandparent-height {
    min-height: 120px !important;
    max-height: 120px !important;
  }
  .greatgrandparent-height {
    min-height: 72px !important;
    max-height: 72px !important;
  }
  .mt-ggrandparent-inner {
    margin-top: 1.9rem;
  }
  .mt-ggrandparent-middle {
    margin-top: 4rem;
  }
  .mb-grandparent {
    margin-bottom: 70px;
  }
  .mb-parent {
    margin-bottom: 100px;
  }
}
/* Pages - Pigeon */
#application .input-field-tw-ext:disabled {
  opacity: 1;
  color: linen !important;
  background-color: #878787 !important;
}

/* Pages - User */
.legend-block {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  padding: 0;
  border-radius: 2px;
}

.bio-height {
  overflow: hidden;
  height: 50px;
}

.single-user ul li {
  list-style: none;
}

.single-user ul li a {
  text-decoration: none;
}

.single-user ul li a:hover {
  opacity: 0.7;
}

.single-user h2 {
  display: inline-block;
}

.single-user .gender-symbol {
  font-size: 75px;
}

.modal-dialog ul li {
  list-style: none;
}

/* Have to overwrite standard CSS to make the JS work */
.action-follow strong {
  font-weight: normal;
}

.follow {
  display: block;
}

.follow-active {
  border-radius: 5px;
  background-color: #e8e8e8;
}

.profileImg {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card-avatar {
  display: inline;
  float: left;
  width: 20%;
}

@media (min-width: 0) and (max-width: 767px) {
  .single-user img {
    display: block;
    width: 50%;
    margin: 0 auto;
  }
}
@media (min-width: 576px) {
  /* Overwrite bootstrap */
  .modal-dialog {
    margin: 5rem auto !important;
  }
}
#app .post-create textarea {
  border: 1px solid #fff !important;
  border-radius: 0;
  outline: none;
  background-color: #eee;
}

/**
 * Vue
 */
.text-right {
  text-align: right;
}

.comments {
  margin-top: 10px;
}

.comments-app {
  width: 100%;
}

.comments-app h1 {
  margin-bottom: 1.5em;
  text-align: center;
  color: #191919;
  text-shadow: 0 0 2px rgb(152, 152, 152);
}

.comment-v {
  margin-left: 70px;
}

.comment-form .form .form-row:last-child {
  margin-bottom: 0;
}

.comment-form .form .input {
  width: 100%;
  min-height: 60px;
  padding: 5px 10px;
  -webkit-transition: 350ms box-shadow;
  -moz-transition: 350ms box-shadow;
  -ms-transition: 350ms box-shadow;
  -o-transition: 350ms box-shadow;
  transition: 350ms box-shadow;
  color: #555f77;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  outline: none;
  background-color: #fcfcfc;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  font-size: 14px;
}

.comment-form .form textarea.input {
  height: 100px;
  padding: 15px;
}

.comment-form .form label {
  color: #555f77;
  font-size: 14px;
}

.comment-form .form input[type=submit] {
  display: block;
  margin-left: auto;
  padding: 6px 15px;
  cursor: pointer;
  -webkit-transition: 350ms box-shadow;
  -moz-transition: 350ms box-shadow;
  -ms-transition: 350ms box-shadow;
  -o-transition: 350ms box-shadow;
  transition: 350ms box-shadow;
  color: #fff;
  border: none;
  outline: none;
  background-color: #555f77;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.comment-form .form .input:focus,
.comment-form .form input[type=submit]:focus,
.comment-form .form input[type=submit]:hover {
  box-shadow: 0 6px 20px 4px rgba(121, 137, 148, 0.55);
}

.comment-form .form.ng-submitted .input.ng-invalid,
.comment-form .form .input.ng-dirty.ng-invalid {
  box-shadow: 0 2px 6px rgba(212, 47, 47, 0.55) !important;
}

.comment-form .form .input.disabled {
  background-color: #e8e8e8;
}

.comment-form,
.comment {
  position: relative;
  z-index: 0;
}

.comment-form .comment-avatar,
.comment .comment-avatar {
  position: absolute;
  top: 15px;
  left: 0;
  overflow: hidden;
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.comment-form .comment-avatar img,
.comment .comment-avatar img {
  display: block;
  width: 100%;
  height: auto;
}

.comment .comment-box {
  position: relative;
  min-height: 60px;
  margin-left: 55px;
  padding: 15px;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.comment .comment-box::before,
.comment .comment-box::after {
  position: absolute;
  top: 20px;
  left: -10px;
  content: "";
  border-width: 10px 10px 10px 0;
  border-style: solid;
  border-color: transparent #fcfcfc;
}

.comment .comment-box::before {
  top: 22px;
  border-color: transparent rgba(0, 0, 0, 0.05);
}

.comment .comment-text {
  color: #555f77;
  font-size: 15px;
}

.comment-actions li a {
  cursor: pointer;
}

.comment .comment-footer {
  color: #acb4c2;
  font-size: 13px;
}

.comment .comment-footer::after {
  display: table;
  clear: both;
  content: "";
}

.comment .comment-footer a {
  -webkit-transition: 350ms color;
  -moz-transition: 350ms color;
  -ms-transition: 350ms color;
  -o-transition: 350ms color;
  transition: 350ms color;
  text-decoration: none;
  color: #acb4c2;
}

.comment .comment-footer a:hover {
  text-decoration: underline;
  color: #555f77;
}

.comment .comment-info {
  float: left;
  width: 60%;
}

#app .comment-actions li a:hover {
  color: #4e566d;
}

.reply {
  margin-top: 5px;
  margin-left: 60px;
}

@media screen and (max-width: 766px) {
  .comment-form .comment-avatar,
  .comment .comment-avatar {
    width: 40px;
    height: 40px;
  }
  .comment .comment-box::before,
  .comment .comment-box::after {
    position: absolute;
    top: 20px;
    left: -10px;
    content: "";
  }
  .comment-actions {
    text-align: left;
  }
  .comment .comment-info {
    width: 100%;
  }
  .comment-actions li {
    width: 100%;
  }
  .reply {
    margin-left: 15px;
  }
}
@media screen and (min-width: 766px) {
  .comment .comment-box {
    margin-left: 70px;
  }
}
.vanboven {
  display: none; /* Temporary */
}

.alert-flash {
  position: fixed !important;
  z-index: 9999;
  right: 25px;
  bottom: 55px;
  font-size: 14.4px;
}

.boxclose {
  display: inline-block;
  padding: 11px 3px;
  cursor: pointer;
  color: #605f61;
  border: 1px solid #605f61;
  border-radius: 5px;
  font-size: 29px;
  line-height: 0;
}

.boxclose::before {
  content: "×";
}

.v--modal-overlay {
  z-index: 1100 !important;
}

.v--modal-overlay .v--modal-box {
  overflow-y: auto !important;
}

ul {
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

hr {
  height: 0;
  margin: 0.5rem 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.header__nav {
  position: relative;
}
.header__navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.header__item {
  position: relative;
  padding: 8px 13px;
}
.header--icon {
  display: inline-flex;
  width: 1.65rem;
  height: 1.65rem;
  fill: currentcolor;
}
.header--btn {
  display: inline-block;
  padding: 0;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: transparent;
  appearance: none;
  user-select: none;
}
.header__link .header--btn {
  position: absolute;
  top: 0;
  right: 0;
}
.header__link .header--btn:focus {
  outline: 0;
}
.header__dropdown-menu {
  position: absolute;
  z-index: 100;
  top: 100%;
  right: 0;
  overflow-y: auto;
  min-width: 280px;
  margin-top: 1.62rem;
  border-radius: 8px;
  background-color: white;
  background-clip: padding-box;
}
.header__dropdown-menu-link {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.4rem;
  padding: 0.6rem 1.5rem 0.6rem 1.5rem;
  color: #3c4043;
  border-radius: 0 50px 50px 0;
  line-height: 0;
  align-items: center;
  justify-content: flex-start;
}
.header__dropdown-menu-link:hover {
  background-color: rgba(0, 0, 0, 0.039);
}
.header__dropdown-menu-svg {
  padding-right: 16px;
}
.header__dropdown-menu-svg .header--icon {
  width: 1.4rem;
  height: 1.4rem;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.6s;
}

.slide-fade-enter,
.slide-fade-leave-active {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(31px);
}

.slide-fade-leave-active {
  transform: translateX(-31px);
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s;
}

.dropdown-enter,
.dropdown-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

@media (max-width: 767px) {
  .header__dropdown-menu {
    position: absolute;
    z-index: 100;
    left: -244px;
    overflow-y: auto;
    width: 100vw;
    margin-top: 11px;
    border-radius: 0.25rem;
    background-color: white;
    background-clip: padding-box;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .header__dropdown-menu-profile {
    left: -301px;
  }
}
/**
 * Vuetify
 */
.v-application .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

/**
 * Tailwind
 */
@tailwind base;
@tailwind components;
.outline-box-shadow {
  outline: 0 0 0 0.2rem rgba(84, 86, 105, 0.25);
}

.landcode {
  display: inline-block;
  width: 23px;
  font-weight: bold;
}

.disabled:disabled {
  background: #ddd;
}

.block-important {
  display: block !important;
}

.flex-basis {
  flex-basis: 100%;
}

.vicp-range {
  display: none;
}

.user-favorite-result-list .table-wrapper {
  overflow-x: auto;
}

/*
 * Standards
 */
.max-h-400 {
  max-height: 400px;
}

/*
 * Lay-out
 */
.bg-framework {
  @apply tw-bg-white tw-rounded-lg tw-shadow !important;
}

.bg-framework-secondary {
  @apply tw-bg-gray-100 tw-rounded-lg tw-shadow !important;
}

.pin-x-auto {
  left: 50%;
  transform: translateX(-50%);
}

.pin-y-auto {
  top: 50%;
  transform: translateY(-50%);
}

.pin-xy-auto {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sm\:pin-x-auto {
  left: 50%;
  transform: translateX(-50%);
}

.sm\:pin-y-auto {
  top: 50%;
  transform: translateY(-50%);
}

.sm\:pin-xy-auto {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.md\:pin-x-auto {
  left: 50%;
  transform: translateX(-50%);
}

.md\:pin-y-auto {
  top: 50%;
  transform: translateY(-50%);
}

.md\:pin-xy-auto {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lg\:pin-x-auto {
  left: 50%;
  transform: translateX(-50%);
}

.lg\:pin-y-auto {
  top: 50%;
  transform: translateY(-50%);
}

.lg\:pin-xy-auto {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.xl\:pin-x-auto {
  left: 50%;
  transform: translateX(-50%);
}

.xl\:pin-y-auto {
  top: 50%;
  transform: translateY(-50%);
}

.xl\:pin-xy-auto {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*
 * Form Components
 */
#application .input-field {
  @apply tw-appearance-none tw-bg-primary-200 tw-rounded tw-w-full tw-p-2 tw-text-primary-900 tw-border tw-border-primary-200 tw-text-sm !important;
}

#application .input-field-tw {
  @apply tw-appearance-none tw-block tw-w-full tw-bg-gray-200 tw-text-gray-700 p-2 tw-text-sm tw-rounded !important;
}

#application .input-field-tw-ext {
  @apply tw-appearance-none tw-block tw-w-full tw-bg-gray-300 tw-text-gray-700 p-2 tw-text-sm tw-rounded !important;
}

#application .input-field-tw-ext:focus {
  @apply tw-outline-none tw-bg-gray-200 !important;
}

#application .input-field-tw:focus {
  background-color: #e2e8f0 !important;
}

#application .input {
  font-size: 0.92rem !important;
}

#application .label-tw {
  @apply tw-tracking-wide tw-text-gray-900 tw-mb-1 tw-text-sm !important;
}
@screen lg {
  #application .label-tw {
    @apply tw-text-sm !important;
  }
}

#application .label-tw .label {
  font-size: inherit;
}

#application .label-tw .input {
  display: block;
  width: 100%;
  height: 35px;
  padding: 0.5rem;
  color: #4a5568;
  border-width: 1px;
  border-color: #edf2f7;
  border-radius: 0.25rem;
  background-color: #edf2f7;
  font-size: 0.875rem;
  line-height: 1.25;
}

#application .label-tw .input:hover {
  border: none !important;
}

#application .label-tw .input:active,
#application .label-tw .input:focus {
  border: none !important;
  background-color: #fff !important;
}

#application .label-tw .label:not(:last-child) {
  margin-bottom: 0.22em !important;
}

/*
 * Visual
 */
.scroll-height {
  height: 500px;
}
@screen lg {
  .scroll-height {
    height: 250px;
  }
}

/*
 * Buttons
 */
.btn-primary {
  @apply tw-bg-primary-500 tw-p-2 tw-text-white !important;
}

.btn-primary:hover {
  @apply tw-opacity-75 !important;
}

.btn-outline-primary {
  @apply tw-text-primary-900 tw-border-2 tw-p-2 tw-border-primary-500 tw-outline-none tw-rounded !important;
}

.btn-outline-primary:hover {
  @apply tw-bg-primary-500 tw-text-white tw-p-2 tw-border-primary-500 tw-outline-none !important;
}

.btn-outline-primary:focus {
  @apply tw-bg-primary-500 tw-text-white tw-p-2 tw-border-primary-500 tw-outline-none !important;
}

.btn-blue {
  @apply tw-bg-blue-500 tw-p-2 tw-text-white !important;
}

.btn-blue:hover {
  @apply tw-opacity-75 !important;
}

.btn-outline-blue {
  @apply tw-text-blue-500 tw-border tw-p-2 tw-border-blue-500 !important;
}

.btn-outline-blue:hover {
  @apply tw-bg-blue-500 tw-text-white tw-p-2 tw-border-blue-500 !important;
}

.btn-red-secondary {
  @apply tw-bg-red-100 tw-p-2 tw-text-red-700 !important;
}

.btn-red-secondary:hover {
  @apply tw-opacity-75 !important;
}

.tw-link {
  @apply tw-text-primary-500 !important;
}

.tw-link:hover {
  opacity: 0.7 !important;
}

.min-height-block {
  min-height: 250px;
}

@media (min-width: 1024px) {
  .min-height-block {
    min-height: 150px;
  }
}
/*
 * Colors
 */
.male {
  @apply tw-bg-blue-200 !important;
}

.female {
  @apply tw-bg-red-200 !important;
}

.male-color {
  @apply tw-text-blue-200 !important;
}

.male-color-strong {
  color: #6699ff;
}

.female-color {
  @apply tw-text-red-200 !important;
}

.female-color-strong {
  color: #ff99cc;
}

.blue {
  @apply tw-bg-blue-200 !important;
}

.pink {
  @apply tw-bg-red-200 !important;
}

/*
 * Charts
 */
.tw-node {
  width: 170px;
  min-height: 170px;
  @apply tw-p-1 tw-border tw-border-gray-600 tw-rounded !important;
}

/*
 * Fonts
 */
.tw-font-theme {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 16px !important;
}

.v-application {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 16px !important;
}

@tailwind utilities;
/**
 * Bulma/Buefy
 */
/* Bulma Utilities */
.button, .file-cta,
.file-name, .select select, .textarea, .input, .pagination-previous,
.pagination-next,
.pagination-link,
.pagination-ellipsis {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;
}
.button:focus, .file-cta:focus,
.file-name:focus, .select select:focus, .textarea:focus, .input:focus, .pagination-previous:focus,
.pagination-next:focus,
.pagination-link:focus,
.pagination-ellipsis:focus, .is-focused.button, .is-focused.file-cta,
.is-focused.file-name, .select select.is-focused, .is-focused.textarea, .is-focused.input, .is-focused.pagination-previous,
.is-focused.pagination-next,
.is-focused.pagination-link,
.is-focused.pagination-ellipsis, .button:active, .file-cta:active,
.file-name:active, .select select:active, .textarea:active, .input:active, .pagination-previous:active,
.pagination-next:active,
.pagination-link:active,
.pagination-ellipsis:active, .is-active.button, .is-active.file-cta,
.is-active.file-name, .select select.is-active, .is-active.textarea, .is-active.input, .is-active.pagination-previous,
.is-active.pagination-next,
.is-active.pagination-link,
.is-active.pagination-ellipsis {
  outline: none;
}
[disabled].button, [disabled].file-cta,
[disabled].file-name, .select select[disabled], [disabled].textarea, [disabled].input, [disabled].pagination-previous,
[disabled].pagination-next,
[disabled].pagination-link,
[disabled].pagination-ellipsis, fieldset[disabled] .button, fieldset[disabled] .file-cta,
fieldset[disabled] .file-name, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input, fieldset[disabled] .pagination-previous,
fieldset[disabled] .pagination-next,
fieldset[disabled] .pagination-link,
fieldset[disabled] .pagination-ellipsis {
  cursor: not-allowed;
}

.switch, .button, .file, .tabs, .pagination-previous,
.pagination-next,
.pagination-link,
.pagination-ellipsis, .breadcrumb {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select:not(.is-multiple):not(.is-loading)::after, .navbar-link:not(.is-arrowless)::after {
  border: 3px solid transparent;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.625em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 0.625em;
}

.block:not(:last-child), .title:not(:last-child),
.subtitle:not(:last-child), .table-container:not(:last-child), .table:not(:last-child), .progress:not(:last-child), .notification:not(:last-child), .content:not(:last-child), .box:not(:last-child), .tabs:not(:last-child), .pagination:not(:last-child), .message:not(:last-child), .level:not(:last-child), .breadcrumb:not(:last-child) {
  margin-bottom: 1.5rem;
}

.delete, .modal-close {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: rgba(10, 10, 10, 0.2);
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px;
}
.delete::before, .modal-close::before, .delete::after, .modal-close::after {
  background-color: hsl(0, 0%, 100%);
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}
.delete::before, .modal-close::before {
  height: 2px;
  width: 50%;
}
.delete::after, .modal-close::after {
  height: 50%;
  width: 2px;
}
.delete:hover, .modal-close:hover, .delete:focus, .modal-close:focus {
  background-color: rgba(10, 10, 10, 0.3);
}
.delete:active, .modal-close:active {
  background-color: rgba(10, 10, 10, 0.4);
}
.is-small.delete, .is-small.modal-close {
  height: 16px;
  max-height: 16px;
  max-width: 16px;
  min-height: 16px;
  min-width: 16px;
  width: 16px;
}
.is-medium.delete, .is-medium.modal-close {
  height: 24px;
  max-height: 24px;
  max-width: 24px;
  min-height: 24px;
  min-width: 24px;
  width: 24px;
}
.is-large.delete, .is-large.modal-close {
  height: 32px;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  width: 32px;
}

.loader, .button.is-loading::after, .control.is-loading::after, .select.is-loading::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid hsl(0, 0%, 86%);
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}

.image.is-square img,
.image.is-square .has-ratio, .image.is-1by1 img,
.image.is-1by1 .has-ratio, .image.is-5by4 img,
.image.is-5by4 .has-ratio, .image.is-4by3 img,
.image.is-4by3 .has-ratio, .image.is-3by2 img,
.image.is-3by2 .has-ratio, .image.is-5by3 img,
.image.is-5by3 .has-ratio, .image.is-16by9 img,
.image.is-16by9 .has-ratio, .image.is-2by1 img,
.image.is-2by1 .has-ratio, .image.is-3by1 img,
.image.is-3by1 .has-ratio, .image.is-4by5 img,
.image.is-4by5 .has-ratio, .image.is-3by4 img,
.image.is-3by4 .has-ratio, .image.is-2by3 img,
.image.is-2by3 .has-ratio, .image.is-3by5 img,
.image.is-3by5 .has-ratio, .image.is-9by16 img,
.image.is-9by16 .has-ratio, .image.is-1by2 img,
.image.is-1by2 .has-ratio, .image.is-1by3 img,
.image.is-1by3 .has-ratio, .hero-video, .modal-background, .modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.navbar-burger {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  color: currentColor;
  font-family: inherit;
  font-size: 1em;
  margin: 0;
  padding: 0;
}

/* Bulma Components */
.breadcrumb {
  font-size: 1rem;
  white-space: nowrap;
}
.breadcrumb a {
  align-items: center;
  color: #59c9a5;
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}
.breadcrumb a:hover {
  color: hsl(0, 0%, 21%);
}
.breadcrumb li {
  align-items: center;
  display: flex;
}
.breadcrumb li:first-child a {
  padding-left: 0;
}
.breadcrumb li.is-active a {
  color: hsl(0, 0%, 21%);
  cursor: default;
  pointer-events: none;
}
.breadcrumb li + li::before {
  color: hsl(0, 0%, 71%);
  content: "/";
}
.breadcrumb ul,
.breadcrumb ol {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.breadcrumb .icon:first-child {
  margin-right: 0.5em;
}
.breadcrumb .icon:last-child {
  margin-left: 0.5em;
}
.breadcrumb.is-centered ol,
.breadcrumb.is-centered ul {
  justify-content: center;
}
.breadcrumb.is-right ol,
.breadcrumb.is-right ul {
  justify-content: flex-end;
}
.breadcrumb.is-small {
  font-size: 0.75rem;
}
.breadcrumb.is-medium {
  font-size: 1.25rem;
}
.breadcrumb.is-large {
  font-size: 1.5rem;
}
.breadcrumb.has-arrow-separator li + li::before {
  content: "→";
}
.breadcrumb.has-bullet-separator li + li::before {
  content: "•";
}
.breadcrumb.has-dot-separator li + li::before {
  content: "·";
}
.breadcrumb.has-succeeds-separator li + li::before {
  content: "≻";
}

.card {
  background-color: hsl(0, 0%, 100%);
  border-radius: 0.25rem;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: hsl(0, 0%, 29%);
  max-width: 100%;
  position: relative;
}

.card-footer:first-child, .card-content:first-child, .card-header:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card-footer:last-child, .card-content:last-child, .card-header:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.card-header {
  background-color: transparent;
  align-items: stretch;
  box-shadow: 0 0.125em 0.25em rgba(10, 10, 10, 0.1);
  display: flex;
}

.card-header-title {
  align-items: center;
  color: hsl(0, 0%, 21%);
  display: flex;
  flex-grow: 1;
  font-weight: 700;
  padding: 0.75rem 1rem;
}
.card-header-title.is-centered {
  justify-content: center;
}

.card-header-icon {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  color: currentColor;
  font-family: inherit;
  font-size: 1em;
  margin: 0;
  padding: 0;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.75rem 1rem;
}

.card-image {
  display: block;
  position: relative;
}
.card-image:first-child img {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card-image:last-child img {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.card-content {
  background-color: transparent;
  padding: 1.5rem;
}

.card-footer {
  background-color: transparent;
  border-top: 1px solid hsl(0, 0%, 93%);
  align-items: stretch;
  display: flex;
}

.card-footer-item {
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  padding: 0.75rem;
}
.card-footer-item:not(:last-child) {
  border-right: 1px solid hsl(0, 0%, 93%);
}

.card .media:not(:last-child) {
  margin-bottom: 1.5rem;
}

.dropdown {
  display: inline-flex;
  position: relative;
  vertical-align: top;
}
.dropdown.is-active .dropdown-menu, .dropdown.is-hoverable:hover .dropdown-menu {
  display: block;
}
.dropdown.is-right .dropdown-menu {
  left: auto;
  right: 0;
}
.dropdown.is-up .dropdown-menu {
  bottom: 100%;
  padding-bottom: 4px;
  padding-top: initial;
  top: auto;
}

.dropdown-menu {
  display: none;
  left: 0;
  min-width: 12rem;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  z-index: 20;
}

.dropdown-content {
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.dropdown-item, .dropdown .dropdown-menu .has-link a {
  color: hsl(0, 0%, 29%);
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;
}

a.dropdown-item, .dropdown .dropdown-menu .has-link a,
button.dropdown-item {
  padding-right: 3rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}
a.dropdown-item:hover, .dropdown .dropdown-menu .has-link a:hover,
button.dropdown-item:hover {
  background-color: hsl(0, 0%, 96%);
  color: hsl(0, 0%, 4%);
}
a.dropdown-item.is-active, .dropdown .dropdown-menu .has-link a.is-active,
button.dropdown-item.is-active {
  background-color: #59c9a5;
  color: #fff;
}

.dropdown-divider {
  background-color: hsl(0, 0%, 93%);
  border: none;
  display: block;
  height: 1px;
  margin: 0.5rem 0;
}

.level {
  align-items: center;
  justify-content: space-between;
}
.level code {
  border-radius: 4px;
}
.level img {
  display: inline-block;
  vertical-align: top;
}
.level.is-mobile {
  display: flex;
}
.level.is-mobile .level-left,
.level.is-mobile .level-right {
  display: flex;
}
.level.is-mobile .level-left + .level-right {
  margin-top: 0;
}
.level.is-mobile .level-item:not(:last-child) {
  margin-bottom: 0;
  margin-right: 0.75rem;
}
.level.is-mobile .level-item:not(.is-narrow) {
  flex-grow: 1;
}
@media screen and (min-width: 769px), print {
  .level {
    display: flex;
  }
  .level > .level-item:not(.is-narrow) {
    flex-grow: 1;
  }
}

.level-item {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
}
.level-item .title,
.level-item .subtitle {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .level-item:not(:last-child) {
    margin-bottom: 0.75rem;
  }
}

.level-left,
.level-right {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}
.level-left .level-item.is-flexible,
.level-right .level-item.is-flexible {
  flex-grow: 1;
}
@media screen and (min-width: 769px), print {
  .level-left .level-item:not(:last-child),
  .level-right .level-item:not(:last-child) {
    margin-right: 0.75rem;
  }
}

.level-left {
  align-items: center;
  justify-content: flex-start;
}
@media screen and (max-width: 768px) {
  .level-left + .level-right {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 769px), print {
  .level-left {
    display: flex;
  }
}

.level-right {
  align-items: center;
  justify-content: flex-end;
}
@media screen and (min-width: 769px), print {
  .level-right {
    display: flex;
  }
}

.media {
  align-items: flex-start;
  display: flex;
  text-align: inherit;
}
.media .content:not(:last-child) {
  margin-bottom: 0.75rem;
}
.media .media {
  border-top: 1px solid rgba(219, 219, 219, 0.5);
  display: flex;
  padding-top: 0.75rem;
}
.media .media .content:not(:last-child),
.media .media .control:not(:last-child) {
  margin-bottom: 0.5rem;
}
.media .media .media {
  padding-top: 0.5rem;
}
.media .media .media + .media {
  margin-top: 0.5rem;
}
.media + .media {
  border-top: 1px solid rgba(219, 219, 219, 0.5);
  margin-top: 1rem;
  padding-top: 1rem;
}
.media.is-large + .media {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.media-left,
.media-right {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.media-left {
  margin-right: 1rem;
}

.media-right {
  margin-left: 1rem;
}

.media-content {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: inherit;
}

@media screen and (max-width: 768px) {
  .media-content {
    overflow-x: auto;
  }
}
.menu {
  font-size: 1rem;
}
.menu.is-small {
  font-size: 0.75rem;
}
.menu.is-medium {
  font-size: 1.25rem;
}
.menu.is-large {
  font-size: 1.5rem;
}

.menu-list {
  line-height: 1.25;
}
.menu-list a {
  border-radius: 2px;
  color: hsl(0, 0%, 29%);
  display: block;
  padding: 0.5em 0.75em;
}
.menu-list a:hover {
  background-color: hsl(0, 0%, 96%);
  color: hsl(0, 0%, 21%);
}
.menu-list a.is-active {
  background-color: #59c9a5;
  color: #fff;
}
.menu-list li ul {
  border-left: 1px solid hsl(0, 0%, 86%);
  margin: 0.75em;
  padding-left: 0.75em;
}

.menu-label {
  color: hsl(0, 0%, 48%);
  font-size: 0.75em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.menu-label:not(:first-child) {
  margin-top: 1em;
}
.menu-label:not(:last-child) {
  margin-bottom: 1em;
}

.message {
  background-color: hsl(0, 0%, 96%);
  border-radius: 4px;
  font-size: 1rem;
}
.message strong {
  color: currentColor;
}
.message a:not(.button):not(.tag):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}
.message.is-small {
  font-size: 0.75rem;
}
.message.is-medium {
  font-size: 1.25rem;
}
.message.is-large {
  font-size: 1.5rem;
}
.message.is-white {
  background-color: white;
}
.message.is-white .message-header {
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 4%);
}
.message.is-white .message-body {
  border-color: hsl(0, 0%, 100%);
}
.message.is-black {
  background-color: #fafafa;
}
.message.is-black .message-header {
  background-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 100%);
}
.message.is-black .message-body {
  border-color: hsl(0, 0%, 4%);
}
.message.is-light {
  background-color: #fafafa;
}
.message.is-light .message-header {
  background-color: hsl(0, 0%, 96%);
  color: rgba(0, 0, 0, 0.7);
}
.message.is-light .message-body {
  border-color: hsl(0, 0%, 96%);
}
.message.is-dark {
  background-color: #fafafa;
}
.message.is-dark .message-header {
  background-color: hsl(0, 0%, 21%);
  color: #fff;
}
.message.is-dark .message-body {
  border-color: hsl(0, 0%, 21%);
}
.message.is-primary {
  background-color: #f7fcfb;
}
.message.is-primary .message-header {
  background-color: #59c9a5;
  color: #fff;
}
.message.is-primary .message-body {
  border-color: #59c9a5;
}
.message.is-info {
  background-color: #f7fafd;
}
.message.is-info .message-header {
  background-color: hsl(207, 61%, 53%);
  color: #fff;
}
.message.is-info .message-body {
  border-color: hsl(207, 61%, 53%);
}
.message.is-success {
  background-color: #f7fdfa;
}
.message.is-success .message-header {
  background-color: hsl(153, 53%, 53%);
  color: #fff;
}
.message.is-success .message-body {
  border-color: hsl(153, 53%, 53%);
}
.message.is-warning {
  background-color: #fffcf5;
}
.message.is-warning .message-header {
  background-color: hsl(44, 100%, 77%);
  color: rgba(0, 0, 0, 0.7);
}
.message.is-warning .message-body {
  border-color: hsl(44, 100%, 77%);
}
.message.is-danger {
  background-color: #fef6f7;
}
.message.is-danger .message-header {
  background-color: hsl(348, 86%, 61%);
  color: #fff;
}
.message.is-danger .message-body {
  border-color: hsl(348, 86%, 61%);
}
.message.is-twitter {
  background-color: #f5faff;
}
.message.is-twitter .message-header {
  background-color: #4099ff;
  color: #fff;
}
.message.is-twitter .message-body {
  border-color: #4099ff;
}

.message-header {
  align-items: center;
  background-color: hsl(0, 0%, 29%);
  border-radius: 4px 4px 0 0;
  color: #fff;
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  line-height: 1.25;
  padding: 0.75em 1em;
  position: relative;
}
.message-header .delete {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0.75em;
}
.message-header + .message-body {
  border-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.message-body {
  border-color: hsl(0, 0%, 86%);
  border-radius: 4px;
  border-style: solid;
  border-width: 0 0 0 4px;
  color: hsl(0, 0%, 29%);
  padding: 1.25em 1.5em;
}
.message-body code,
.message-body pre {
  background-color: hsl(0, 0%, 100%);
}
.message-body pre code {
  background-color: transparent;
}

.modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;
}
.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.86);
}

.modal-content,
.modal-card {
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .modal-content,
  .modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 640px;
  }
}

.modal-close {
  background: none;
  height: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
}

.modal-card {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  -ms-overflow-y: visible;
}

.modal-card-head,
.modal-card-foot {
  align-items: center;
  background-color: hsl(0, 0%, 96%);
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid hsl(0, 0%, 86%);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: hsl(0, 0%, 21%);
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid hsl(0, 0%, 86%);
}
.modal-card-foot .button:not(:last-child) {
  margin-right: 0.5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: hsl(0, 0%, 100%);
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 20px;
}

.navbar {
  background-color: hsl(0, 0%, 100%);
  min-height: 3.25rem;
  position: relative;
  z-index: 30;
}
.navbar.is-white {
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 4%);
}
.navbar.is-white .navbar-brand > .navbar-item,
.navbar.is-white .navbar-brand .navbar-link {
  color: hsl(0, 0%, 4%);
}
.navbar.is-white .navbar-brand > a.navbar-item:focus, .navbar.is-white .navbar-brand > a.navbar-item:hover, .navbar.is-white .navbar-brand > a.navbar-item.is-active,
.navbar.is-white .navbar-brand .navbar-link:focus,
.navbar.is-white .navbar-brand .navbar-link:hover,
.navbar.is-white .navbar-brand .navbar-link.is-active {
  background-color: #f2f2f2;
  color: hsl(0, 0%, 4%);
}
.navbar.is-white .navbar-brand .navbar-link::after {
  border-color: hsl(0, 0%, 4%);
}
.navbar.is-white .navbar-burger {
  color: hsl(0, 0%, 4%);
}
@media screen and (min-width: 1024px) {
  .navbar.is-white .navbar-start > .navbar-item,
  .navbar.is-white .navbar-start .navbar-link,
  .navbar.is-white .navbar-end > .navbar-item,
  .navbar.is-white .navbar-end .navbar-link {
    color: hsl(0, 0%, 4%);
  }
  .navbar.is-white .navbar-start > a.navbar-item:focus, .navbar.is-white .navbar-start > a.navbar-item:hover, .navbar.is-white .navbar-start > a.navbar-item.is-active,
  .navbar.is-white .navbar-start .navbar-link:focus,
  .navbar.is-white .navbar-start .navbar-link:hover,
  .navbar.is-white .navbar-start .navbar-link.is-active,
  .navbar.is-white .navbar-end > a.navbar-item:focus,
  .navbar.is-white .navbar-end > a.navbar-item:hover,
  .navbar.is-white .navbar-end > a.navbar-item.is-active,
  .navbar.is-white .navbar-end .navbar-link:focus,
  .navbar.is-white .navbar-end .navbar-link:hover,
  .navbar.is-white .navbar-end .navbar-link.is-active {
    background-color: #f2f2f2;
    color: hsl(0, 0%, 4%);
  }
  .navbar.is-white .navbar-start .navbar-link::after,
  .navbar.is-white .navbar-end .navbar-link::after {
    border-color: hsl(0, 0%, 4%);
  }
  .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #f2f2f2;
    color: hsl(0, 0%, 4%);
  }
  .navbar.is-white .navbar-dropdown a.navbar-item.is-active {
    background-color: hsl(0, 0%, 100%);
    color: hsl(0, 0%, 4%);
  }
}
.navbar.is-black {
  background-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 100%);
}
.navbar.is-black .navbar-brand > .navbar-item,
.navbar.is-black .navbar-brand .navbar-link {
  color: hsl(0, 0%, 100%);
}
.navbar.is-black .navbar-brand > a.navbar-item:focus, .navbar.is-black .navbar-brand > a.navbar-item:hover, .navbar.is-black .navbar-brand > a.navbar-item.is-active,
.navbar.is-black .navbar-brand .navbar-link:focus,
.navbar.is-black .navbar-brand .navbar-link:hover,
.navbar.is-black .navbar-brand .navbar-link.is-active {
  background-color: black;
  color: hsl(0, 0%, 100%);
}
.navbar.is-black .navbar-brand .navbar-link::after {
  border-color: hsl(0, 0%, 100%);
}
.navbar.is-black .navbar-burger {
  color: hsl(0, 0%, 100%);
}
@media screen and (min-width: 1024px) {
  .navbar.is-black .navbar-start > .navbar-item,
  .navbar.is-black .navbar-start .navbar-link,
  .navbar.is-black .navbar-end > .navbar-item,
  .navbar.is-black .navbar-end .navbar-link {
    color: hsl(0, 0%, 100%);
  }
  .navbar.is-black .navbar-start > a.navbar-item:focus, .navbar.is-black .navbar-start > a.navbar-item:hover, .navbar.is-black .navbar-start > a.navbar-item.is-active,
  .navbar.is-black .navbar-start .navbar-link:focus,
  .navbar.is-black .navbar-start .navbar-link:hover,
  .navbar.is-black .navbar-start .navbar-link.is-active,
  .navbar.is-black .navbar-end > a.navbar-item:focus,
  .navbar.is-black .navbar-end > a.navbar-item:hover,
  .navbar.is-black .navbar-end > a.navbar-item.is-active,
  .navbar.is-black .navbar-end .navbar-link:focus,
  .navbar.is-black .navbar-end .navbar-link:hover,
  .navbar.is-black .navbar-end .navbar-link.is-active {
    background-color: black;
    color: hsl(0, 0%, 100%);
  }
  .navbar.is-black .navbar-start .navbar-link::after,
  .navbar.is-black .navbar-end .navbar-link::after {
    border-color: hsl(0, 0%, 100%);
  }
  .navbar.is-black .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-black .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: black;
    color: hsl(0, 0%, 100%);
  }
  .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
    background-color: hsl(0, 0%, 4%);
    color: hsl(0, 0%, 100%);
  }
}
.navbar.is-light {
  background-color: hsl(0, 0%, 96%);
  color: rgba(0, 0, 0, 0.7);
}
.navbar.is-light .navbar-brand > .navbar-item,
.navbar.is-light .navbar-brand .navbar-link {
  color: rgba(0, 0, 0, 0.7);
}
.navbar.is-light .navbar-brand > a.navbar-item:focus, .navbar.is-light .navbar-brand > a.navbar-item:hover, .navbar.is-light .navbar-brand > a.navbar-item.is-active,
.navbar.is-light .navbar-brand .navbar-link:focus,
.navbar.is-light .navbar-brand .navbar-link:hover,
.navbar.is-light .navbar-brand .navbar-link.is-active {
  background-color: #e8e8e8;
  color: rgba(0, 0, 0, 0.7);
}
.navbar.is-light .navbar-brand .navbar-link::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.navbar.is-light .navbar-burger {
  color: rgba(0, 0, 0, 0.7);
}
@media screen and (min-width: 1024px) {
  .navbar.is-light .navbar-start > .navbar-item,
  .navbar.is-light .navbar-start .navbar-link,
  .navbar.is-light .navbar-end > .navbar-item,
  .navbar.is-light .navbar-end .navbar-link {
    color: rgba(0, 0, 0, 0.7);
  }
  .navbar.is-light .navbar-start > a.navbar-item:focus, .navbar.is-light .navbar-start > a.navbar-item:hover, .navbar.is-light .navbar-start > a.navbar-item.is-active,
  .navbar.is-light .navbar-start .navbar-link:focus,
  .navbar.is-light .navbar-start .navbar-link:hover,
  .navbar.is-light .navbar-start .navbar-link.is-active,
  .navbar.is-light .navbar-end > a.navbar-item:focus,
  .navbar.is-light .navbar-end > a.navbar-item:hover,
  .navbar.is-light .navbar-end > a.navbar-item.is-active,
  .navbar.is-light .navbar-end .navbar-link:focus,
  .navbar.is-light .navbar-end .navbar-link:hover,
  .navbar.is-light .navbar-end .navbar-link.is-active {
    background-color: #e8e8e8;
    color: rgba(0, 0, 0, 0.7);
  }
  .navbar.is-light .navbar-start .navbar-link::after,
  .navbar.is-light .navbar-end .navbar-link::after {
    border-color: rgba(0, 0, 0, 0.7);
  }
  .navbar.is-light .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-light .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-light .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #e8e8e8;
    color: rgba(0, 0, 0, 0.7);
  }
  .navbar.is-light .navbar-dropdown a.navbar-item.is-active {
    background-color: hsl(0, 0%, 96%);
    color: rgba(0, 0, 0, 0.7);
  }
}
.navbar.is-dark {
  background-color: hsl(0, 0%, 21%);
  color: #fff;
}
.navbar.is-dark .navbar-brand > .navbar-item,
.navbar.is-dark .navbar-brand .navbar-link {
  color: #fff;
}
.navbar.is-dark .navbar-brand > a.navbar-item:focus, .navbar.is-dark .navbar-brand > a.navbar-item:hover, .navbar.is-dark .navbar-brand > a.navbar-item.is-active,
.navbar.is-dark .navbar-brand .navbar-link:focus,
.navbar.is-dark .navbar-brand .navbar-link:hover,
.navbar.is-dark .navbar-brand .navbar-link.is-active {
  background-color: #292929;
  color: #fff;
}
.navbar.is-dark .navbar-brand .navbar-link::after {
  border-color: #fff;
}
.navbar.is-dark .navbar-burger {
  color: #fff;
}
@media screen and (min-width: 1024px) {
  .navbar.is-dark .navbar-start > .navbar-item,
  .navbar.is-dark .navbar-start .navbar-link,
  .navbar.is-dark .navbar-end > .navbar-item,
  .navbar.is-dark .navbar-end .navbar-link {
    color: #fff;
  }
  .navbar.is-dark .navbar-start > a.navbar-item:focus, .navbar.is-dark .navbar-start > a.navbar-item:hover, .navbar.is-dark .navbar-start > a.navbar-item.is-active,
  .navbar.is-dark .navbar-start .navbar-link:focus,
  .navbar.is-dark .navbar-start .navbar-link:hover,
  .navbar.is-dark .navbar-start .navbar-link.is-active,
  .navbar.is-dark .navbar-end > a.navbar-item:focus,
  .navbar.is-dark .navbar-end > a.navbar-item:hover,
  .navbar.is-dark .navbar-end > a.navbar-item.is-active,
  .navbar.is-dark .navbar-end .navbar-link:focus,
  .navbar.is-dark .navbar-end .navbar-link:hover,
  .navbar.is-dark .navbar-end .navbar-link.is-active {
    background-color: #292929;
    color: #fff;
  }
  .navbar.is-dark .navbar-start .navbar-link::after,
  .navbar.is-dark .navbar-end .navbar-link::after {
    border-color: #fff;
  }
  .navbar.is-dark .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-dark .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #292929;
    color: #fff;
  }
  .navbar.is-dark .navbar-dropdown a.navbar-item.is-active {
    background-color: hsl(0, 0%, 21%);
    color: #fff;
  }
}
.navbar.is-primary {
  background-color: #59c9a5;
  color: #fff;
}
.navbar.is-primary .navbar-brand > .navbar-item,
.navbar.is-primary .navbar-brand .navbar-link {
  color: #fff;
}
.navbar.is-primary .navbar-brand > a.navbar-item:focus, .navbar.is-primary .navbar-brand > a.navbar-item:hover, .navbar.is-primary .navbar-brand > a.navbar-item.is-active,
.navbar.is-primary .navbar-brand .navbar-link:focus,
.navbar.is-primary .navbar-brand .navbar-link:hover,
.navbar.is-primary .navbar-brand .navbar-link.is-active {
  background-color: #46c39b;
  color: #fff;
}
.navbar.is-primary .navbar-brand .navbar-link::after {
  border-color: #fff;
}
.navbar.is-primary .navbar-burger {
  color: #fff;
}
@media screen and (min-width: 1024px) {
  .navbar.is-primary .navbar-start > .navbar-item,
  .navbar.is-primary .navbar-start .navbar-link,
  .navbar.is-primary .navbar-end > .navbar-item,
  .navbar.is-primary .navbar-end .navbar-link {
    color: #fff;
  }
  .navbar.is-primary .navbar-start > a.navbar-item:focus, .navbar.is-primary .navbar-start > a.navbar-item:hover, .navbar.is-primary .navbar-start > a.navbar-item.is-active,
  .navbar.is-primary .navbar-start .navbar-link:focus,
  .navbar.is-primary .navbar-start .navbar-link:hover,
  .navbar.is-primary .navbar-start .navbar-link.is-active,
  .navbar.is-primary .navbar-end > a.navbar-item:focus,
  .navbar.is-primary .navbar-end > a.navbar-item:hover,
  .navbar.is-primary .navbar-end > a.navbar-item.is-active,
  .navbar.is-primary .navbar-end .navbar-link:focus,
  .navbar.is-primary .navbar-end .navbar-link:hover,
  .navbar.is-primary .navbar-end .navbar-link.is-active {
    background-color: #46c39b;
    color: #fff;
  }
  .navbar.is-primary .navbar-start .navbar-link::after,
  .navbar.is-primary .navbar-end .navbar-link::after {
    border-color: #fff;
  }
  .navbar.is-primary .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #46c39b;
    color: #fff;
  }
  .navbar.is-primary .navbar-dropdown a.navbar-item.is-active {
    background-color: #59c9a5;
    color: #fff;
  }
}
.navbar.is-info {
  background-color: hsl(207, 61%, 53%);
  color: #fff;
}
.navbar.is-info .navbar-brand > .navbar-item,
.navbar.is-info .navbar-brand .navbar-link {
  color: #fff;
}
.navbar.is-info .navbar-brand > a.navbar-item:focus, .navbar.is-info .navbar-brand > a.navbar-item:hover, .navbar.is-info .navbar-brand > a.navbar-item.is-active,
.navbar.is-info .navbar-brand .navbar-link:focus,
.navbar.is-info .navbar-brand .navbar-link:hover,
.navbar.is-info .navbar-brand .navbar-link.is-active {
  background-color: #3082c5;
  color: #fff;
}
.navbar.is-info .navbar-brand .navbar-link::after {
  border-color: #fff;
}
.navbar.is-info .navbar-burger {
  color: #fff;
}
@media screen and (min-width: 1024px) {
  .navbar.is-info .navbar-start > .navbar-item,
  .navbar.is-info .navbar-start .navbar-link,
  .navbar.is-info .navbar-end > .navbar-item,
  .navbar.is-info .navbar-end .navbar-link {
    color: #fff;
  }
  .navbar.is-info .navbar-start > a.navbar-item:focus, .navbar.is-info .navbar-start > a.navbar-item:hover, .navbar.is-info .navbar-start > a.navbar-item.is-active,
  .navbar.is-info .navbar-start .navbar-link:focus,
  .navbar.is-info .navbar-start .navbar-link:hover,
  .navbar.is-info .navbar-start .navbar-link.is-active,
  .navbar.is-info .navbar-end > a.navbar-item:focus,
  .navbar.is-info .navbar-end > a.navbar-item:hover,
  .navbar.is-info .navbar-end > a.navbar-item.is-active,
  .navbar.is-info .navbar-end .navbar-link:focus,
  .navbar.is-info .navbar-end .navbar-link:hover,
  .navbar.is-info .navbar-end .navbar-link.is-active {
    background-color: #3082c5;
    color: #fff;
  }
  .navbar.is-info .navbar-start .navbar-link::after,
  .navbar.is-info .navbar-end .navbar-link::after {
    border-color: #fff;
  }
  .navbar.is-info .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-info .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-info .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #3082c5;
    color: #fff;
  }
  .navbar.is-info .navbar-dropdown a.navbar-item.is-active {
    background-color: hsl(207, 61%, 53%);
    color: #fff;
  }
}
.navbar.is-success {
  background-color: hsl(153, 53%, 53%);
  color: #fff;
}
.navbar.is-success .navbar-brand > .navbar-item,
.navbar.is-success .navbar-brand .navbar-link {
  color: #fff;
}
.navbar.is-success .navbar-brand > a.navbar-item:focus, .navbar.is-success .navbar-brand > a.navbar-item:hover, .navbar.is-success .navbar-brand > a.navbar-item.is-active,
.navbar.is-success .navbar-brand .navbar-link:focus,
.navbar.is-success .navbar-brand .navbar-link:hover,
.navbar.is-success .navbar-brand .navbar-link.is-active {
  background-color: #3abb81;
  color: #fff;
}
.navbar.is-success .navbar-brand .navbar-link::after {
  border-color: #fff;
}
.navbar.is-success .navbar-burger {
  color: #fff;
}
@media screen and (min-width: 1024px) {
  .navbar.is-success .navbar-start > .navbar-item,
  .navbar.is-success .navbar-start .navbar-link,
  .navbar.is-success .navbar-end > .navbar-item,
  .navbar.is-success .navbar-end .navbar-link {
    color: #fff;
  }
  .navbar.is-success .navbar-start > a.navbar-item:focus, .navbar.is-success .navbar-start > a.navbar-item:hover, .navbar.is-success .navbar-start > a.navbar-item.is-active,
  .navbar.is-success .navbar-start .navbar-link:focus,
  .navbar.is-success .navbar-start .navbar-link:hover,
  .navbar.is-success .navbar-start .navbar-link.is-active,
  .navbar.is-success .navbar-end > a.navbar-item:focus,
  .navbar.is-success .navbar-end > a.navbar-item:hover,
  .navbar.is-success .navbar-end > a.navbar-item.is-active,
  .navbar.is-success .navbar-end .navbar-link:focus,
  .navbar.is-success .navbar-end .navbar-link:hover,
  .navbar.is-success .navbar-end .navbar-link.is-active {
    background-color: #3abb81;
    color: #fff;
  }
  .navbar.is-success .navbar-start .navbar-link::after,
  .navbar.is-success .navbar-end .navbar-link::after {
    border-color: #fff;
  }
  .navbar.is-success .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #3abb81;
    color: #fff;
  }
  .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
    background-color: hsl(153, 53%, 53%);
    color: #fff;
  }
}
.navbar.is-warning {
  background-color: hsl(44, 100%, 77%);
  color: rgba(0, 0, 0, 0.7);
}
.navbar.is-warning .navbar-brand > .navbar-item,
.navbar.is-warning .navbar-brand .navbar-link {
  color: rgba(0, 0, 0, 0.7);
}
.navbar.is-warning .navbar-brand > a.navbar-item:focus, .navbar.is-warning .navbar-brand > a.navbar-item:hover, .navbar.is-warning .navbar-brand > a.navbar-item.is-active,
.navbar.is-warning .navbar-brand .navbar-link:focus,
.navbar.is-warning .navbar-brand .navbar-link:hover,
.navbar.is-warning .navbar-brand .navbar-link.is-active {
  background-color: #ffd970;
  color: rgba(0, 0, 0, 0.7);
}
.navbar.is-warning .navbar-brand .navbar-link::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.navbar.is-warning .navbar-burger {
  color: rgba(0, 0, 0, 0.7);
}
@media screen and (min-width: 1024px) {
  .navbar.is-warning .navbar-start > .navbar-item,
  .navbar.is-warning .navbar-start .navbar-link,
  .navbar.is-warning .navbar-end > .navbar-item,
  .navbar.is-warning .navbar-end .navbar-link {
    color: rgba(0, 0, 0, 0.7);
  }
  .navbar.is-warning .navbar-start > a.navbar-item:focus, .navbar.is-warning .navbar-start > a.navbar-item:hover, .navbar.is-warning .navbar-start > a.navbar-item.is-active,
  .navbar.is-warning .navbar-start .navbar-link:focus,
  .navbar.is-warning .navbar-start .navbar-link:hover,
  .navbar.is-warning .navbar-start .navbar-link.is-active,
  .navbar.is-warning .navbar-end > a.navbar-item:focus,
  .navbar.is-warning .navbar-end > a.navbar-item:hover,
  .navbar.is-warning .navbar-end > a.navbar-item.is-active,
  .navbar.is-warning .navbar-end .navbar-link:focus,
  .navbar.is-warning .navbar-end .navbar-link:hover,
  .navbar.is-warning .navbar-end .navbar-link.is-active {
    background-color: #ffd970;
    color: rgba(0, 0, 0, 0.7);
  }
  .navbar.is-warning .navbar-start .navbar-link::after,
  .navbar.is-warning .navbar-end .navbar-link::after {
    border-color: rgba(0, 0, 0, 0.7);
  }
  .navbar.is-warning .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-warning .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-warning .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #ffd970;
    color: rgba(0, 0, 0, 0.7);
  }
  .navbar.is-warning .navbar-dropdown a.navbar-item.is-active {
    background-color: hsl(44, 100%, 77%);
    color: rgba(0, 0, 0, 0.7);
  }
}
.navbar.is-danger {
  background-color: hsl(348, 86%, 61%);
  color: #fff;
}
.navbar.is-danger .navbar-brand > .navbar-item,
.navbar.is-danger .navbar-brand .navbar-link {
  color: #fff;
}
.navbar.is-danger .navbar-brand > a.navbar-item:focus, .navbar.is-danger .navbar-brand > a.navbar-item:hover, .navbar.is-danger .navbar-brand > a.navbar-item.is-active,
.navbar.is-danger .navbar-brand .navbar-link:focus,
.navbar.is-danger .navbar-brand .navbar-link:hover,
.navbar.is-danger .navbar-brand .navbar-link.is-active {
  background-color: #ef2e55;
  color: #fff;
}
.navbar.is-danger .navbar-brand .navbar-link::after {
  border-color: #fff;
}
.navbar.is-danger .navbar-burger {
  color: #fff;
}
@media screen and (min-width: 1024px) {
  .navbar.is-danger .navbar-start > .navbar-item,
  .navbar.is-danger .navbar-start .navbar-link,
  .navbar.is-danger .navbar-end > .navbar-item,
  .navbar.is-danger .navbar-end .navbar-link {
    color: #fff;
  }
  .navbar.is-danger .navbar-start > a.navbar-item:focus, .navbar.is-danger .navbar-start > a.navbar-item:hover, .navbar.is-danger .navbar-start > a.navbar-item.is-active,
  .navbar.is-danger .navbar-start .navbar-link:focus,
  .navbar.is-danger .navbar-start .navbar-link:hover,
  .navbar.is-danger .navbar-start .navbar-link.is-active,
  .navbar.is-danger .navbar-end > a.navbar-item:focus,
  .navbar.is-danger .navbar-end > a.navbar-item:hover,
  .navbar.is-danger .navbar-end > a.navbar-item.is-active,
  .navbar.is-danger .navbar-end .navbar-link:focus,
  .navbar.is-danger .navbar-end .navbar-link:hover,
  .navbar.is-danger .navbar-end .navbar-link.is-active {
    background-color: #ef2e55;
    color: #fff;
  }
  .navbar.is-danger .navbar-start .navbar-link::after,
  .navbar.is-danger .navbar-end .navbar-link::after {
    border-color: #fff;
  }
  .navbar.is-danger .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #ef2e55;
    color: #fff;
  }
  .navbar.is-danger .navbar-dropdown a.navbar-item.is-active {
    background-color: hsl(348, 86%, 61%);
    color: #fff;
  }
}
.navbar.is-twitter {
  background-color: #4099ff;
  color: #fff;
}
.navbar.is-twitter .navbar-brand > .navbar-item,
.navbar.is-twitter .navbar-brand .navbar-link {
  color: #fff;
}
.navbar.is-twitter .navbar-brand > a.navbar-item:focus, .navbar.is-twitter .navbar-brand > a.navbar-item:hover, .navbar.is-twitter .navbar-brand > a.navbar-item.is-active,
.navbar.is-twitter .navbar-brand .navbar-link:focus,
.navbar.is-twitter .navbar-brand .navbar-link:hover,
.navbar.is-twitter .navbar-brand .navbar-link.is-active {
  background-color: #278bff;
  color: #fff;
}
.navbar.is-twitter .navbar-brand .navbar-link::after {
  border-color: #fff;
}
.navbar.is-twitter .navbar-burger {
  color: #fff;
}
@media screen and (min-width: 1024px) {
  .navbar.is-twitter .navbar-start > .navbar-item,
  .navbar.is-twitter .navbar-start .navbar-link,
  .navbar.is-twitter .navbar-end > .navbar-item,
  .navbar.is-twitter .navbar-end .navbar-link {
    color: #fff;
  }
  .navbar.is-twitter .navbar-start > a.navbar-item:focus, .navbar.is-twitter .navbar-start > a.navbar-item:hover, .navbar.is-twitter .navbar-start > a.navbar-item.is-active,
  .navbar.is-twitter .navbar-start .navbar-link:focus,
  .navbar.is-twitter .navbar-start .navbar-link:hover,
  .navbar.is-twitter .navbar-start .navbar-link.is-active,
  .navbar.is-twitter .navbar-end > a.navbar-item:focus,
  .navbar.is-twitter .navbar-end > a.navbar-item:hover,
  .navbar.is-twitter .navbar-end > a.navbar-item.is-active,
  .navbar.is-twitter .navbar-end .navbar-link:focus,
  .navbar.is-twitter .navbar-end .navbar-link:hover,
  .navbar.is-twitter .navbar-end .navbar-link.is-active {
    background-color: #278bff;
    color: #fff;
  }
  .navbar.is-twitter .navbar-start .navbar-link::after,
  .navbar.is-twitter .navbar-end .navbar-link::after {
    border-color: #fff;
  }
  .navbar.is-twitter .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-twitter .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-twitter .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #278bff;
    color: #fff;
  }
  .navbar.is-twitter .navbar-dropdown a.navbar-item.is-active {
    background-color: #4099ff;
    color: #fff;
  }
}
.navbar > .container {
  align-items: stretch;
  display: flex;
  min-height: 3.25rem;
  width: 100%;
}
.navbar.has-shadow {
  box-shadow: 0 2px 0 0 hsl(0, 0%, 96%);
}
.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 30;
}
.navbar.is-fixed-bottom {
  bottom: 0;
}
.navbar.is-fixed-bottom.has-shadow {
  box-shadow: 0 -2px 0 0 hsl(0, 0%, 96%);
}
.navbar.is-fixed-top {
  top: 0;
}

html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
  padding-top: 3.25rem;
}
html.has-navbar-fixed-bottom,
body.has-navbar-fixed-bottom {
  padding-bottom: 3.25rem;
}

.navbar-brand,
.navbar-tabs {
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  min-height: 3.25rem;
}

.navbar-brand a.navbar-item:focus, .navbar-brand a.navbar-item:hover {
  background-color: transparent;
}

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.navbar-burger {
  color: hsl(0, 0%, 29%);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  height: 3.25rem;
  position: relative;
  width: 3.25rem;
  margin-left: auto;
}
.navbar-burger span {
  background-color: currentColor;
  display: block;
  height: 1px;
  left: calc(50% - 8px);
  position: absolute;
  transform-origin: center;
  transition-duration: 86ms;
  transition-property: background-color, opacity, transform;
  transition-timing-function: ease-out;
  width: 16px;
}
.navbar-burger span:nth-child(1) {
  top: calc(50% - 6px);
}
.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px);
}
.navbar-burger span:nth-child(3) {
  top: calc(50% + 4px);
}
.navbar-burger:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.navbar-burger.is-active span:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
}
.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}
.navbar-burger.is-active span:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}

.navbar-menu {
  display: none;
}

.navbar-item,
.navbar-link {
  color: hsl(0, 0%, 29%);
  display: block;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  position: relative;
}
.navbar-item .icon:only-child,
.navbar-link .icon:only-child {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

a.navbar-item,
.navbar-link {
  cursor: pointer;
}
a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  background-color: hsl(0, 0%, 98%);
  color: #59c9a5;
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
}
.navbar-item img {
  max-height: 1.75rem;
}
.navbar-item.has-dropdown {
  padding: 0;
}
.navbar-item.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}
.navbar-item.is-tab {
  border-bottom: 1px solid transparent;
  min-height: 3.25rem;
  padding-bottom: calc(0.5rem - 1px);
}
.navbar-item.is-tab:focus, .navbar-item.is-tab:hover {
  background-color: transparent;
  border-bottom-color: #59c9a5;
}
.navbar-item.is-tab.is-active {
  background-color: transparent;
  border-bottom-color: #59c9a5;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  color: #59c9a5;
  padding-bottom: calc(0.5rem - 3px);
}

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-link:not(.is-arrowless) {
  padding-right: 2.5em;
}
.navbar-link:not(.is-arrowless)::after {
  border-color: #59c9a5;
  margin-top: -0.375em;
  right: 1.125em;
}

.navbar-dropdown {
  font-size: 0.875rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-divider {
  background-color: hsl(0, 0%, 96%);
  border: none;
  display: none;
  height: 2px;
  margin: 0.5rem 0;
}

@media screen and (max-width: 1023px) {
  .navbar > .container {
    display: block;
  }
  .navbar-brand .navbar-item,
  .navbar-tabs .navbar-item {
    align-items: center;
    display: flex;
  }
  .navbar-link::after {
    display: none;
  }
  .navbar-menu {
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
    padding: 0.5rem 0;
  }
  .navbar-menu.is-active {
    display: block;
  }
  .navbar.is-fixed-bottom-touch, .navbar.is-fixed-top-touch {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 30;
  }
  .navbar.is-fixed-bottom-touch {
    bottom: 0;
  }
  .navbar.is-fixed-bottom-touch.has-shadow {
    box-shadow: 0 -2px 3px rgba(10, 10, 10, 0.1);
  }
  .navbar.is-fixed-top-touch {
    top: 0;
  }
  .navbar.is-fixed-top .navbar-menu, .navbar.is-fixed-top-touch .navbar-menu {
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 3.25rem);
    overflow: auto;
  }
  html.has-navbar-fixed-top-touch,
  body.has-navbar-fixed-top-touch {
    padding-top: 3.25rem;
  }
  html.has-navbar-fixed-bottom-touch,
  body.has-navbar-fixed-bottom-touch {
    padding-bottom: 3.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .navbar,
  .navbar-menu,
  .navbar-start,
  .navbar-end {
    align-items: stretch;
    display: flex;
  }
  .navbar {
    min-height: 3.25rem;
  }
  .navbar.is-spaced {
    padding: 1rem 2rem;
  }
  .navbar.is-spaced .navbar-start,
  .navbar.is-spaced .navbar-end {
    align-items: center;
  }
  .navbar.is-spaced a.navbar-item,
  .navbar.is-spaced .navbar-link {
    border-radius: 4px;
  }
  .navbar.is-transparent a.navbar-item:focus, .navbar.is-transparent a.navbar-item:hover, .navbar.is-transparent a.navbar-item.is-active,
  .navbar.is-transparent .navbar-link:focus,
  .navbar.is-transparent .navbar-link:hover,
  .navbar.is-transparent .navbar-link.is-active {
    background-color: transparent !important;
  }
  .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus-within .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:hover .navbar-link {
    background-color: transparent !important;
  }
  .navbar.is-transparent .navbar-dropdown a.navbar-item:focus, .navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
    background-color: hsl(0, 0%, 96%);
    color: hsl(0, 0%, 4%);
  }
  .navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
    background-color: hsl(0, 0%, 96%);
    color: #59c9a5;
  }
  .navbar-burger {
    display: none;
  }
  .navbar-item,
  .navbar-link {
    align-items: center;
    display: flex;
  }
  .navbar-item.has-dropdown {
    align-items: stretch;
  }
  .navbar-item.has-dropdown-up .navbar-link::after {
    transform: rotate(135deg) translate(0.25em, -0.25em);
  }
  .navbar-item.has-dropdown-up .navbar-dropdown {
    border-bottom: 2px solid hsl(0, 0%, 86%);
    border-radius: 6px 6px 0 0;
    border-top: none;
    bottom: 100%;
    box-shadow: 0 -8px 8px rgba(10, 10, 10, 0.1);
    top: auto;
  }
  .navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }
  .navbar.is-spaced .navbar-item.is-active .navbar-dropdown, .navbar-item.is-active .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }
  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
  }
  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-dropdown {
    background-color: hsl(0, 0%, 100%);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 2px solid hsl(0, 0%, 86%);
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
    display: none;
    font-size: 0.875rem;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    z-index: 20;
  }
  .navbar-dropdown .navbar-item {
    padding: 0.375rem 1rem;
    white-space: nowrap;
  }
  .navbar-dropdown a.navbar-item {
    padding-right: 3rem;
  }
  .navbar-dropdown a.navbar-item:focus, .navbar-dropdown a.navbar-item:hover {
    background-color: hsl(0, 0%, 96%);
    color: hsl(0, 0%, 4%);
  }
  .navbar-dropdown a.navbar-item.is-active {
    background-color: hsl(0, 0%, 96%);
    color: #59c9a5;
  }
  .navbar.is-spaced .navbar-dropdown, .navbar-dropdown.is-boxed {
    border-radius: 6px;
    border-top: none;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    display: block;
    opacity: 0;
    pointer-events: none;
    top: calc(100% + (-4px));
    transform: translateY(-5px);
    transition-duration: 86ms;
    transition-property: opacity, transform;
  }
  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }
  .navbar-divider {
    display: block;
  }
  .navbar > .container .navbar-brand,
  .container > .navbar .navbar-brand {
    margin-left: -0.75rem;
  }
  .navbar > .container .navbar-menu,
  .container > .navbar .navbar-menu {
    margin-right: -0.75rem;
  }
  .navbar.is-fixed-bottom-desktop, .navbar.is-fixed-top-desktop {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 30;
  }
  .navbar.is-fixed-bottom-desktop {
    bottom: 0;
  }
  .navbar.is-fixed-bottom-desktop.has-shadow {
    box-shadow: 0 -2px 3px rgba(10, 10, 10, 0.1);
  }
  .navbar.is-fixed-top-desktop {
    top: 0;
  }
  html.has-navbar-fixed-top-desktop,
  body.has-navbar-fixed-top-desktop {
    padding-top: 3.25rem;
  }
  html.has-navbar-fixed-bottom-desktop,
  body.has-navbar-fixed-bottom-desktop {
    padding-bottom: 3.25rem;
  }
  html.has-spaced-navbar-fixed-top,
  body.has-spaced-navbar-fixed-top {
    padding-top: 5.25rem;
  }
  html.has-spaced-navbar-fixed-bottom,
  body.has-spaced-navbar-fixed-bottom {
    padding-bottom: 5.25rem;
  }
  a.navbar-item.is-active,
  .navbar-link.is-active {
    color: hsl(0, 0%, 4%);
  }
  a.navbar-item.is-active:not(:focus):not(:hover),
  .navbar-link.is-active:not(:focus):not(:hover) {
    background-color: transparent;
  }
  .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: hsl(0, 0%, 98%);
  }
}
.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 3.25rem);
}

.pagination {
  font-size: 1rem;
  margin: -0.25rem;
}
.pagination.is-small {
  font-size: 0.75rem;
}
.pagination.is-medium {
  font-size: 1.25rem;
}
.pagination.is-large {
  font-size: 1.5rem;
}
.pagination.is-rounded .pagination-previous,
.pagination.is-rounded .pagination-next {
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 9999px;
}
.pagination.is-rounded .pagination-link {
  border-radius: 9999px;
}

.pagination,
.pagination-list {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.pagination-previous,
.pagination-next,
.pagination-link,
.pagination-ellipsis {
  font-size: 1em;
  justify-content: center;
  margin: 0.25rem;
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-align: center;
}

.pagination-previous,
.pagination-next,
.pagination-link {
  border-color: hsl(0, 0%, 86%);
  color: hsl(0, 0%, 21%);
  min-width: 2.5em;
}
.pagination-previous:hover,
.pagination-next:hover,
.pagination-link:hover {
  border-color: hsl(0, 0%, 71%);
  color: hsl(0, 0%, 21%);
}
.pagination-previous:focus,
.pagination-next:focus,
.pagination-link:focus {
  border-color: #59c9a5;
}
.pagination-previous:active,
.pagination-next:active,
.pagination-link:active {
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.2);
}
.pagination-previous[disabled], .pagination-previous.is-disabled,
.pagination-next[disabled],
.pagination-next.is-disabled,
.pagination-link[disabled],
.pagination-link.is-disabled {
  background-color: hsl(0, 0%, 86%);
  border-color: hsl(0, 0%, 86%);
  box-shadow: none;
  color: hsl(0, 0%, 48%);
  opacity: 0.5;
}

.pagination-previous,
.pagination-next {
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
}

.pagination-link.is-current {
  background-color: #59c9a5;
  border-color: #59c9a5;
  color: #fff;
}

.pagination-ellipsis {
  color: hsl(0, 0%, 71%);
  pointer-events: none;
}

.pagination-list {
  flex-wrap: wrap;
}
.pagination-list li {
  list-style: none;
}

@media screen and (max-width: 768px) {
  .pagination {
    flex-wrap: wrap;
  }
  .pagination-previous,
  .pagination-next {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .pagination-list li {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
@media screen and (min-width: 769px), print {
  .pagination-list {
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    order: 1;
  }
  .pagination-previous,
  .pagination-next,
  .pagination-link,
  .pagination-ellipsis {
    margin-bottom: 0;
    margin-top: 0;
  }
  .pagination-previous {
    order: 2;
  }
  .pagination-next {
    order: 3;
  }
  .pagination {
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 0;
  }
  .pagination.is-centered .pagination-previous {
    order: 1;
  }
  .pagination.is-centered .pagination-list {
    justify-content: center;
    order: 2;
  }
  .pagination.is-centered .pagination-next {
    order: 3;
  }
  .pagination.is-right .pagination-previous {
    order: 1;
  }
  .pagination.is-right .pagination-next {
    order: 2;
  }
  .pagination.is-right .pagination-list {
    justify-content: flex-end;
    order: 3;
  }
}
.panel {
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  font-size: 1rem;
}
.panel:not(:last-child) {
  margin-bottom: 1.5rem;
}
.panel.is-white .panel-heading {
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 4%);
}
.panel.is-white .panel-tabs a.is-active {
  border-bottom-color: hsl(0, 0%, 100%);
}
.panel.is-white .panel-block.is-active .panel-icon {
  color: hsl(0, 0%, 100%);
}
.panel.is-black .panel-heading {
  background-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 100%);
}
.panel.is-black .panel-tabs a.is-active {
  border-bottom-color: hsl(0, 0%, 4%);
}
.panel.is-black .panel-block.is-active .panel-icon {
  color: hsl(0, 0%, 4%);
}
.panel.is-light .panel-heading {
  background-color: hsl(0, 0%, 96%);
  color: rgba(0, 0, 0, 0.7);
}
.panel.is-light .panel-tabs a.is-active {
  border-bottom-color: hsl(0, 0%, 96%);
}
.panel.is-light .panel-block.is-active .panel-icon {
  color: hsl(0, 0%, 96%);
}
.panel.is-dark .panel-heading {
  background-color: hsl(0, 0%, 21%);
  color: #fff;
}
.panel.is-dark .panel-tabs a.is-active {
  border-bottom-color: hsl(0, 0%, 21%);
}
.panel.is-dark .panel-block.is-active .panel-icon {
  color: hsl(0, 0%, 21%);
}
.panel.is-primary .panel-heading {
  background-color: #59c9a5;
  color: #fff;
}
.panel.is-primary .panel-tabs a.is-active {
  border-bottom-color: #59c9a5;
}
.panel.is-primary .panel-block.is-active .panel-icon {
  color: #59c9a5;
}
.panel.is-info .panel-heading {
  background-color: hsl(207, 61%, 53%);
  color: #fff;
}
.panel.is-info .panel-tabs a.is-active {
  border-bottom-color: hsl(207, 61%, 53%);
}
.panel.is-info .panel-block.is-active .panel-icon {
  color: hsl(207, 61%, 53%);
}
.panel.is-success .panel-heading {
  background-color: hsl(153, 53%, 53%);
  color: #fff;
}
.panel.is-success .panel-tabs a.is-active {
  border-bottom-color: hsl(153, 53%, 53%);
}
.panel.is-success .panel-block.is-active .panel-icon {
  color: hsl(153, 53%, 53%);
}
.panel.is-warning .panel-heading {
  background-color: hsl(44, 100%, 77%);
  color: rgba(0, 0, 0, 0.7);
}
.panel.is-warning .panel-tabs a.is-active {
  border-bottom-color: hsl(44, 100%, 77%);
}
.panel.is-warning .panel-block.is-active .panel-icon {
  color: hsl(44, 100%, 77%);
}
.panel.is-danger .panel-heading {
  background-color: hsl(348, 86%, 61%);
  color: #fff;
}
.panel.is-danger .panel-tabs a.is-active {
  border-bottom-color: hsl(348, 86%, 61%);
}
.panel.is-danger .panel-block.is-active .panel-icon {
  color: hsl(348, 86%, 61%);
}
.panel.is-twitter .panel-heading {
  background-color: #4099ff;
  color: #fff;
}
.panel.is-twitter .panel-tabs a.is-active {
  border-bottom-color: #4099ff;
}
.panel.is-twitter .panel-block.is-active .panel-icon {
  color: #4099ff;
}

.panel-tabs:not(:last-child),
.panel-block:not(:last-child) {
  border-bottom: 1px solid hsl(0, 0%, 93%);
}

.panel-heading {
  background-color: hsl(0, 0%, 93%);
  border-radius: 6px 6px 0 0;
  color: hsl(0, 0%, 21%);
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
}

.panel-tabs {
  align-items: flex-end;
  display: flex;
  font-size: 0.875em;
  justify-content: center;
}
.panel-tabs a {
  border-bottom: 1px solid hsl(0, 0%, 86%);
  margin-bottom: -1px;
  padding: 0.5em;
}
.panel-tabs a.is-active {
  border-bottom-color: hsl(0, 0%, 29%);
  color: hsl(0, 0%, 21%);
}

.panel-list a {
  color: hsl(0, 0%, 29%);
}
.panel-list a:hover {
  color: #59c9a5;
}

.panel-block {
  align-items: center;
  color: hsl(0, 0%, 21%);
  display: flex;
  justify-content: flex-start;
  padding: 0.5em 0.75em;
}
.panel-block input[type=checkbox] {
  margin-right: 0.75em;
}
.panel-block > .control {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}
.panel-block.is-wrapped {
  flex-wrap: wrap;
}
.panel-block.is-active {
  border-left-color: #59c9a5;
  color: hsl(0, 0%, 21%);
}
.panel-block.is-active .panel-icon {
  color: #59c9a5;
}
.panel-block:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

a.panel-block,
label.panel-block {
  cursor: pointer;
}
a.panel-block:hover,
label.panel-block:hover {
  background-color: hsl(0, 0%, 96%);
}

.panel-icon {
  display: inline-block;
  font-size: 14px;
  height: 1em;
  line-height: 1em;
  text-align: center;
  vertical-align: top;
  width: 1em;
  color: hsl(0, 0%, 48%);
  margin-right: 0.75em;
}
.panel-icon .fa {
  font-size: inherit;
  line-height: inherit;
}

.tabs {
  -webkit-overflow-scrolling: touch;
  align-items: stretch;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.tabs a {
  align-items: center;
  border-bottom-color: hsl(0, 0%, 86%);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: hsl(0, 0%, 29%);
  display: flex;
  justify-content: center;
  margin-bottom: -1px;
  padding: 0.5em 1em;
  vertical-align: top;
}
.tabs a:hover {
  border-bottom-color: hsl(0, 0%, 21%);
  color: hsl(0, 0%, 21%);
}
.tabs li {
  display: block;
}
.tabs li.is-active a {
  border-bottom-color: #59c9a5;
  color: #59c9a5;
}
.tabs ul {
  align-items: center;
  border-bottom-color: hsl(0, 0%, 86%);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
}
.tabs ul.is-left {
  padding-right: 0.75em;
}
.tabs ul.is-center {
  flex: none;
  justify-content: center;
  padding-left: 0.75em;
  padding-right: 0.75em;
}
.tabs ul.is-right {
  justify-content: flex-end;
  padding-left: 0.75em;
}
.tabs .icon:first-child {
  margin-right: 0.5em;
}
.tabs .icon:last-child {
  margin-left: 0.5em;
}
.tabs.is-centered ul {
  justify-content: center;
}
.tabs.is-right ul {
  justify-content: flex-end;
}
.tabs.is-boxed a {
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}
.tabs.is-boxed a:hover {
  background-color: hsl(0, 0%, 96%);
  border-bottom-color: hsl(0, 0%, 86%);
}
.tabs.is-boxed li.is-active a {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 86%);
  border-bottom-color: transparent !important;
}
.tabs.is-fullwidth li {
  flex-grow: 1;
  flex-shrink: 0;
}
.tabs.is-toggle a {
  border-color: hsl(0, 0%, 86%);
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
}
.tabs.is-toggle a:hover {
  background-color: hsl(0, 0%, 96%);
  border-color: hsl(0, 0%, 71%);
  z-index: 2;
}
.tabs.is-toggle li + li {
  margin-left: -1px;
}
.tabs.is-toggle li:first-child a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.tabs.is-toggle li:last-child a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.tabs.is-toggle li.is-active a {
  background-color: #59c9a5;
  border-color: #59c9a5;
  color: #fff;
  z-index: 1;
}
.tabs.is-toggle ul {
  border-bottom: none;
}
.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-bottom-left-radius: 9999px;
  border-top-left-radius: 9999px;
  padding-left: 1.25em;
}
.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-bottom-right-radius: 9999px;
  border-top-right-radius: 9999px;
  padding-right: 1.25em;
}
.tabs.is-small {
  font-size: 0.75rem;
}
.tabs.is-medium {
  font-size: 1.25rem;
}
.tabs.is-large {
  font-size: 1.5rem;
}

/* Bulma Form */
.select select, .textarea, .input {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 86%);
  border-radius: 4px;
  color: hsl(0, 0%, 21%);
}
.select select::-moz-placeholder, .textarea::-moz-placeholder, .input::-moz-placeholder {
  color: rgba(54, 54, 54, 0.3);
}
.select select::-webkit-input-placeholder, .textarea::-webkit-input-placeholder, .input::-webkit-input-placeholder {
  color: rgba(54, 54, 54, 0.3);
}
.select select:-moz-placeholder, .textarea:-moz-placeholder, .input:-moz-placeholder {
  color: rgba(54, 54, 54, 0.3);
}
.select select:-ms-input-placeholder, .textarea:-ms-input-placeholder, .input:-ms-input-placeholder {
  color: rgba(54, 54, 54, 0.3);
}
.select select:hover, .textarea:hover, .input:hover, .select select.is-hovered, .is-hovered.textarea, .is-hovered.input {
  border-color: hsl(0, 0%, 71%);
}
.select select:focus, .textarea:focus, .input:focus, .select select.is-focused, .is-focused.textarea, .is-focused.input, .select select:active, .textarea:active, .input:active, .select select.is-active, .is-active.textarea, .is-active.input {
  border-color: #59c9a5;
  box-shadow: 0 0 0 0.125em rgba(89, 201, 165, 0.25);
}
.select select[disabled], [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input {
  background-color: hsl(0, 0%, 96%);
  border-color: hsl(0, 0%, 96%);
  box-shadow: none;
  color: hsl(0, 0%, 48%);
}
.select select[disabled]::-moz-placeholder, [disabled].textarea::-moz-placeholder, [disabled].input::-moz-placeholder, fieldset[disabled] .select select::-moz-placeholder, .select fieldset[disabled] select::-moz-placeholder, fieldset[disabled] .textarea::-moz-placeholder, fieldset[disabled] .input::-moz-placeholder {
  color: rgba(122, 122, 122, 0.3);
}
.select select[disabled]::-webkit-input-placeholder, [disabled].textarea::-webkit-input-placeholder, [disabled].input::-webkit-input-placeholder, fieldset[disabled] .select select::-webkit-input-placeholder, .select fieldset[disabled] select::-webkit-input-placeholder, fieldset[disabled] .textarea::-webkit-input-placeholder, fieldset[disabled] .input::-webkit-input-placeholder {
  color: rgba(122, 122, 122, 0.3);
}
.select select[disabled]:-moz-placeholder, [disabled].textarea:-moz-placeholder, [disabled].input:-moz-placeholder, fieldset[disabled] .select select:-moz-placeholder, .select fieldset[disabled] select:-moz-placeholder, fieldset[disabled] .textarea:-moz-placeholder, fieldset[disabled] .input:-moz-placeholder {
  color: rgba(122, 122, 122, 0.3);
}
.select select[disabled]:-ms-input-placeholder, [disabled].textarea:-ms-input-placeholder, [disabled].input:-ms-input-placeholder, fieldset[disabled] .select select:-ms-input-placeholder, .select fieldset[disabled] select:-ms-input-placeholder, fieldset[disabled] .textarea:-ms-input-placeholder, fieldset[disabled] .input:-ms-input-placeholder {
  color: rgba(122, 122, 122, 0.3);
}

.textarea, .input {
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  max-width: 100%;
  width: 100%;
}
[readonly].textarea, [readonly].input {
  box-shadow: none;
}
.is-white.textarea, .is-white.input {
  border-color: hsl(0, 0%, 100%);
}
.is-white.textarea:focus, .is-white.input:focus, .is-white.is-focused.textarea, .is-white.is-focused.input, .is-white.textarea:active, .is-white.input:active, .is-white.is-active.textarea, .is-white.is-active.input {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25);
}
.is-black.textarea, .is-black.input {
  border-color: hsl(0, 0%, 4%);
}
.is-black.textarea:focus, .is-black.input:focus, .is-black.is-focused.textarea, .is-black.is-focused.input, .is-black.textarea:active, .is-black.input:active, .is-black.is-active.textarea, .is-black.is-active.input {
  box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25);
}
.is-light.textarea, .is-light.input {
  border-color: hsl(0, 0%, 96%);
}
.is-light.textarea:focus, .is-light.input:focus, .is-light.is-focused.textarea, .is-light.is-focused.input, .is-light.textarea:active, .is-light.input:active, .is-light.is-active.textarea, .is-light.is-active.input {
  box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25);
}
.is-dark.textarea, .is-dark.input {
  border-color: hsl(0, 0%, 21%);
}
.is-dark.textarea:focus, .is-dark.input:focus, .is-dark.is-focused.textarea, .is-dark.is-focused.input, .is-dark.textarea:active, .is-dark.input:active, .is-dark.is-active.textarea, .is-dark.is-active.input {
  box-shadow: 0 0 0 0.125em rgba(54, 54, 54, 0.25);
}
.is-primary.textarea, .is-primary.input {
  border-color: #59c9a5;
}
.is-primary.textarea:focus, .is-primary.input:focus, .is-primary.is-focused.textarea, .is-primary.is-focused.input, .is-primary.textarea:active, .is-primary.input:active, .is-primary.is-active.textarea, .is-primary.is-active.input {
  box-shadow: 0 0 0 0.125em rgba(89, 201, 165, 0.25);
}
.is-info.textarea, .is-info.input {
  border-color: hsl(207, 61%, 53%);
}
.is-info.textarea:focus, .is-info.input:focus, .is-info.is-focused.textarea, .is-info.is-focused.input, .is-info.textarea:active, .is-info.input:active, .is-info.is-active.textarea, .is-info.is-active.input {
  box-shadow: 0 0 0 0.125em rgba(62, 142, 208, 0.25);
}
.is-success.textarea, .is-success.input {
  border-color: hsl(153, 53%, 53%);
}
.is-success.textarea:focus, .is-success.input:focus, .is-success.is-focused.textarea, .is-success.is-focused.input, .is-success.textarea:active, .is-success.input:active, .is-success.is-active.textarea, .is-success.is-active.input {
  box-shadow: 0 0 0 0.125em rgba(72, 199, 142, 0.25);
}
.is-warning.textarea, .is-warning.input {
  border-color: hsl(44, 100%, 77%);
}
.is-warning.textarea:focus, .is-warning.input:focus, .is-warning.is-focused.textarea, .is-warning.is-focused.input, .is-warning.textarea:active, .is-warning.input:active, .is-warning.is-active.textarea, .is-warning.is-active.input {
  box-shadow: 0 0 0 0.125em rgba(255, 224, 138, 0.25);
}
.is-danger.textarea, .is-danger.input {
  border-color: hsl(348, 86%, 61%);
}
.is-danger.textarea:focus, .is-danger.input:focus, .is-danger.is-focused.textarea, .is-danger.is-focused.input, .is-danger.textarea:active, .is-danger.input:active, .is-danger.is-active.textarea, .is-danger.is-active.input {
  box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25);
}
.is-twitter.textarea, .is-twitter.input {
  border-color: #4099ff;
}
.is-twitter.textarea:focus, .is-twitter.input:focus, .is-twitter.is-focused.textarea, .is-twitter.is-focused.input, .is-twitter.textarea:active, .is-twitter.input:active, .is-twitter.is-active.textarea, .is-twitter.is-active.input {
  box-shadow: 0 0 0 0.125em rgba(64, 153, 255, 0.25);
}
.is-small.textarea, .is-small.input {
  border-radius: 2px;
  font-size: 0.75rem;
}
.is-medium.textarea, .is-medium.input {
  font-size: 1.25rem;
}
.is-large.textarea, .is-large.input {
  font-size: 1.5rem;
}
.is-fullwidth.textarea, .is-fullwidth.input {
  display: block;
  width: 100%;
}
.is-inline.textarea, .is-inline.input {
  display: inline;
  width: auto;
}

.input.is-rounded {
  border-radius: 9999px;
  padding-left: calc(calc(0.75em - 1px) + 0.375em);
  padding-right: calc(calc(0.75em - 1px) + 0.375em);
}
.input.is-static {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: calc(0.75em - 1px);
  resize: vertical;
}
.textarea:not([rows]) {
  max-height: 40em;
  min-height: 8em;
}
.textarea[rows] {
  height: initial;
}
.textarea.has-fixed-size {
  resize: none;
}

.radio, .checkbox {
  cursor: pointer;
  display: inline-block;
  line-height: 1.25;
  position: relative;
}
.radio input, .checkbox input {
  cursor: pointer;
}
.radio:hover, .checkbox:hover {
  color: hsl(0, 0%, 21%);
}
[disabled].radio, [disabled].checkbox, fieldset[disabled] .radio, fieldset[disabled] .checkbox,
.radio input[disabled],
.checkbox input[disabled] {
  color: hsl(0, 0%, 48%);
  cursor: not-allowed;
}

.radio + .radio {
  margin-left: 0.5em;
}

.select {
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: top;
}
.select:not(.is-multiple) {
  height: 2.5em;
}
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #59c9a5;
  right: 1.125em;
  z-index: 4;
}
.select.is-rounded select {
  border-radius: 9999px;
  padding-left: 1em;
}
.select select {
  cursor: pointer;
  display: block;
  font-size: 1em;
  max-width: 100%;
  outline: none;
}
.select select::-ms-expand {
  display: none;
}
.select select[disabled]:hover, fieldset[disabled] .select select:hover {
  border-color: hsl(0, 0%, 96%);
}
.select select:not([multiple]) {
  padding-right: 2.5em;
}
.select select[multiple] {
  height: auto;
  padding: 0;
}
.select select[multiple] option {
  padding: 0.5em 1em;
}
.select:not(.is-multiple):not(.is-loading):hover::after {
  border-color: hsl(0, 0%, 21%);
}
.select.is-white:not(:hover)::after {
  border-color: hsl(0, 0%, 100%);
}
.select.is-white select {
  border-color: hsl(0, 0%, 100%);
}
.select.is-white select:hover, .select.is-white select.is-hovered {
  border-color: #f2f2f2;
}
.select.is-white select:focus, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select.is-active {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25);
}
.select.is-black:not(:hover)::after {
  border-color: hsl(0, 0%, 4%);
}
.select.is-black select {
  border-color: hsl(0, 0%, 4%);
}
.select.is-black select:hover, .select.is-black select.is-hovered {
  border-color: black;
}
.select.is-black select:focus, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select.is-active {
  box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25);
}
.select.is-light:not(:hover)::after {
  border-color: hsl(0, 0%, 96%);
}
.select.is-light select {
  border-color: hsl(0, 0%, 96%);
}
.select.is-light select:hover, .select.is-light select.is-hovered {
  border-color: #e8e8e8;
}
.select.is-light select:focus, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select.is-active {
  box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25);
}
.select.is-dark:not(:hover)::after {
  border-color: hsl(0, 0%, 21%);
}
.select.is-dark select {
  border-color: hsl(0, 0%, 21%);
}
.select.is-dark select:hover, .select.is-dark select.is-hovered {
  border-color: #292929;
}
.select.is-dark select:focus, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select.is-active {
  box-shadow: 0 0 0 0.125em rgba(54, 54, 54, 0.25);
}
.select.is-primary:not(:hover)::after {
  border-color: #59c9a5;
}
.select.is-primary select {
  border-color: #59c9a5;
}
.select.is-primary select:hover, .select.is-primary select.is-hovered {
  border-color: #46c39b;
}
.select.is-primary select:focus, .select.is-primary select.is-focused, .select.is-primary select:active, .select.is-primary select.is-active {
  box-shadow: 0 0 0 0.125em rgba(89, 201, 165, 0.25);
}
.select.is-info:not(:hover)::after {
  border-color: hsl(207, 61%, 53%);
}
.select.is-info select {
  border-color: hsl(207, 61%, 53%);
}
.select.is-info select:hover, .select.is-info select.is-hovered {
  border-color: #3082c5;
}
.select.is-info select:focus, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select.is-active {
  box-shadow: 0 0 0 0.125em rgba(62, 142, 208, 0.25);
}
.select.is-success:not(:hover)::after {
  border-color: hsl(153, 53%, 53%);
}
.select.is-success select {
  border-color: hsl(153, 53%, 53%);
}
.select.is-success select:hover, .select.is-success select.is-hovered {
  border-color: #3abb81;
}
.select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
  box-shadow: 0 0 0 0.125em rgba(72, 199, 142, 0.25);
}
.select.is-warning:not(:hover)::after {
  border-color: hsl(44, 100%, 77%);
}
.select.is-warning select {
  border-color: hsl(44, 100%, 77%);
}
.select.is-warning select:hover, .select.is-warning select.is-hovered {
  border-color: #ffd970;
}
.select.is-warning select:focus, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select.is-active {
  box-shadow: 0 0 0 0.125em rgba(255, 224, 138, 0.25);
}
.select.is-danger:not(:hover)::after {
  border-color: hsl(348, 86%, 61%);
}
.select.is-danger select {
  border-color: hsl(348, 86%, 61%);
}
.select.is-danger select:hover, .select.is-danger select.is-hovered {
  border-color: #ef2e55;
}
.select.is-danger select:focus, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select.is-active {
  box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25);
}
.select.is-twitter:not(:hover)::after {
  border-color: #4099ff;
}
.select.is-twitter select {
  border-color: #4099ff;
}
.select.is-twitter select:hover, .select.is-twitter select.is-hovered {
  border-color: #278bff;
}
.select.is-twitter select:focus, .select.is-twitter select.is-focused, .select.is-twitter select:active, .select.is-twitter select.is-active {
  box-shadow: 0 0 0 0.125em rgba(64, 153, 255, 0.25);
}
.select.is-small {
  border-radius: 2px;
  font-size: 0.75rem;
}
.select.is-medium {
  font-size: 1.25rem;
}
.select.is-large {
  font-size: 1.5rem;
}
.select.is-disabled::after {
  border-color: hsl(0, 0%, 48%) !important;
  opacity: 0.5;
}
.select.is-fullwidth {
  width: 100%;
}
.select.is-fullwidth select {
  width: 100%;
}
.select.is-loading::after {
  margin-top: 0;
  position: absolute;
  right: 0.625em;
  top: 0.625em;
  transform: none;
}
.select.is-loading.is-small:after {
  font-size: 0.75rem;
}
.select.is-loading.is-medium:after {
  font-size: 1.25rem;
}
.select.is-loading.is-large:after {
  font-size: 1.5rem;
}

.file {
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  position: relative;
}
.file.is-white .file-cta {
  background-color: hsl(0, 0%, 100%);
  border-color: transparent;
  color: hsl(0, 0%, 4%);
}
.file.is-white:hover .file-cta, .file.is-white.is-hovered .file-cta {
  background-color: #f9f9f9;
  border-color: transparent;
  color: hsl(0, 0%, 4%);
}
.file.is-white:focus .file-cta, .file.is-white.is-focused .file-cta {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.25);
  color: hsl(0, 0%, 4%);
}
.file.is-white:active .file-cta, .file.is-white.is-active .file-cta {
  background-color: #f2f2f2;
  border-color: transparent;
  color: hsl(0, 0%, 4%);
}
.file.is-black .file-cta {
  background-color: hsl(0, 0%, 4%);
  border-color: transparent;
  color: hsl(0, 0%, 100%);
}
.file.is-black:hover .file-cta, .file.is-black.is-hovered .file-cta {
  background-color: #040404;
  border-color: transparent;
  color: hsl(0, 0%, 100%);
}
.file.is-black:focus .file-cta, .file.is-black.is-focused .file-cta {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(10, 10, 10, 0.25);
  color: hsl(0, 0%, 100%);
}
.file.is-black:active .file-cta, .file.is-black.is-active .file-cta {
  background-color: black;
  border-color: transparent;
  color: hsl(0, 0%, 100%);
}
.file.is-light .file-cta {
  background-color: hsl(0, 0%, 96%);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.file.is-light:hover .file-cta, .file.is-light.is-hovered .file-cta {
  background-color: #eeeeee;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.file.is-light:focus .file-cta, .file.is-light.is-focused .file-cta {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(245, 245, 245, 0.25);
  color: rgba(0, 0, 0, 0.7);
}
.file.is-light:active .file-cta, .file.is-light.is-active .file-cta {
  background-color: #e8e8e8;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.file.is-dark .file-cta {
  background-color: hsl(0, 0%, 21%);
  border-color: transparent;
  color: #fff;
}
.file.is-dark:hover .file-cta, .file.is-dark.is-hovered .file-cta {
  background-color: #2f2f2f;
  border-color: transparent;
  color: #fff;
}
.file.is-dark:focus .file-cta, .file.is-dark.is-focused .file-cta {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(54, 54, 54, 0.25);
  color: #fff;
}
.file.is-dark:active .file-cta, .file.is-dark.is-active .file-cta {
  background-color: #292929;
  border-color: transparent;
  color: #fff;
}
.file.is-primary .file-cta {
  background-color: #59c9a5;
  border-color: transparent;
  color: #fff;
}
.file.is-primary:hover .file-cta, .file.is-primary.is-hovered .file-cta {
  background-color: #4fc6a0;
  border-color: transparent;
  color: #fff;
}
.file.is-primary:focus .file-cta, .file.is-primary.is-focused .file-cta {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(89, 201, 165, 0.25);
  color: #fff;
}
.file.is-primary:active .file-cta, .file.is-primary.is-active .file-cta {
  background-color: #46c39b;
  border-color: transparent;
  color: #fff;
}
.file.is-info .file-cta {
  background-color: hsl(207, 61%, 53%);
  border-color: transparent;
  color: #fff;
}
.file.is-info:hover .file-cta, .file.is-info.is-hovered .file-cta {
  background-color: #3488ce;
  border-color: transparent;
  color: #fff;
}
.file.is-info:focus .file-cta, .file.is-info.is-focused .file-cta {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(62, 142, 208, 0.25);
  color: #fff;
}
.file.is-info:active .file-cta, .file.is-info.is-active .file-cta {
  background-color: #3082c5;
  border-color: transparent;
  color: #fff;
}
.file.is-success .file-cta {
  background-color: hsl(153, 53%, 53%);
  border-color: transparent;
  color: #fff;
}
.file.is-success:hover .file-cta, .file.is-success.is-hovered .file-cta {
  background-color: #3ec487;
  border-color: transparent;
  color: #fff;
}
.file.is-success:focus .file-cta, .file.is-success.is-focused .file-cta {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(72, 199, 142, 0.25);
  color: #fff;
}
.file.is-success:active .file-cta, .file.is-success.is-active .file-cta {
  background-color: #3abb81;
  border-color: transparent;
  color: #fff;
}
.file.is-warning .file-cta {
  background-color: hsl(44, 100%, 77%);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.file.is-warning:hover .file-cta, .file.is-warning.is-hovered .file-cta {
  background-color: #ffdc7d;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.file.is-warning:focus .file-cta, .file.is-warning.is-focused .file-cta {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(255, 224, 138, 0.25);
  color: rgba(0, 0, 0, 0.7);
}
.file.is-warning:active .file-cta, .file.is-warning.is-active .file-cta {
  background-color: #ffd970;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.file.is-danger .file-cta {
  background-color: hsl(348, 86%, 61%);
  border-color: transparent;
  color: #fff;
}
.file.is-danger:hover .file-cta, .file.is-danger.is-hovered .file-cta {
  background-color: #f03a5f;
  border-color: transparent;
  color: #fff;
}
.file.is-danger:focus .file-cta, .file.is-danger.is-focused .file-cta {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(241, 70, 104, 0.25);
  color: #fff;
}
.file.is-danger:active .file-cta, .file.is-danger.is-active .file-cta {
  background-color: #ef2e55;
  border-color: transparent;
  color: #fff;
}
.file.is-twitter .file-cta {
  background-color: #4099ff;
  border-color: transparent;
  color: #fff;
}
.file.is-twitter:hover .file-cta, .file.is-twitter.is-hovered .file-cta {
  background-color: #3392ff;
  border-color: transparent;
  color: #fff;
}
.file.is-twitter:focus .file-cta, .file.is-twitter.is-focused .file-cta {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(64, 153, 255, 0.25);
  color: #fff;
}
.file.is-twitter:active .file-cta, .file.is-twitter.is-active .file-cta {
  background-color: #278bff;
  border-color: transparent;
  color: #fff;
}
.file.is-small {
  font-size: 0.75rem;
}
.file.is-normal {
  font-size: 1rem;
}
.file.is-medium {
  font-size: 1.25rem;
}
.file.is-medium .file-icon .fa {
  font-size: 21px;
}
.file.is-large {
  font-size: 1.5rem;
}
.file.is-large .file-icon .fa {
  font-size: 28px;
}
.file.has-name .file-cta {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.file.has-name .file-name {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.file.has-name.is-empty .file-cta {
  border-radius: 4px;
}
.file.has-name.is-empty .file-name {
  display: none;
}
.file.is-boxed .file-label {
  flex-direction: column;
}
.file.is-boxed .file-cta {
  flex-direction: column;
  height: auto;
  padding: 1em 3em;
}
.file.is-boxed .file-name {
  border-width: 0 1px 1px;
}
.file.is-boxed .file-icon {
  height: 1.5em;
  width: 1.5em;
}
.file.is-boxed .file-icon .fa {
  font-size: 21px;
}
.file.is-boxed.is-small .file-icon .fa {
  font-size: 14px;
}
.file.is-boxed.is-medium .file-icon .fa {
  font-size: 28px;
}
.file.is-boxed.is-large .file-icon .fa {
  font-size: 35px;
}
.file.is-boxed.has-name .file-cta {
  border-radius: 4px 4px 0 0;
}
.file.is-boxed.has-name .file-name {
  border-radius: 0 0 4px 4px;
  border-width: 0 1px 1px;
}
.file.is-centered {
  justify-content: center;
}
.file.is-fullwidth .file-label {
  width: 100%;
}
.file.is-fullwidth .file-name {
  flex-grow: 1;
  max-width: none;
}
.file.is-right {
  justify-content: flex-end;
}
.file.is-right .file-cta {
  border-radius: 0 4px 4px 0;
}
.file.is-right .file-name {
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px;
  order: -1;
}

.file-label {
  align-items: stretch;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}
.file-label:hover .file-cta {
  background-color: #eeeeee;
  color: hsl(0, 0%, 21%);
}
.file-label:hover .file-name {
  border-color: #d5d5d5;
}
.file-label:active .file-cta {
  background-color: #e8e8e8;
  color: hsl(0, 0%, 21%);
}
.file-label:active .file-name {
  border-color: #cfcfcf;
}

.file-input {
  height: 100%;
  left: 0;
  opacity: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.file-cta,
.file-name {
  border-color: hsl(0, 0%, 86%);
  border-radius: 4px;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap;
}

.file-cta {
  background-color: hsl(0, 0%, 96%);
  color: hsl(0, 0%, 29%);
}

.file-name {
  border-color: hsl(0, 0%, 86%);
  border-style: solid;
  border-width: 1px 1px 1px 0;
  display: block;
  max-width: 16em;
  overflow: hidden;
  text-align: inherit;
  text-overflow: ellipsis;
}

.file-icon {
  align-items: center;
  display: flex;
  height: 1em;
  justify-content: center;
  margin-right: 0.5em;
  width: 1em;
}
.file-icon .fa {
  font-size: 14px;
}

.label {
  color: hsl(0, 0%, 21%);
  display: block;
  font-size: 1rem;
  font-weight: 700;
}
.label:not(:last-child) {
  margin-bottom: 0.5em;
}
.label.is-small {
  font-size: 0.75rem;
}
.label.is-medium {
  font-size: 1.25rem;
}
.label.is-large {
  font-size: 1.5rem;
}

.help {
  display: block;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}
.help.is-white {
  color: hsl(0, 0%, 100%);
}
.help.is-black {
  color: hsl(0, 0%, 4%);
}
.help.is-light {
  color: hsl(0, 0%, 96%);
}
.help.is-dark {
  color: hsl(0, 0%, 21%);
}
.help.is-primary {
  color: #59c9a5;
}
.help.is-info {
  color: hsl(207, 61%, 53%);
}
.help.is-success {
  color: hsl(153, 53%, 53%);
}
.help.is-warning {
  color: hsl(44, 100%, 77%);
}
.help.is-danger {
  color: hsl(348, 86%, 61%);
}
.help.is-twitter {
  color: #4099ff;
}

.field:not(:last-child) {
  margin-bottom: 0.75rem;
}
.field.has-addons {
  display: flex;
  justify-content: flex-start;
}
.field.has-addons .control:not(:last-child) {
  margin-right: -1px;
}
.field.has-addons .control:not(:first-child):not(:last-child) .button,
.field.has-addons .control:not(:first-child):not(:last-child) .input,
.field.has-addons .control:not(:first-child):not(:last-child) .select select {
  border-radius: 0;
}
.field.has-addons .control:first-child:not(:only-child) .button,
.field.has-addons .control:first-child:not(:only-child) .input,
.field.has-addons .control:first-child:not(:only-child) .select select {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.field.has-addons .control:last-child:not(:only-child) .button,
.field.has-addons .control:last-child:not(:only-child) .input,
.field.has-addons .control:last-child:not(:only-child) .select select {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered,
.field.has-addons .control .input:not([disabled]):hover,
.field.has-addons .control .input:not([disabled]).is-hovered,
.field.has-addons .control .select select:not([disabled]):hover,
.field.has-addons .control .select select:not([disabled]).is-hovered {
  z-index: 2;
}
.field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active,
.field.has-addons .control .input:not([disabled]):focus,
.field.has-addons .control .input:not([disabled]).is-focused,
.field.has-addons .control .input:not([disabled]):active,
.field.has-addons .control .input:not([disabled]).is-active,
.field.has-addons .control .select select:not([disabled]):focus,
.field.has-addons .control .select select:not([disabled]).is-focused,
.field.has-addons .control .select select:not([disabled]):active,
.field.has-addons .control .select select:not([disabled]).is-active {
  z-index: 3;
}
.field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover,
.field.has-addons .control .input:not([disabled]):focus:hover,
.field.has-addons .control .input:not([disabled]).is-focused:hover,
.field.has-addons .control .input:not([disabled]):active:hover,
.field.has-addons .control .input:not([disabled]).is-active:hover,
.field.has-addons .control .select select:not([disabled]):focus:hover,
.field.has-addons .control .select select:not([disabled]).is-focused:hover,
.field.has-addons .control .select select:not([disabled]):active:hover,
.field.has-addons .control .select select:not([disabled]).is-active:hover {
  z-index: 4;
}
.field.has-addons .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}
.field.has-addons.has-addons-centered {
  justify-content: center;
}
.field.has-addons.has-addons-right {
  justify-content: flex-end;
}
.field.has-addons.has-addons-fullwidth .control {
  flex-grow: 1;
  flex-shrink: 0;
}
.field.is-grouped {
  display: flex;
  justify-content: flex-start;
}
.field.is-grouped > .control {
  flex-shrink: 0;
}
.field.is-grouped > .control:not(:last-child) {
  margin-bottom: 0;
  margin-right: 0.75rem;
}
.field.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}
.field.is-grouped.is-grouped-centered {
  justify-content: center;
}
.field.is-grouped.is-grouped-right {
  justify-content: flex-end;
}
.field.is-grouped.is-grouped-multiline {
  flex-wrap: wrap;
}
.field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
  margin-bottom: 0.75rem;
}
.field.is-grouped.is-grouped-multiline:last-child {
  margin-bottom: -0.75rem;
}
.field.is-grouped.is-grouped-multiline:not(:last-child) {
  margin-bottom: 0;
}
@media screen and (min-width: 769px), print {
  .field.is-horizontal {
    display: flex;
  }
}

.field-label .label {
  font-size: inherit;
}
@media screen and (max-width: 768px) {
  .field-label {
    margin-bottom: 0.5rem;
  }
}
@media screen and (min-width: 769px), print {
  .field-label {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 1.5rem;
    text-align: right;
  }
  .field-label.is-small {
    font-size: 0.75rem;
    padding-top: 0.375em;
  }
  .field-label.is-normal {
    padding-top: 0.375em;
  }
  .field-label.is-medium {
    font-size: 1.25rem;
    padding-top: 0.375em;
  }
  .field-label.is-large {
    font-size: 1.5rem;
    padding-top: 0.375em;
  }
}

.field-body .field .field {
  margin-bottom: 0;
}
@media screen and (min-width: 769px), print {
  .field-body {
    display: flex;
    flex-basis: 0;
    flex-grow: 5;
    flex-shrink: 1;
  }
  .field-body .field {
    margin-bottom: 0;
  }
  .field-body > .field {
    flex-shrink: 1;
  }
  .field-body > .field:not(.is-narrow) {
    flex-grow: 1;
  }
  .field-body > .field:not(:last-child) {
    margin-right: 0.75rem;
  }
}

.control {
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: inherit;
}
.control.has-icons-left .input:focus ~ .icon,
.control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon,
.control.has-icons-right .select:focus ~ .icon {
  color: hsl(0, 0%, 29%);
}
.control.has-icons-left .input.is-small ~ .icon,
.control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon,
.control.has-icons-right .select.is-small ~ .icon {
  font-size: 0.75rem;
}
.control.has-icons-left .input.is-medium ~ .icon,
.control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon,
.control.has-icons-right .select.is-medium ~ .icon {
  font-size: 1.25rem;
}
.control.has-icons-left .input.is-large ~ .icon,
.control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon,
.control.has-icons-right .select.is-large ~ .icon {
  font-size: 1.5rem;
}
.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: hsl(0, 0%, 86%);
  height: 2.5em;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 2.5em;
  z-index: 4;
}
.control.has-icons-left .input,
.control.has-icons-left .select select {
  padding-left: 2.5em;
}
.control.has-icons-left .icon.is-left {
  left: 0;
}
.control.has-icons-right .input,
.control.has-icons-right .select select {
  padding-right: 2.5em;
}
.control.has-icons-right .icon.is-right {
  right: 0;
}
.control.is-loading::after {
  position: absolute !important;
  right: 0.625em;
  top: 0.625em;
  z-index: 4;
}
.control.is-loading.is-small:after {
  font-size: 0.75rem;
}
.control.is-loading.is-medium:after {
  font-size: 1.25rem;
}
.control.is-loading.is-large:after {
  font-size: 1.5rem;
}

/* Bulma Layout */
.hero {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hero .navbar {
  background: none;
}
.hero .tabs ul {
  border-bottom: none;
}
.hero.is-white {
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 4%);
}
.hero.is-white a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-white strong {
  color: inherit;
}
.hero.is-white .title {
  color: hsl(0, 0%, 4%);
}
.hero.is-white .subtitle {
  color: rgba(10, 10, 10, 0.9);
}
.hero.is-white .subtitle a:not(.button),
.hero.is-white .subtitle strong {
  color: hsl(0, 0%, 4%);
}
@media screen and (max-width: 1023px) {
  .hero.is-white .navbar-menu {
    background-color: hsl(0, 0%, 100%);
  }
}
.hero.is-white .navbar-item,
.hero.is-white .navbar-link {
  color: rgba(10, 10, 10, 0.7);
}
.hero.is-white a.navbar-item:hover, .hero.is-white a.navbar-item.is-active,
.hero.is-white .navbar-link:hover,
.hero.is-white .navbar-link.is-active {
  background-color: #f2f2f2;
  color: hsl(0, 0%, 4%);
}
.hero.is-white .tabs a {
  color: hsl(0, 0%, 4%);
  opacity: 0.9;
}
.hero.is-white .tabs a:hover {
  opacity: 1;
}
.hero.is-white .tabs li.is-active a {
  color: hsl(0, 0%, 100%) !important;
  opacity: 1;
}
.hero.is-white .tabs.is-boxed a, .hero.is-white .tabs.is-toggle a {
  color: hsl(0, 0%, 4%);
}
.hero.is-white .tabs.is-boxed a:hover, .hero.is-white .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, 0.1);
}
.hero.is-white .tabs.is-boxed li.is-active a, .hero.is-white .tabs.is-boxed li.is-active a:hover, .hero.is-white .tabs.is-toggle li.is-active a, .hero.is-white .tabs.is-toggle li.is-active a:hover {
  background-color: hsl(0, 0%, 4%);
  border-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 100%);
}
.hero.is-white.is-bold {
  background-image: linear-gradient(141deg, #e8e3e4 0%, hsl(0, 0%, 100%) 71%, white 100%);
}
@media screen and (max-width: 768px) {
  .hero.is-white.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e8e3e4 0%, hsl(0, 0%, 100%) 71%, white 100%);
  }
}
.hero.is-black {
  background-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 100%);
}
.hero.is-black a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-black strong {
  color: inherit;
}
.hero.is-black .title {
  color: hsl(0, 0%, 100%);
}
.hero.is-black .subtitle {
  color: rgba(255, 255, 255, 0.9);
}
.hero.is-black .subtitle a:not(.button),
.hero.is-black .subtitle strong {
  color: hsl(0, 0%, 100%);
}
@media screen and (max-width: 1023px) {
  .hero.is-black .navbar-menu {
    background-color: hsl(0, 0%, 4%);
  }
}
.hero.is-black .navbar-item,
.hero.is-black .navbar-link {
  color: rgba(255, 255, 255, 0.7);
}
.hero.is-black a.navbar-item:hover, .hero.is-black a.navbar-item.is-active,
.hero.is-black .navbar-link:hover,
.hero.is-black .navbar-link.is-active {
  background-color: black;
  color: hsl(0, 0%, 100%);
}
.hero.is-black .tabs a {
  color: hsl(0, 0%, 100%);
  opacity: 0.9;
}
.hero.is-black .tabs a:hover {
  opacity: 1;
}
.hero.is-black .tabs li.is-active a {
  color: hsl(0, 0%, 4%) !important;
  opacity: 1;
}
.hero.is-black .tabs.is-boxed a, .hero.is-black .tabs.is-toggle a {
  color: hsl(0, 0%, 100%);
}
.hero.is-black .tabs.is-boxed a:hover, .hero.is-black .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, 0.1);
}
.hero.is-black .tabs.is-boxed li.is-active a, .hero.is-black .tabs.is-boxed li.is-active a:hover, .hero.is-black .tabs.is-toggle li.is-active a, .hero.is-black .tabs.is-toggle li.is-active a:hover {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 4%);
}
.hero.is-black.is-bold {
  background-image: linear-gradient(141deg, black 0%, hsl(0, 0%, 4%) 71%, #181616 100%);
}
@media screen and (max-width: 768px) {
  .hero.is-black.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, black 0%, hsl(0, 0%, 4%) 71%, #181616 100%);
  }
}
.hero.is-light {
  background-color: hsl(0, 0%, 96%);
  color: rgba(0, 0, 0, 0.7);
}
.hero.is-light a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-light strong {
  color: inherit;
}
.hero.is-light .title {
  color: rgba(0, 0, 0, 0.7);
}
.hero.is-light .subtitle {
  color: rgba(0, 0, 0, 0.9);
}
.hero.is-light .subtitle a:not(.button),
.hero.is-light .subtitle strong {
  color: rgba(0, 0, 0, 0.7);
}
@media screen and (max-width: 1023px) {
  .hero.is-light .navbar-menu {
    background-color: hsl(0, 0%, 96%);
  }
}
.hero.is-light .navbar-item,
.hero.is-light .navbar-link {
  color: rgba(0, 0, 0, 0.7);
}
.hero.is-light a.navbar-item:hover, .hero.is-light a.navbar-item.is-active,
.hero.is-light .navbar-link:hover,
.hero.is-light .navbar-link.is-active {
  background-color: #e8e8e8;
  color: rgba(0, 0, 0, 0.7);
}
.hero.is-light .tabs a {
  color: rgba(0, 0, 0, 0.7);
  opacity: 0.9;
}
.hero.is-light .tabs a:hover {
  opacity: 1;
}
.hero.is-light .tabs li.is-active a {
  color: hsl(0, 0%, 96%) !important;
  opacity: 1;
}
.hero.is-light .tabs.is-boxed a, .hero.is-light .tabs.is-toggle a {
  color: rgba(0, 0, 0, 0.7);
}
.hero.is-light .tabs.is-boxed a:hover, .hero.is-light .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, 0.1);
}
.hero.is-light .tabs.is-boxed li.is-active a, .hero.is-light .tabs.is-boxed li.is-active a:hover, .hero.is-light .tabs.is-toggle li.is-active a, .hero.is-light .tabs.is-toggle li.is-active a:hover {
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(0, 0, 0, 0.7);
  color: hsl(0, 0%, 96%);
}
.hero.is-light.is-bold {
  background-image: linear-gradient(141deg, #dfd8d9 0%, hsl(0, 0%, 96%) 71%, white 100%);
}
@media screen and (max-width: 768px) {
  .hero.is-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #dfd8d9 0%, hsl(0, 0%, 96%) 71%, white 100%);
  }
}
.hero.is-dark {
  background-color: hsl(0, 0%, 21%);
  color: #fff;
}
.hero.is-dark a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-dark strong {
  color: inherit;
}
.hero.is-dark .title {
  color: #fff;
}
.hero.is-dark .subtitle {
  color: rgba(255, 255, 255, 0.9);
}
.hero.is-dark .subtitle a:not(.button),
.hero.is-dark .subtitle strong {
  color: #fff;
}
@media screen and (max-width: 1023px) {
  .hero.is-dark .navbar-menu {
    background-color: hsl(0, 0%, 21%);
  }
}
.hero.is-dark .navbar-item,
.hero.is-dark .navbar-link {
  color: rgba(255, 255, 255, 0.7);
}
.hero.is-dark a.navbar-item:hover, .hero.is-dark a.navbar-item.is-active,
.hero.is-dark .navbar-link:hover,
.hero.is-dark .navbar-link.is-active {
  background-color: #292929;
  color: #fff;
}
.hero.is-dark .tabs a {
  color: #fff;
  opacity: 0.9;
}
.hero.is-dark .tabs a:hover {
  opacity: 1;
}
.hero.is-dark .tabs li.is-active a {
  color: hsl(0, 0%, 21%) !important;
  opacity: 1;
}
.hero.is-dark .tabs.is-boxed a, .hero.is-dark .tabs.is-toggle a {
  color: #fff;
}
.hero.is-dark .tabs.is-boxed a:hover, .hero.is-dark .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, 0.1);
}
.hero.is-dark .tabs.is-boxed li.is-active a, .hero.is-dark .tabs.is-boxed li.is-active a:hover, .hero.is-dark .tabs.is-toggle li.is-active a, .hero.is-dark .tabs.is-toggle li.is-active a:hover {
  background-color: #fff;
  border-color: #fff;
  color: hsl(0, 0%, 21%);
}
.hero.is-dark.is-bold {
  background-image: linear-gradient(141deg, #1f191a 0%, hsl(0, 0%, 21%) 71%, #46403f 100%);
}
@media screen and (max-width: 768px) {
  .hero.is-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #1f191a 0%, hsl(0, 0%, 21%) 71%, #46403f 100%);
  }
}
.hero.is-primary {
  background-color: #59c9a5;
  color: #fff;
}
.hero.is-primary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-primary strong {
  color: inherit;
}
.hero.is-primary .title {
  color: #fff;
}
.hero.is-primary .subtitle {
  color: rgba(255, 255, 255, 0.9);
}
.hero.is-primary .subtitle a:not(.button),
.hero.is-primary .subtitle strong {
  color: #fff;
}
@media screen and (max-width: 1023px) {
  .hero.is-primary .navbar-menu {
    background-color: #59c9a5;
  }
}
.hero.is-primary .navbar-item,
.hero.is-primary .navbar-link {
  color: rgba(255, 255, 255, 0.7);
}
.hero.is-primary a.navbar-item:hover, .hero.is-primary a.navbar-item.is-active,
.hero.is-primary .navbar-link:hover,
.hero.is-primary .navbar-link.is-active {
  background-color: #46c39b;
  color: #fff;
}
.hero.is-primary .tabs a {
  color: #fff;
  opacity: 0.9;
}
.hero.is-primary .tabs a:hover {
  opacity: 1;
}
.hero.is-primary .tabs li.is-active a {
  color: #59c9a5 !important;
  opacity: 1;
}
.hero.is-primary .tabs.is-boxed a, .hero.is-primary .tabs.is-toggle a {
  color: #fff;
}
.hero.is-primary .tabs.is-boxed a:hover, .hero.is-primary .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, 0.1);
}
.hero.is-primary .tabs.is-boxed li.is-active a, .hero.is-primary .tabs.is-boxed li.is-active a:hover, .hero.is-primary .tabs.is-toggle li.is-active a, .hero.is-primary .tabs.is-toggle li.is-active a:hover {
  background-color: #fff;
  border-color: #fff;
  color: #59c9a5;
}
.hero.is-primary.is-bold {
  background-image: linear-gradient(141deg, #2fc079 0%, #59c9a5 71%, #67d4c3 100%);
}
@media screen and (max-width: 768px) {
  .hero.is-primary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #2fc079 0%, #59c9a5 71%, #67d4c3 100%);
  }
}
.hero.is-info {
  background-color: hsl(207, 61%, 53%);
  color: #fff;
}
.hero.is-info a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-info strong {
  color: inherit;
}
.hero.is-info .title {
  color: #fff;
}
.hero.is-info .subtitle {
  color: rgba(255, 255, 255, 0.9);
}
.hero.is-info .subtitle a:not(.button),
.hero.is-info .subtitle strong {
  color: #fff;
}
@media screen and (max-width: 1023px) {
  .hero.is-info .navbar-menu {
    background-color: hsl(207, 61%, 53%);
  }
}
.hero.is-info .navbar-item,
.hero.is-info .navbar-link {
  color: rgba(255, 255, 255, 0.7);
}
.hero.is-info a.navbar-item:hover, .hero.is-info a.navbar-item.is-active,
.hero.is-info .navbar-link:hover,
.hero.is-info .navbar-link.is-active {
  background-color: #3082c5;
  color: #fff;
}
.hero.is-info .tabs a {
  color: #fff;
  opacity: 0.9;
}
.hero.is-info .tabs a:hover {
  opacity: 1;
}
.hero.is-info .tabs li.is-active a {
  color: hsl(207, 61%, 53%) !important;
  opacity: 1;
}
.hero.is-info .tabs.is-boxed a, .hero.is-info .tabs.is-toggle a {
  color: #fff;
}
.hero.is-info .tabs.is-boxed a:hover, .hero.is-info .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, 0.1);
}
.hero.is-info .tabs.is-boxed li.is-active a, .hero.is-info .tabs.is-boxed li.is-active a:hover, .hero.is-info .tabs.is-toggle li.is-active a, .hero.is-info .tabs.is-toggle li.is-active a:hover {
  background-color: #fff;
  border-color: #fff;
  color: hsl(207, 61%, 53%);
}
.hero.is-info.is-bold {
  background-image: linear-gradient(141deg, #208fbc 0%, hsl(207, 61%, 53%) 71%, #4d83db 100%);
}
@media screen and (max-width: 768px) {
  .hero.is-info.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #208fbc 0%, hsl(207, 61%, 53%) 71%, #4d83db 100%);
  }
}
.hero.is-success {
  background-color: hsl(153, 53%, 53%);
  color: #fff;
}
.hero.is-success a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-success strong {
  color: inherit;
}
.hero.is-success .title {
  color: #fff;
}
.hero.is-success .subtitle {
  color: rgba(255, 255, 255, 0.9);
}
.hero.is-success .subtitle a:not(.button),
.hero.is-success .subtitle strong {
  color: #fff;
}
@media screen and (max-width: 1023px) {
  .hero.is-success .navbar-menu {
    background-color: hsl(153, 53%, 53%);
  }
}
.hero.is-success .navbar-item,
.hero.is-success .navbar-link {
  color: rgba(255, 255, 255, 0.7);
}
.hero.is-success a.navbar-item:hover, .hero.is-success a.navbar-item.is-active,
.hero.is-success .navbar-link:hover,
.hero.is-success .navbar-link.is-active {
  background-color: #3abb81;
  color: #fff;
}
.hero.is-success .tabs a {
  color: #fff;
  opacity: 0.9;
}
.hero.is-success .tabs a:hover {
  opacity: 1;
}
.hero.is-success .tabs li.is-active a {
  color: hsl(153, 53%, 53%) !important;
  opacity: 1;
}
.hero.is-success .tabs.is-boxed a, .hero.is-success .tabs.is-toggle a {
  color: #fff;
}
.hero.is-success .tabs.is-boxed a:hover, .hero.is-success .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, 0.1);
}
.hero.is-success .tabs.is-boxed li.is-active a, .hero.is-success .tabs.is-boxed li.is-active a:hover, .hero.is-success .tabs.is-toggle li.is-active a, .hero.is-success .tabs.is-toggle li.is-active a:hover {
  background-color: #fff;
  border-color: #fff;
  color: hsl(153, 53%, 53%);
}
.hero.is-success.is-bold {
  background-image: linear-gradient(141deg, #29b35e 0%, hsl(153, 53%, 53%) 71%, #56d2af 100%);
}
@media screen and (max-width: 768px) {
  .hero.is-success.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #29b35e 0%, hsl(153, 53%, 53%) 71%, #56d2af 100%);
  }
}
.hero.is-warning {
  background-color: hsl(44, 100%, 77%);
  color: rgba(0, 0, 0, 0.7);
}
.hero.is-warning a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-warning strong {
  color: inherit;
}
.hero.is-warning .title {
  color: rgba(0, 0, 0, 0.7);
}
.hero.is-warning .subtitle {
  color: rgba(0, 0, 0, 0.9);
}
.hero.is-warning .subtitle a:not(.button),
.hero.is-warning .subtitle strong {
  color: rgba(0, 0, 0, 0.7);
}
@media screen and (max-width: 1023px) {
  .hero.is-warning .navbar-menu {
    background-color: hsl(44, 100%, 77%);
  }
}
.hero.is-warning .navbar-item,
.hero.is-warning .navbar-link {
  color: rgba(0, 0, 0, 0.7);
}
.hero.is-warning a.navbar-item:hover, .hero.is-warning a.navbar-item.is-active,
.hero.is-warning .navbar-link:hover,
.hero.is-warning .navbar-link.is-active {
  background-color: #ffd970;
  color: rgba(0, 0, 0, 0.7);
}
.hero.is-warning .tabs a {
  color: rgba(0, 0, 0, 0.7);
  opacity: 0.9;
}
.hero.is-warning .tabs a:hover {
  opacity: 1;
}
.hero.is-warning .tabs li.is-active a {
  color: hsl(44, 100%, 77%) !important;
  opacity: 1;
}
.hero.is-warning .tabs.is-boxed a, .hero.is-warning .tabs.is-toggle a {
  color: rgba(0, 0, 0, 0.7);
}
.hero.is-warning .tabs.is-boxed a:hover, .hero.is-warning .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, 0.1);
}
.hero.is-warning .tabs.is-boxed li.is-active a, .hero.is-warning .tabs.is-boxed li.is-active a:hover, .hero.is-warning .tabs.is-toggle li.is-active a, .hero.is-warning .tabs.is-toggle li.is-active a:hover {
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(0, 0, 0, 0.7);
  color: hsl(44, 100%, 77%);
}
.hero.is-warning.is-bold {
  background-image: linear-gradient(141deg, #ffb657 0%, hsl(44, 100%, 77%) 71%, #fff6a3 100%);
}
@media screen and (max-width: 768px) {
  .hero.is-warning.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ffb657 0%, hsl(44, 100%, 77%) 71%, #fff6a3 100%);
  }
}
.hero.is-danger {
  background-color: hsl(348, 86%, 61%);
  color: #fff;
}
.hero.is-danger a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-danger strong {
  color: inherit;
}
.hero.is-danger .title {
  color: #fff;
}
.hero.is-danger .subtitle {
  color: rgba(255, 255, 255, 0.9);
}
.hero.is-danger .subtitle a:not(.button),
.hero.is-danger .subtitle strong {
  color: #fff;
}
@media screen and (max-width: 1023px) {
  .hero.is-danger .navbar-menu {
    background-color: hsl(348, 86%, 61%);
  }
}
.hero.is-danger .navbar-item,
.hero.is-danger .navbar-link {
  color: rgba(255, 255, 255, 0.7);
}
.hero.is-danger a.navbar-item:hover, .hero.is-danger a.navbar-item.is-active,
.hero.is-danger .navbar-link:hover,
.hero.is-danger .navbar-link.is-active {
  background-color: #ef2e55;
  color: #fff;
}
.hero.is-danger .tabs a {
  color: #fff;
  opacity: 0.9;
}
.hero.is-danger .tabs a:hover {
  opacity: 1;
}
.hero.is-danger .tabs li.is-active a {
  color: hsl(348, 86%, 61%) !important;
  opacity: 1;
}
.hero.is-danger .tabs.is-boxed a, .hero.is-danger .tabs.is-toggle a {
  color: #fff;
}
.hero.is-danger .tabs.is-boxed a:hover, .hero.is-danger .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, 0.1);
}
.hero.is-danger .tabs.is-boxed li.is-active a, .hero.is-danger .tabs.is-boxed li.is-active a:hover, .hero.is-danger .tabs.is-toggle li.is-active a, .hero.is-danger .tabs.is-toggle li.is-active a:hover {
  background-color: #fff;
  border-color: #fff;
  color: hsl(348, 86%, 61%);
}
.hero.is-danger.is-bold {
  background-image: linear-gradient(141deg, #fa0a62 0%, hsl(348, 86%, 61%) 71%, #f7595f 100%);
}
@media screen and (max-width: 768px) {
  .hero.is-danger.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #fa0a62 0%, hsl(348, 86%, 61%) 71%, #f7595f 100%);
  }
}
.hero.is-twitter {
  background-color: #4099ff;
  color: #fff;
}
.hero.is-twitter a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-twitter strong {
  color: inherit;
}
.hero.is-twitter .title {
  color: #fff;
}
.hero.is-twitter .subtitle {
  color: rgba(255, 255, 255, 0.9);
}
.hero.is-twitter .subtitle a:not(.button),
.hero.is-twitter .subtitle strong {
  color: #fff;
}
@media screen and (max-width: 1023px) {
  .hero.is-twitter .navbar-menu {
    background-color: #4099ff;
  }
}
.hero.is-twitter .navbar-item,
.hero.is-twitter .navbar-link {
  color: rgba(255, 255, 255, 0.7);
}
.hero.is-twitter a.navbar-item:hover, .hero.is-twitter a.navbar-item.is-active,
.hero.is-twitter .navbar-link:hover,
.hero.is-twitter .navbar-link.is-active {
  background-color: #278bff;
  color: #fff;
}
.hero.is-twitter .tabs a {
  color: #fff;
  opacity: 0.9;
}
.hero.is-twitter .tabs a:hover {
  opacity: 1;
}
.hero.is-twitter .tabs li.is-active a {
  color: #4099ff !important;
  opacity: 1;
}
.hero.is-twitter .tabs.is-boxed a, .hero.is-twitter .tabs.is-toggle a {
  color: #fff;
}
.hero.is-twitter .tabs.is-boxed a:hover, .hero.is-twitter .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, 0.1);
}
.hero.is-twitter .tabs.is-boxed li.is-active a, .hero.is-twitter .tabs.is-boxed li.is-active a:hover, .hero.is-twitter .tabs.is-toggle li.is-active a, .hero.is-twitter .tabs.is-toggle li.is-active a:hover {
  background-color: #fff;
  border-color: #fff;
  color: #4099ff;
}
.hero.is-twitter.is-bold {
  background-image: linear-gradient(141deg, #0da6ff 0%, #4099ff 71%, #5a8bff 100%);
}
@media screen and (max-width: 768px) {
  .hero.is-twitter.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #0da6ff 0%, #4099ff 71%, #5a8bff 100%);
  }
}
.hero.is-small .hero-body {
  padding: 1.5rem;
}
@media screen and (min-width: 769px), print {
  .hero.is-medium .hero-body {
    padding: 9rem 4.5rem;
  }
}
@media screen and (min-width: 769px), print {
  .hero.is-large .hero-body {
    padding: 18rem 6rem;
  }
}
.hero.is-halfheight .hero-body, .hero.is-fullheight .hero-body, .hero.is-fullheight-with-navbar .hero-body {
  align-items: center;
  display: flex;
}
.hero.is-halfheight .hero-body > .container, .hero.is-fullheight .hero-body > .container, .hero.is-fullheight-with-navbar .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}
.hero.is-halfheight {
  min-height: 50vh;
}
.hero.is-fullheight {
  min-height: 100vh;
}

.hero-video {
  overflow: hidden;
}
.hero-video video {
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.hero-video.is-transparent {
  opacity: 0.3;
}
@media screen and (max-width: 768px) {
  .hero-video {
    display: none;
  }
}

.hero-buttons {
  margin-top: 1.5rem;
}
@media screen and (max-width: 768px) {
  .hero-buttons .button {
    display: flex;
  }
  .hero-buttons .button:not(:last-child) {
    margin-bottom: 0.75rem;
  }
}
@media screen and (min-width: 769px), print {
  .hero-buttons {
    display: flex;
    justify-content: center;
  }
  .hero-buttons .button:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.hero-head,
.hero-foot {
  flex-grow: 0;
  flex-shrink: 0;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
}
@media screen and (min-width: 769px), print {
  .hero-body {
    padding: 3rem 3rem;
  }
}

.section {
  padding: 3rem 1.5rem;
}
@media screen and (min-width: 1024px) {
  .section {
    padding: 3rem 3rem;
  }
  .section.is-medium {
    padding: 9rem 4.5rem;
  }
  .section.is-large {
    padding: 18rem 6rem;
  }
}

.footer {
  background-color: hsl(0, 0%, 98%);
  padding: 3rem 1.5rem 6rem;
}

/* Bulma Elements */
.box {
  background-color: hsl(0, 0%, 100%);
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: hsl(0, 0%, 29%);
  display: block;
  padding: 1.25rem;
}

a.box:hover, a.box:focus {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px #59c9a5;
}
a.box:active {
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.2), 0 0 0 1px #59c9a5;
}

.button {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 86%);
  border-width: 1px;
  color: hsl(0, 0%, 21%);
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
}
.button strong {
  color: inherit;
}
.button .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
  height: 1.5em;
  width: 1.5em;
}
.button .icon:first-child:not(:last-child) {
  margin-left: calc(-0.5em - 1px);
  margin-right: 0.25em;
}
.button .icon:last-child:not(:first-child) {
  margin-left: 0.25em;
  margin-right: calc(-0.5em - 1px);
}
.button .icon:first-child:last-child {
  margin-left: calc(-0.5em - 1px);
  margin-right: calc(-0.5em - 1px);
}
.button:hover, .button.is-hovered {
  border-color: hsl(0, 0%, 71%);
  color: hsl(0, 0%, 21%);
}
.button:focus, .button.is-focused {
  border-color: #59c9a5;
  color: hsl(0, 0%, 21%);
}
.button:focus:not(:active), .button.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(89, 201, 165, 0.25);
}
.button:active, .button.is-active {
  border-color: hsl(0, 0%, 29%);
  color: hsl(0, 0%, 21%);
}
.button.is-text {
  background-color: transparent;
  border-color: transparent;
  color: hsl(0, 0%, 29%);
  text-decoration: underline;
}
.button.is-text:hover, .button.is-text.is-hovered, .button.is-text:focus, .button.is-text.is-focused {
  background-color: hsl(0, 0%, 96%);
  color: hsl(0, 0%, 21%);
}
.button.is-text:active, .button.is-text.is-active {
  background-color: #e8e8e8;
  color: hsl(0, 0%, 21%);
}
.button.is-text[disabled], fieldset[disabled] .button.is-text {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #59c9a5;
  text-decoration: none;
}
.button.is-ghost:hover, .button.is-ghost.is-hovered {
  color: #59c9a5;
  text-decoration: underline;
}
.button.is-white {
  background-color: hsl(0, 0%, 100%);
  border-color: transparent;
  color: hsl(0, 0%, 4%);
}
.button.is-white:hover, .button.is-white.is-hovered {
  background-color: #f9f9f9;
  border-color: transparent;
  color: hsl(0, 0%, 4%);
}
.button.is-white:focus, .button.is-white.is-focused {
  border-color: transparent;
  color: hsl(0, 0%, 4%);
}
.button.is-white:focus:not(:active), .button.is-white.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25);
}
.button.is-white:active, .button.is-white.is-active {
  background-color: #f2f2f2;
  border-color: transparent;
  color: hsl(0, 0%, 4%);
}
.button.is-white[disabled], fieldset[disabled] .button.is-white {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 100%);
  box-shadow: none;
}
.button.is-white.is-inverted {
  background-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 100%);
}
.button.is-white.is-inverted:hover, .button.is-white.is-inverted.is-hovered {
  background-color: black;
}
.button.is-white.is-inverted[disabled], fieldset[disabled] .button.is-white.is-inverted {
  background-color: hsl(0, 0%, 4%);
  border-color: transparent;
  box-shadow: none;
  color: hsl(0, 0%, 100%);
}
.button.is-white.is-loading::after {
  border-color: transparent transparent hsl(0, 0%, 4%) hsl(0, 0%, 4%) !important;
}
.button.is-white.is-outlined {
  background-color: transparent;
  border-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 100%);
}
.button.is-white.is-outlined:hover, .button.is-white.is-outlined.is-hovered, .button.is-white.is-outlined:focus, .button.is-white.is-outlined.is-focused {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 4%);
}
.button.is-white.is-outlined.is-loading::after {
  border-color: transparent transparent hsl(0, 0%, 100%) hsl(0, 0%, 100%) !important;
}
.button.is-white.is-outlined.is-loading:hover::after, .button.is-white.is-outlined.is-loading.is-hovered::after, .button.is-white.is-outlined.is-loading:focus::after, .button.is-white.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent hsl(0, 0%, 4%) hsl(0, 0%, 4%) !important;
}
.button.is-white.is-outlined[disabled], fieldset[disabled] .button.is-white.is-outlined {
  background-color: transparent;
  border-color: hsl(0, 0%, 100%);
  box-shadow: none;
  color: hsl(0, 0%, 100%);
}
.button.is-white.is-inverted.is-outlined {
  background-color: transparent;
  border-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 4%);
}
.button.is-white.is-inverted.is-outlined:hover, .button.is-white.is-inverted.is-outlined.is-hovered, .button.is-white.is-inverted.is-outlined:focus, .button.is-white.is-inverted.is-outlined.is-focused {
  background-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 100%);
}
.button.is-white.is-inverted.is-outlined.is-loading:hover::after, .button.is-white.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-white.is-inverted.is-outlined.is-loading:focus::after, .button.is-white.is-inverted.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent hsl(0, 0%, 100%) hsl(0, 0%, 100%) !important;
}
.button.is-white.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-white.is-inverted.is-outlined {
  background-color: transparent;
  border-color: hsl(0, 0%, 4%);
  box-shadow: none;
  color: hsl(0, 0%, 4%);
}
.button.is-black {
  background-color: hsl(0, 0%, 4%);
  border-color: transparent;
  color: hsl(0, 0%, 100%);
}
.button.is-black:hover, .button.is-black.is-hovered {
  background-color: #040404;
  border-color: transparent;
  color: hsl(0, 0%, 100%);
}
.button.is-black:focus, .button.is-black.is-focused {
  border-color: transparent;
  color: hsl(0, 0%, 100%);
}
.button.is-black:focus:not(:active), .button.is-black.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25);
}
.button.is-black:active, .button.is-black.is-active {
  background-color: black;
  border-color: transparent;
  color: hsl(0, 0%, 100%);
}
.button.is-black[disabled], fieldset[disabled] .button.is-black {
  background-color: hsl(0, 0%, 4%);
  border-color: hsl(0, 0%, 4%);
  box-shadow: none;
}
.button.is-black.is-inverted {
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 4%);
}
.button.is-black.is-inverted:hover, .button.is-black.is-inverted.is-hovered {
  background-color: #f2f2f2;
}
.button.is-black.is-inverted[disabled], fieldset[disabled] .button.is-black.is-inverted {
  background-color: hsl(0, 0%, 100%);
  border-color: transparent;
  box-shadow: none;
  color: hsl(0, 0%, 4%);
}
.button.is-black.is-loading::after {
  border-color: transparent transparent hsl(0, 0%, 100%) hsl(0, 0%, 100%) !important;
}
.button.is-black.is-outlined {
  background-color: transparent;
  border-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 4%);
}
.button.is-black.is-outlined:hover, .button.is-black.is-outlined.is-hovered, .button.is-black.is-outlined:focus, .button.is-black.is-outlined.is-focused {
  background-color: hsl(0, 0%, 4%);
  border-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 100%);
}
.button.is-black.is-outlined.is-loading::after {
  border-color: transparent transparent hsl(0, 0%, 4%) hsl(0, 0%, 4%) !important;
}
.button.is-black.is-outlined.is-loading:hover::after, .button.is-black.is-outlined.is-loading.is-hovered::after, .button.is-black.is-outlined.is-loading:focus::after, .button.is-black.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent hsl(0, 0%, 100%) hsl(0, 0%, 100%) !important;
}
.button.is-black.is-outlined[disabled], fieldset[disabled] .button.is-black.is-outlined {
  background-color: transparent;
  border-color: hsl(0, 0%, 4%);
  box-shadow: none;
  color: hsl(0, 0%, 4%);
}
.button.is-black.is-inverted.is-outlined {
  background-color: transparent;
  border-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 100%);
}
.button.is-black.is-inverted.is-outlined:hover, .button.is-black.is-inverted.is-outlined.is-hovered, .button.is-black.is-inverted.is-outlined:focus, .button.is-black.is-inverted.is-outlined.is-focused {
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 4%);
}
.button.is-black.is-inverted.is-outlined.is-loading:hover::after, .button.is-black.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-black.is-inverted.is-outlined.is-loading:focus::after, .button.is-black.is-inverted.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent hsl(0, 0%, 4%) hsl(0, 0%, 4%) !important;
}
.button.is-black.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-black.is-inverted.is-outlined {
  background-color: transparent;
  border-color: hsl(0, 0%, 100%);
  box-shadow: none;
  color: hsl(0, 0%, 100%);
}
.button.is-light {
  background-color: hsl(0, 0%, 96%);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.button.is-light:hover, .button.is-light.is-hovered {
  background-color: #eeeeee;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.button.is-light:focus, .button.is-light.is-focused {
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.button.is-light:focus:not(:active), .button.is-light.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25);
}
.button.is-light:active, .button.is-light.is-active {
  background-color: #e8e8e8;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.button.is-light[disabled], fieldset[disabled] .button.is-light {
  background-color: hsl(0, 0%, 96%);
  border-color: hsl(0, 0%, 96%);
  box-shadow: none;
}
.button.is-light.is-inverted {
  background-color: rgba(0, 0, 0, 0.7);
  color: hsl(0, 0%, 96%);
}
.button.is-light.is-inverted:hover, .button.is-light.is-inverted.is-hovered {
  background-color: rgba(0, 0, 0, 0.7);
}
.button.is-light.is-inverted[disabled], fieldset[disabled] .button.is-light.is-inverted {
  background-color: rgba(0, 0, 0, 0.7);
  border-color: transparent;
  box-shadow: none;
  color: hsl(0, 0%, 96%);
}
.button.is-light.is-loading::after {
  border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important;
}
.button.is-light.is-outlined {
  background-color: transparent;
  border-color: hsl(0, 0%, 96%);
  color: hsl(0, 0%, 96%);
}
.button.is-light.is-outlined:hover, .button.is-light.is-outlined.is-hovered, .button.is-light.is-outlined:focus, .button.is-light.is-outlined.is-focused {
  background-color: hsl(0, 0%, 96%);
  border-color: hsl(0, 0%, 96%);
  color: rgba(0, 0, 0, 0.7);
}
.button.is-light.is-outlined.is-loading::after {
  border-color: transparent transparent hsl(0, 0%, 96%) hsl(0, 0%, 96%) !important;
}
.button.is-light.is-outlined.is-loading:hover::after, .button.is-light.is-outlined.is-loading.is-hovered::after, .button.is-light.is-outlined.is-loading:focus::after, .button.is-light.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important;
}
.button.is-light.is-outlined[disabled], fieldset[disabled] .button.is-light.is-outlined {
  background-color: transparent;
  border-color: hsl(0, 0%, 96%);
  box-shadow: none;
  color: hsl(0, 0%, 96%);
}
.button.is-light.is-inverted.is-outlined {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.7);
  color: rgba(0, 0, 0, 0.7);
}
.button.is-light.is-inverted.is-outlined:hover, .button.is-light.is-inverted.is-outlined.is-hovered, .button.is-light.is-inverted.is-outlined:focus, .button.is-light.is-inverted.is-outlined.is-focused {
  background-color: rgba(0, 0, 0, 0.7);
  color: hsl(0, 0%, 96%);
}
.button.is-light.is-inverted.is-outlined.is-loading:hover::after, .button.is-light.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-light.is-inverted.is-outlined.is-loading:focus::after, .button.is-light.is-inverted.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent hsl(0, 0%, 96%) hsl(0, 0%, 96%) !important;
}
.button.is-light.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-light.is-inverted.is-outlined {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.7);
  box-shadow: none;
  color: rgba(0, 0, 0, 0.7);
}
.button.is-dark {
  background-color: hsl(0, 0%, 21%);
  border-color: transparent;
  color: #fff;
}
.button.is-dark:hover, .button.is-dark.is-hovered {
  background-color: #2f2f2f;
  border-color: transparent;
  color: #fff;
}
.button.is-dark:focus, .button.is-dark.is-focused {
  border-color: transparent;
  color: #fff;
}
.button.is-dark:focus:not(:active), .button.is-dark.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(54, 54, 54, 0.25);
}
.button.is-dark:active, .button.is-dark.is-active {
  background-color: #292929;
  border-color: transparent;
  color: #fff;
}
.button.is-dark[disabled], fieldset[disabled] .button.is-dark {
  background-color: hsl(0, 0%, 21%);
  border-color: hsl(0, 0%, 21%);
  box-shadow: none;
}
.button.is-dark.is-inverted {
  background-color: #fff;
  color: hsl(0, 0%, 21%);
}
.button.is-dark.is-inverted:hover, .button.is-dark.is-inverted.is-hovered {
  background-color: #f2f2f2;
}
.button.is-dark.is-inverted[disabled], fieldset[disabled] .button.is-dark.is-inverted {
  background-color: #fff;
  border-color: transparent;
  box-shadow: none;
  color: hsl(0, 0%, 21%);
}
.button.is-dark.is-loading::after {
  border-color: transparent transparent #fff #fff !important;
}
.button.is-dark.is-outlined {
  background-color: transparent;
  border-color: hsl(0, 0%, 21%);
  color: hsl(0, 0%, 21%);
}
.button.is-dark.is-outlined:hover, .button.is-dark.is-outlined.is-hovered, .button.is-dark.is-outlined:focus, .button.is-dark.is-outlined.is-focused {
  background-color: hsl(0, 0%, 21%);
  border-color: hsl(0, 0%, 21%);
  color: #fff;
}
.button.is-dark.is-outlined.is-loading::after {
  border-color: transparent transparent hsl(0, 0%, 21%) hsl(0, 0%, 21%) !important;
}
.button.is-dark.is-outlined.is-loading:hover::after, .button.is-dark.is-outlined.is-loading.is-hovered::after, .button.is-dark.is-outlined.is-loading:focus::after, .button.is-dark.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent #fff #fff !important;
}
.button.is-dark.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-outlined {
  background-color: transparent;
  border-color: hsl(0, 0%, 21%);
  box-shadow: none;
  color: hsl(0, 0%, 21%);
}
.button.is-dark.is-inverted.is-outlined {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.button.is-dark.is-inverted.is-outlined:hover, .button.is-dark.is-inverted.is-outlined.is-hovered, .button.is-dark.is-inverted.is-outlined:focus, .button.is-dark.is-inverted.is-outlined.is-focused {
  background-color: #fff;
  color: hsl(0, 0%, 21%);
}
.button.is-dark.is-inverted.is-outlined.is-loading:hover::after, .button.is-dark.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-dark.is-inverted.is-outlined.is-loading:focus::after, .button.is-dark.is-inverted.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent hsl(0, 0%, 21%) hsl(0, 0%, 21%) !important;
}
.button.is-dark.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-inverted.is-outlined {
  background-color: transparent;
  border-color: #fff;
  box-shadow: none;
  color: #fff;
}
.button.is-primary {
  background-color: #59c9a5;
  border-color: transparent;
  color: #fff;
}
.button.is-primary:hover, .button.is-primary.is-hovered {
  background-color: #4fc6a0;
  border-color: transparent;
  color: #fff;
}
.button.is-primary:focus, .button.is-primary.is-focused {
  border-color: transparent;
  color: #fff;
}
.button.is-primary:focus:not(:active), .button.is-primary.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(89, 201, 165, 0.25);
}
.button.is-primary:active, .button.is-primary.is-active {
  background-color: #46c39b;
  border-color: transparent;
  color: #fff;
}
.button.is-primary[disabled], fieldset[disabled] .button.is-primary {
  background-color: #59c9a5;
  border-color: #59c9a5;
  box-shadow: none;
}
.button.is-primary.is-inverted {
  background-color: #fff;
  color: #59c9a5;
}
.button.is-primary.is-inverted:hover, .button.is-primary.is-inverted.is-hovered {
  background-color: #f2f2f2;
}
.button.is-primary.is-inverted[disabled], fieldset[disabled] .button.is-primary.is-inverted {
  background-color: #fff;
  border-color: transparent;
  box-shadow: none;
  color: #59c9a5;
}
.button.is-primary.is-loading::after {
  border-color: transparent transparent #fff #fff !important;
}
.button.is-primary.is-outlined {
  background-color: transparent;
  border-color: #59c9a5;
  color: #59c9a5;
}
.button.is-primary.is-outlined:hover, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined.is-focused {
  background-color: #59c9a5;
  border-color: #59c9a5;
  color: #fff;
}
.button.is-primary.is-outlined.is-loading::after {
  border-color: transparent transparent #59c9a5 #59c9a5 !important;
}
.button.is-primary.is-outlined.is-loading:hover::after, .button.is-primary.is-outlined.is-loading.is-hovered::after, .button.is-primary.is-outlined.is-loading:focus::after, .button.is-primary.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent #fff #fff !important;
}
.button.is-primary.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-outlined {
  background-color: transparent;
  border-color: #59c9a5;
  box-shadow: none;
  color: #59c9a5;
}
.button.is-primary.is-inverted.is-outlined {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.button.is-primary.is-inverted.is-outlined:hover, .button.is-primary.is-inverted.is-outlined.is-hovered, .button.is-primary.is-inverted.is-outlined:focus, .button.is-primary.is-inverted.is-outlined.is-focused {
  background-color: #fff;
  color: #59c9a5;
}
.button.is-primary.is-inverted.is-outlined.is-loading:hover::after, .button.is-primary.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-primary.is-inverted.is-outlined.is-loading:focus::after, .button.is-primary.is-inverted.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent #59c9a5 #59c9a5 !important;
}
.button.is-primary.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
  background-color: transparent;
  border-color: #fff;
  box-shadow: none;
  color: #fff;
}
.button.is-info {
  background-color: hsl(207, 61%, 53%);
  border-color: transparent;
  color: #fff;
}
.button.is-info:hover, .button.is-info.is-hovered {
  background-color: #3488ce;
  border-color: transparent;
  color: #fff;
}
.button.is-info:focus, .button.is-info.is-focused {
  border-color: transparent;
  color: #fff;
}
.button.is-info:focus:not(:active), .button.is-info.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(62, 142, 208, 0.25);
}
.button.is-info:active, .button.is-info.is-active {
  background-color: #3082c5;
  border-color: transparent;
  color: #fff;
}
.button.is-info[disabled], fieldset[disabled] .button.is-info {
  background-color: hsl(207, 61%, 53%);
  border-color: hsl(207, 61%, 53%);
  box-shadow: none;
}
.button.is-info.is-inverted {
  background-color: #fff;
  color: hsl(207, 61%, 53%);
}
.button.is-info.is-inverted:hover, .button.is-info.is-inverted.is-hovered {
  background-color: #f2f2f2;
}
.button.is-info.is-inverted[disabled], fieldset[disabled] .button.is-info.is-inverted {
  background-color: #fff;
  border-color: transparent;
  box-shadow: none;
  color: hsl(207, 61%, 53%);
}
.button.is-info.is-loading::after {
  border-color: transparent transparent #fff #fff !important;
}
.button.is-info.is-outlined {
  background-color: transparent;
  border-color: hsl(207, 61%, 53%);
  color: hsl(207, 61%, 53%);
}
.button.is-info.is-outlined:hover, .button.is-info.is-outlined.is-hovered, .button.is-info.is-outlined:focus, .button.is-info.is-outlined.is-focused {
  background-color: hsl(207, 61%, 53%);
  border-color: hsl(207, 61%, 53%);
  color: #fff;
}
.button.is-info.is-outlined.is-loading::after {
  border-color: transparent transparent hsl(207, 61%, 53%) hsl(207, 61%, 53%) !important;
}
.button.is-info.is-outlined.is-loading:hover::after, .button.is-info.is-outlined.is-loading.is-hovered::after, .button.is-info.is-outlined.is-loading:focus::after, .button.is-info.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent #fff #fff !important;
}
.button.is-info.is-outlined[disabled], fieldset[disabled] .button.is-info.is-outlined {
  background-color: transparent;
  border-color: hsl(207, 61%, 53%);
  box-shadow: none;
  color: hsl(207, 61%, 53%);
}
.button.is-info.is-inverted.is-outlined {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.button.is-info.is-inverted.is-outlined:hover, .button.is-info.is-inverted.is-outlined.is-hovered, .button.is-info.is-inverted.is-outlined:focus, .button.is-info.is-inverted.is-outlined.is-focused {
  background-color: #fff;
  color: hsl(207, 61%, 53%);
}
.button.is-info.is-inverted.is-outlined.is-loading:hover::after, .button.is-info.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-info.is-inverted.is-outlined.is-loading:focus::after, .button.is-info.is-inverted.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent hsl(207, 61%, 53%) hsl(207, 61%, 53%) !important;
}
.button.is-info.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-info.is-inverted.is-outlined {
  background-color: transparent;
  border-color: #fff;
  box-shadow: none;
  color: #fff;
}
.button.is-success {
  background-color: hsl(153, 53%, 53%);
  border-color: transparent;
  color: #fff;
}
.button.is-success:hover, .button.is-success.is-hovered {
  background-color: #3ec487;
  border-color: transparent;
  color: #fff;
}
.button.is-success:focus, .button.is-success.is-focused {
  border-color: transparent;
  color: #fff;
}
.button.is-success:focus:not(:active), .button.is-success.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(72, 199, 142, 0.25);
}
.button.is-success:active, .button.is-success.is-active {
  background-color: #3abb81;
  border-color: transparent;
  color: #fff;
}
.button.is-success[disabled], fieldset[disabled] .button.is-success {
  background-color: hsl(153, 53%, 53%);
  border-color: hsl(153, 53%, 53%);
  box-shadow: none;
}
.button.is-success.is-inverted {
  background-color: #fff;
  color: hsl(153, 53%, 53%);
}
.button.is-success.is-inverted:hover, .button.is-success.is-inverted.is-hovered {
  background-color: #f2f2f2;
}
.button.is-success.is-inverted[disabled], fieldset[disabled] .button.is-success.is-inverted {
  background-color: #fff;
  border-color: transparent;
  box-shadow: none;
  color: hsl(153, 53%, 53%);
}
.button.is-success.is-loading::after {
  border-color: transparent transparent #fff #fff !important;
}
.button.is-success.is-outlined {
  background-color: transparent;
  border-color: hsl(153, 53%, 53%);
  color: hsl(153, 53%, 53%);
}
.button.is-success.is-outlined:hover, .button.is-success.is-outlined.is-hovered, .button.is-success.is-outlined:focus, .button.is-success.is-outlined.is-focused {
  background-color: hsl(153, 53%, 53%);
  border-color: hsl(153, 53%, 53%);
  color: #fff;
}
.button.is-success.is-outlined.is-loading::after {
  border-color: transparent transparent hsl(153, 53%, 53%) hsl(153, 53%, 53%) !important;
}
.button.is-success.is-outlined.is-loading:hover::after, .button.is-success.is-outlined.is-loading.is-hovered::after, .button.is-success.is-outlined.is-loading:focus::after, .button.is-success.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent #fff #fff !important;
}
.button.is-success.is-outlined[disabled], fieldset[disabled] .button.is-success.is-outlined {
  background-color: transparent;
  border-color: hsl(153, 53%, 53%);
  box-shadow: none;
  color: hsl(153, 53%, 53%);
}
.button.is-success.is-inverted.is-outlined {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.button.is-success.is-inverted.is-outlined:hover, .button.is-success.is-inverted.is-outlined.is-hovered, .button.is-success.is-inverted.is-outlined:focus, .button.is-success.is-inverted.is-outlined.is-focused {
  background-color: #fff;
  color: hsl(153, 53%, 53%);
}
.button.is-success.is-inverted.is-outlined.is-loading:hover::after, .button.is-success.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-success.is-inverted.is-outlined.is-loading:focus::after, .button.is-success.is-inverted.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent hsl(153, 53%, 53%) hsl(153, 53%, 53%) !important;
}
.button.is-success.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-success.is-inverted.is-outlined {
  background-color: transparent;
  border-color: #fff;
  box-shadow: none;
  color: #fff;
}
.button.is-warning {
  background-color: hsl(44, 100%, 77%);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.button.is-warning:hover, .button.is-warning.is-hovered {
  background-color: #ffdc7d;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.button.is-warning:focus, .button.is-warning.is-focused {
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.button.is-warning:focus:not(:active), .button.is-warning.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(255, 224, 138, 0.25);
}
.button.is-warning:active, .button.is-warning.is-active {
  background-color: #ffd970;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.button.is-warning[disabled], fieldset[disabled] .button.is-warning {
  background-color: hsl(44, 100%, 77%);
  border-color: hsl(44, 100%, 77%);
  box-shadow: none;
}
.button.is-warning.is-inverted {
  background-color: rgba(0, 0, 0, 0.7);
  color: hsl(44, 100%, 77%);
}
.button.is-warning.is-inverted:hover, .button.is-warning.is-inverted.is-hovered {
  background-color: rgba(0, 0, 0, 0.7);
}
.button.is-warning.is-inverted[disabled], fieldset[disabled] .button.is-warning.is-inverted {
  background-color: rgba(0, 0, 0, 0.7);
  border-color: transparent;
  box-shadow: none;
  color: hsl(44, 100%, 77%);
}
.button.is-warning.is-loading::after {
  border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important;
}
.button.is-warning.is-outlined {
  background-color: transparent;
  border-color: hsl(44, 100%, 77%);
  color: hsl(44, 100%, 77%);
}
.button.is-warning.is-outlined:hover, .button.is-warning.is-outlined.is-hovered, .button.is-warning.is-outlined:focus, .button.is-warning.is-outlined.is-focused {
  background-color: hsl(44, 100%, 77%);
  border-color: hsl(44, 100%, 77%);
  color: rgba(0, 0, 0, 0.7);
}
.button.is-warning.is-outlined.is-loading::after {
  border-color: transparent transparent hsl(44, 100%, 77%) hsl(44, 100%, 77%) !important;
}
.button.is-warning.is-outlined.is-loading:hover::after, .button.is-warning.is-outlined.is-loading.is-hovered::after, .button.is-warning.is-outlined.is-loading:focus::after, .button.is-warning.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important;
}
.button.is-warning.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-outlined {
  background-color: transparent;
  border-color: hsl(44, 100%, 77%);
  box-shadow: none;
  color: hsl(44, 100%, 77%);
}
.button.is-warning.is-inverted.is-outlined {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.7);
  color: rgba(0, 0, 0, 0.7);
}
.button.is-warning.is-inverted.is-outlined:hover, .button.is-warning.is-inverted.is-outlined.is-hovered, .button.is-warning.is-inverted.is-outlined:focus, .button.is-warning.is-inverted.is-outlined.is-focused {
  background-color: rgba(0, 0, 0, 0.7);
  color: hsl(44, 100%, 77%);
}
.button.is-warning.is-inverted.is-outlined.is-loading:hover::after, .button.is-warning.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-warning.is-inverted.is-outlined.is-loading:focus::after, .button.is-warning.is-inverted.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent hsl(44, 100%, 77%) hsl(44, 100%, 77%) !important;
}
.button.is-warning.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-inverted.is-outlined {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.7);
  box-shadow: none;
  color: rgba(0, 0, 0, 0.7);
}
.button.is-danger {
  background-color: hsl(348, 86%, 61%);
  border-color: transparent;
  color: #fff;
}
.button.is-danger:hover, .button.is-danger.is-hovered {
  background-color: #f03a5f;
  border-color: transparent;
  color: #fff;
}
.button.is-danger:focus, .button.is-danger.is-focused {
  border-color: transparent;
  color: #fff;
}
.button.is-danger:focus:not(:active), .button.is-danger.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25);
}
.button.is-danger:active, .button.is-danger.is-active {
  background-color: #ef2e55;
  border-color: transparent;
  color: #fff;
}
.button.is-danger[disabled], fieldset[disabled] .button.is-danger {
  background-color: hsl(348, 86%, 61%);
  border-color: hsl(348, 86%, 61%);
  box-shadow: none;
}
.button.is-danger.is-inverted {
  background-color: #fff;
  color: hsl(348, 86%, 61%);
}
.button.is-danger.is-inverted:hover, .button.is-danger.is-inverted.is-hovered {
  background-color: #f2f2f2;
}
.button.is-danger.is-inverted[disabled], fieldset[disabled] .button.is-danger.is-inverted {
  background-color: #fff;
  border-color: transparent;
  box-shadow: none;
  color: hsl(348, 86%, 61%);
}
.button.is-danger.is-loading::after {
  border-color: transparent transparent #fff #fff !important;
}
.button.is-danger.is-outlined {
  background-color: transparent;
  border-color: hsl(348, 86%, 61%);
  color: hsl(348, 86%, 61%);
}
.button.is-danger.is-outlined:hover, .button.is-danger.is-outlined.is-hovered, .button.is-danger.is-outlined:focus, .button.is-danger.is-outlined.is-focused {
  background-color: hsl(348, 86%, 61%);
  border-color: hsl(348, 86%, 61%);
  color: #fff;
}
.button.is-danger.is-outlined.is-loading::after {
  border-color: transparent transparent hsl(348, 86%, 61%) hsl(348, 86%, 61%) !important;
}
.button.is-danger.is-outlined.is-loading:hover::after, .button.is-danger.is-outlined.is-loading.is-hovered::after, .button.is-danger.is-outlined.is-loading:focus::after, .button.is-danger.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent #fff #fff !important;
}
.button.is-danger.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-outlined {
  background-color: transparent;
  border-color: hsl(348, 86%, 61%);
  box-shadow: none;
  color: hsl(348, 86%, 61%);
}
.button.is-danger.is-inverted.is-outlined {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.button.is-danger.is-inverted.is-outlined:hover, .button.is-danger.is-inverted.is-outlined.is-hovered, .button.is-danger.is-inverted.is-outlined:focus, .button.is-danger.is-inverted.is-outlined.is-focused {
  background-color: #fff;
  color: hsl(348, 86%, 61%);
}
.button.is-danger.is-inverted.is-outlined.is-loading:hover::after, .button.is-danger.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-danger.is-inverted.is-outlined.is-loading:focus::after, .button.is-danger.is-inverted.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent hsl(348, 86%, 61%) hsl(348, 86%, 61%) !important;
}
.button.is-danger.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-inverted.is-outlined {
  background-color: transparent;
  border-color: #fff;
  box-shadow: none;
  color: #fff;
}
.button.is-twitter {
  background-color: #4099ff;
  border-color: transparent;
  color: #fff;
}
.button.is-twitter:hover, .button.is-twitter.is-hovered {
  background-color: #3392ff;
  border-color: transparent;
  color: #fff;
}
.button.is-twitter:focus, .button.is-twitter.is-focused {
  border-color: transparent;
  color: #fff;
}
.button.is-twitter:focus:not(:active), .button.is-twitter.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(64, 153, 255, 0.25);
}
.button.is-twitter:active, .button.is-twitter.is-active {
  background-color: #278bff;
  border-color: transparent;
  color: #fff;
}
.button.is-twitter[disabled], fieldset[disabled] .button.is-twitter {
  background-color: #4099ff;
  border-color: #4099ff;
  box-shadow: none;
}
.button.is-twitter.is-inverted {
  background-color: #fff;
  color: #4099ff;
}
.button.is-twitter.is-inverted:hover, .button.is-twitter.is-inverted.is-hovered {
  background-color: #f2f2f2;
}
.button.is-twitter.is-inverted[disabled], fieldset[disabled] .button.is-twitter.is-inverted {
  background-color: #fff;
  border-color: transparent;
  box-shadow: none;
  color: #4099ff;
}
.button.is-twitter.is-loading::after {
  border-color: transparent transparent #fff #fff !important;
}
.button.is-twitter.is-outlined {
  background-color: transparent;
  border-color: #4099ff;
  color: #4099ff;
}
.button.is-twitter.is-outlined:hover, .button.is-twitter.is-outlined.is-hovered, .button.is-twitter.is-outlined:focus, .button.is-twitter.is-outlined.is-focused {
  background-color: #4099ff;
  border-color: #4099ff;
  color: #fff;
}
.button.is-twitter.is-outlined.is-loading::after {
  border-color: transparent transparent #4099ff #4099ff !important;
}
.button.is-twitter.is-outlined.is-loading:hover::after, .button.is-twitter.is-outlined.is-loading.is-hovered::after, .button.is-twitter.is-outlined.is-loading:focus::after, .button.is-twitter.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent #fff #fff !important;
}
.button.is-twitter.is-outlined[disabled], fieldset[disabled] .button.is-twitter.is-outlined {
  background-color: transparent;
  border-color: #4099ff;
  box-shadow: none;
  color: #4099ff;
}
.button.is-twitter.is-inverted.is-outlined {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.button.is-twitter.is-inverted.is-outlined:hover, .button.is-twitter.is-inverted.is-outlined.is-hovered, .button.is-twitter.is-inverted.is-outlined:focus, .button.is-twitter.is-inverted.is-outlined.is-focused {
  background-color: #fff;
  color: #4099ff;
}
.button.is-twitter.is-inverted.is-outlined.is-loading:hover::after, .button.is-twitter.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-twitter.is-inverted.is-outlined.is-loading:focus::after, .button.is-twitter.is-inverted.is-outlined.is-loading.is-focused::after {
  border-color: transparent transparent #4099ff #4099ff !important;
}
.button.is-twitter.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-twitter.is-inverted.is-outlined {
  background-color: transparent;
  border-color: #fff;
  box-shadow: none;
  color: #fff;
}
.button.is-small {
  font-size: 0.75rem;
}
.button.is-small:not(.is-rounded) {
  border-radius: 2px;
}
.button.is-normal {
  font-size: 1rem;
}
.button.is-medium {
  font-size: 1.25rem;
}
.button.is-large {
  font-size: 1.5rem;
}
.button[disabled], fieldset[disabled] .button {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 86%);
  box-shadow: none;
  opacity: 0.5;
}
.button.is-fullwidth {
  display: flex;
  width: 100%;
}
.button.is-loading {
  color: transparent !important;
  pointer-events: none;
}
.button.is-loading::after {
  position: absolute;
  left: calc(50% - 1em * 0.5);
  top: calc(50% - 1em * 0.5);
  position: absolute !important;
}
.button.is-static {
  background-color: hsl(0, 0%, 96%);
  border-color: hsl(0, 0%, 86%);
  color: hsl(0, 0%, 48%);
  box-shadow: none;
  pointer-events: none;
}
.button.is-rounded {
  border-radius: 9999px;
  padding-left: calc(1em + 0.25em);
  padding-right: calc(1em + 0.25em);
}

.buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.buttons .button {
  margin-bottom: 0.5rem;
}
.buttons .button:not(:last-child):not(.is-fullwidth) {
  margin-right: 0.5rem;
}
.buttons:last-child {
  margin-bottom: -0.5rem;
}
.buttons:not(:last-child) {
  margin-bottom: 1rem;
}
.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large) {
  font-size: 0.75rem;
}
.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large):not(.is-rounded) {
  border-radius: 2px;
}
.buttons.are-medium .button:not(.is-small):not(.is-normal):not(.is-large) {
  font-size: 1.25rem;
}
.buttons.are-large .button:not(.is-small):not(.is-normal):not(.is-medium) {
  font-size: 1.5rem;
}
.buttons.has-addons .button:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.buttons.has-addons .button:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: -1px;
}
.buttons.has-addons .button:last-child {
  margin-right: 0;
}
.buttons.has-addons .button:hover, .buttons.has-addons .button.is-hovered {
  z-index: 2;
}
.buttons.has-addons .button:focus, .buttons.has-addons .button.is-focused, .buttons.has-addons .button:active, .buttons.has-addons .button.is-active, .buttons.has-addons .button.is-selected {
  z-index: 3;
}
.buttons.has-addons .button:focus:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .button.is-active:hover, .buttons.has-addons .button.is-selected:hover {
  z-index: 4;
}
.buttons.has-addons .button.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}
.buttons.is-centered {
  justify-content: center;
}
.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.buttons.is-right {
  justify-content: flex-end;
}
.buttons.is-right:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

@media screen and (max-width: 768px) {
  .button.is-responsive.is-small {
    font-size: 0.5625rem;
  }
  .button.is-responsive,
  .button.is-responsive.is-normal {
    font-size: 0.65625rem;
  }
  .button.is-responsive.is-medium {
    font-size: 0.75rem;
  }
  .button.is-responsive.is-large {
    font-size: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .button.is-responsive.is-small {
    font-size: 0.65625rem;
  }
  .button.is-responsive,
  .button.is-responsive.is-normal {
    font-size: 0.75rem;
  }
  .button.is-responsive.is-medium {
    font-size: 1rem;
  }
  .button.is-responsive.is-large {
    font-size: 1.25rem;
  }
}
.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
}
.container.is-fluid {
  max-width: none !important;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 1215px) {
  .container.is-widescreen:not(.is-max-desktop) {
    max-width: 1152px;
  }
}
@media screen and (max-width: 1407px) {
  .container.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}
@media screen and (min-width: 1216px) {
  .container:not(.is-max-desktop) {
    max-width: 1152px;
  }
}
@media screen and (min-width: 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

.content li + li {
  margin-top: 0.25em;
}
.content p:not(:last-child),
.content dl:not(:last-child),
.content ol:not(:last-child),
.content ul:not(:last-child),
.content blockquote:not(:last-child),
.content pre:not(:last-child),
.content table:not(:last-child) {
  margin-bottom: 1em;
}
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  color: hsl(0, 0%, 21%);
  font-weight: 600;
  line-height: 1.125;
}
.content h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}
.content h1:not(:first-child) {
  margin-top: 1em;
}
.content h2 {
  font-size: 1.75em;
  margin-bottom: 0.5714em;
}
.content h2:not(:first-child) {
  margin-top: 1.1428em;
}
.content h3 {
  font-size: 1.5em;
  margin-bottom: 0.6666em;
}
.content h3:not(:first-child) {
  margin-top: 1.3333em;
}
.content h4 {
  font-size: 1.25em;
  margin-bottom: 0.8em;
}
.content h5 {
  font-size: 1.125em;
  margin-bottom: 0.8888em;
}
.content h6 {
  font-size: 1em;
  margin-bottom: 1em;
}
.content blockquote {
  background-color: hsl(0, 0%, 96%);
  border-left: 5px solid hsl(0, 0%, 86%);
  padding: 1.25em 1.5em;
}
.content ol {
  list-style-position: outside;
  margin-left: 2em;
  margin-top: 1em;
}
.content ol:not([type]) {
  list-style-type: decimal;
}
.content ol:not([type]).is-lower-alpha {
  list-style-type: lower-alpha;
}
.content ol:not([type]).is-lower-roman {
  list-style-type: lower-roman;
}
.content ol:not([type]).is-upper-alpha {
  list-style-type: upper-alpha;
}
.content ol:not([type]).is-upper-roman {
  list-style-type: upper-roman;
}
.content ul {
  list-style: disc outside;
  margin-left: 2em;
  margin-top: 1em;
}
.content ul ul {
  list-style-type: circle;
  margin-top: 0.5em;
}
.content ul ul ul {
  list-style-type: square;
}
.content dd {
  margin-left: 2em;
}
.content figure {
  margin-left: 2em;
  margin-right: 2em;
  text-align: center;
}
.content figure:not(:first-child) {
  margin-top: 2em;
}
.content figure:not(:last-child) {
  margin-bottom: 2em;
}
.content figure img {
  display: inline-block;
}
.content figure figcaption {
  font-style: italic;
}
.content pre {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  padding: 1.25em 1.5em;
  white-space: pre;
  word-wrap: normal;
}
.content sup,
.content sub {
  font-size: 75%;
}
.content table {
  width: 100%;
}
.content table td,
.content table th {
  border: 1px solid hsl(0, 0%, 86%);
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}
.content table th {
  color: hsl(0, 0%, 21%);
}
.content table th:not([align]) {
  text-align: inherit;
}
.content table thead td,
.content table thead th {
  border-width: 0 0 2px;
  color: hsl(0, 0%, 21%);
}
.content table tfoot td,
.content table tfoot th {
  border-width: 2px 0 0;
  color: hsl(0, 0%, 21%);
}
.content table tbody tr:last-child td,
.content table tbody tr:last-child th {
  border-bottom-width: 0;
}
.content .tabs li + li {
  margin-top: 0;
}
.content.is-small {
  font-size: 0.75rem;
}
.content.is-normal {
  font-size: 1rem;
}
.content.is-medium {
  font-size: 1.25rem;
}
.content.is-large {
  font-size: 1.5rem;
}

.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
}
.icon.is-small {
  height: 1rem;
  width: 1rem;
}
.icon.is-medium {
  height: 2rem;
  width: 2rem;
}
.icon.is-large {
  height: 3rem;
  width: 3rem;
}

.icon-text {
  align-items: flex-start;
  color: inherit;
  display: inline-flex;
  flex-wrap: wrap;
  line-height: 1.5rem;
  vertical-align: top;
}
.icon-text .icon {
  flex-grow: 0;
  flex-shrink: 0;
}
.icon-text .icon:not(:last-child) {
  margin-right: 0.25em;
}
.icon-text .icon:not(:first-child) {
  margin-left: 0.25em;
}

div.icon-text {
  display: flex;
}

.image {
  display: block;
  position: relative;
}
.image img {
  display: block;
  height: auto;
  width: 100%;
}
.image img.is-rounded {
  border-radius: 9999px;
}
.image.is-fullwidth {
  width: 100%;
}
.image.is-square img,
.image.is-square .has-ratio, .image.is-1by1 img,
.image.is-1by1 .has-ratio, .image.is-5by4 img,
.image.is-5by4 .has-ratio, .image.is-4by3 img,
.image.is-4by3 .has-ratio, .image.is-3by2 img,
.image.is-3by2 .has-ratio, .image.is-5by3 img,
.image.is-5by3 .has-ratio, .image.is-16by9 img,
.image.is-16by9 .has-ratio, .image.is-2by1 img,
.image.is-2by1 .has-ratio, .image.is-3by1 img,
.image.is-3by1 .has-ratio, .image.is-4by5 img,
.image.is-4by5 .has-ratio, .image.is-3by4 img,
.image.is-3by4 .has-ratio, .image.is-2by3 img,
.image.is-2by3 .has-ratio, .image.is-3by5 img,
.image.is-3by5 .has-ratio, .image.is-9by16 img,
.image.is-9by16 .has-ratio, .image.is-1by2 img,
.image.is-1by2 .has-ratio, .image.is-1by3 img,
.image.is-1by3 .has-ratio {
  height: 100%;
  width: 100%;
}
.image.is-square, .image.is-1by1 {
  padding-top: 100%;
}
.image.is-5by4 {
  padding-top: 80%;
}
.image.is-4by3 {
  padding-top: 75%;
}
.image.is-3by2 {
  padding-top: 66.6666%;
}
.image.is-5by3 {
  padding-top: 60%;
}
.image.is-16by9 {
  padding-top: 56.25%;
}
.image.is-2by1 {
  padding-top: 50%;
}
.image.is-3by1 {
  padding-top: 33.3333%;
}
.image.is-4by5 {
  padding-top: 125%;
}
.image.is-3by4 {
  padding-top: 133.3333%;
}
.image.is-2by3 {
  padding-top: 150%;
}
.image.is-3by5 {
  padding-top: 166.6666%;
}
.image.is-9by16 {
  padding-top: 177.7777%;
}
.image.is-1by2 {
  padding-top: 200%;
}
.image.is-1by3 {
  padding-top: 300%;
}
.image.is-16x16 {
  height: 16px;
  width: 16px;
}
.image.is-24x24 {
  height: 24px;
  width: 24px;
}
.image.is-32x32 {
  height: 32px;
  width: 32px;
}
.image.is-48x48 {
  height: 48px;
  width: 48px;
}
.image.is-64x64 {
  height: 64px;
  width: 64px;
}
.image.is-96x96 {
  height: 96px;
  width: 96px;
}
.image.is-128x128 {
  height: 128px;
  width: 128px;
}

.notification {
  background-color: hsl(0, 0%, 96%);
  border-radius: 4px;
  position: relative;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
}
.notification a:not(.button):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}
.notification strong {
  color: currentColor;
}
.notification code,
.notification pre {
  background: hsl(0, 0%, 100%);
}
.notification pre code {
  background: transparent;
}
.notification > .delete {
  right: 0.5rem;
  position: absolute;
  top: 0.5rem;
}
.notification .title,
.notification .subtitle,
.notification .content {
  color: currentColor;
}
.notification.is-white {
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 4%);
}
.notification.is-black {
  background-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 100%);
}
.notification.is-light {
  background-color: hsl(0, 0%, 96%);
  color: rgba(0, 0, 0, 0.7);
}
.notification.is-dark {
  background-color: hsl(0, 0%, 21%);
  color: #fff;
}
.notification.is-primary {
  background-color: #59c9a5;
  color: #fff;
}
.notification.is-info {
  background-color: hsl(207, 61%, 53%);
  color: #fff;
}
.notification.is-success {
  background-color: hsl(153, 53%, 53%);
  color: #fff;
}
.notification.is-warning {
  background-color: hsl(44, 100%, 77%);
  color: rgba(0, 0, 0, 0.7);
}
.notification.is-danger {
  background-color: hsl(348, 86%, 61%);
  color: #fff;
}
.notification.is-twitter {
  background-color: #4099ff;
  color: #fff;
}

.progress {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 9999px;
  display: block;
  height: 1rem;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.progress::-webkit-progress-bar {
  background-color: hsl(0, 0%, 93%);
}
.progress::-webkit-progress-value {
  background-color: hsl(0, 0%, 29%);
}
.progress::-moz-progress-bar {
  background-color: hsl(0, 0%, 29%);
}
.progress::-ms-fill {
  background-color: hsl(0, 0%, 29%);
  border: none;
}
.progress.is-white::-webkit-progress-value {
  background-color: hsl(0, 0%, 100%);
}
.progress.is-white::-moz-progress-bar {
  background-color: hsl(0, 0%, 100%);
}
.progress.is-white::-ms-fill {
  background-color: hsl(0, 0%, 100%);
}
.progress.is-white:indeterminate {
  background-image: linear-gradient(to right, hsl(0, 0%, 100%) 30%, hsl(0, 0%, 93%) 30%);
}
.progress.is-black::-webkit-progress-value {
  background-color: hsl(0, 0%, 4%);
}
.progress.is-black::-moz-progress-bar {
  background-color: hsl(0, 0%, 4%);
}
.progress.is-black::-ms-fill {
  background-color: hsl(0, 0%, 4%);
}
.progress.is-black:indeterminate {
  background-image: linear-gradient(to right, hsl(0, 0%, 4%) 30%, hsl(0, 0%, 93%) 30%);
}
.progress.is-light::-webkit-progress-value {
  background-color: hsl(0, 0%, 96%);
}
.progress.is-light::-moz-progress-bar {
  background-color: hsl(0, 0%, 96%);
}
.progress.is-light::-ms-fill {
  background-color: hsl(0, 0%, 96%);
}
.progress.is-light:indeterminate {
  background-image: linear-gradient(to right, hsl(0, 0%, 96%) 30%, hsl(0, 0%, 93%) 30%);
}
.progress.is-dark::-webkit-progress-value {
  background-color: hsl(0, 0%, 21%);
}
.progress.is-dark::-moz-progress-bar {
  background-color: hsl(0, 0%, 21%);
}
.progress.is-dark::-ms-fill {
  background-color: hsl(0, 0%, 21%);
}
.progress.is-dark:indeterminate {
  background-image: linear-gradient(to right, hsl(0, 0%, 21%) 30%, hsl(0, 0%, 93%) 30%);
}
.progress.is-primary::-webkit-progress-value {
  background-color: #59c9a5;
}
.progress.is-primary::-moz-progress-bar {
  background-color: #59c9a5;
}
.progress.is-primary::-ms-fill {
  background-color: #59c9a5;
}
.progress.is-primary:indeterminate {
  background-image: linear-gradient(to right, #59c9a5 30%, hsl(0, 0%, 93%) 30%);
}
.progress.is-info::-webkit-progress-value {
  background-color: hsl(207, 61%, 53%);
}
.progress.is-info::-moz-progress-bar {
  background-color: hsl(207, 61%, 53%);
}
.progress.is-info::-ms-fill {
  background-color: hsl(207, 61%, 53%);
}
.progress.is-info:indeterminate {
  background-image: linear-gradient(to right, hsl(207, 61%, 53%) 30%, hsl(0, 0%, 93%) 30%);
}
.progress.is-success::-webkit-progress-value {
  background-color: hsl(153, 53%, 53%);
}
.progress.is-success::-moz-progress-bar {
  background-color: hsl(153, 53%, 53%);
}
.progress.is-success::-ms-fill {
  background-color: hsl(153, 53%, 53%);
}
.progress.is-success:indeterminate {
  background-image: linear-gradient(to right, hsl(153, 53%, 53%) 30%, hsl(0, 0%, 93%) 30%);
}
.progress.is-warning::-webkit-progress-value {
  background-color: hsl(44, 100%, 77%);
}
.progress.is-warning::-moz-progress-bar {
  background-color: hsl(44, 100%, 77%);
}
.progress.is-warning::-ms-fill {
  background-color: hsl(44, 100%, 77%);
}
.progress.is-warning:indeterminate {
  background-image: linear-gradient(to right, hsl(44, 100%, 77%) 30%, hsl(0, 0%, 93%) 30%);
}
.progress.is-danger::-webkit-progress-value {
  background-color: hsl(348, 86%, 61%);
}
.progress.is-danger::-moz-progress-bar {
  background-color: hsl(348, 86%, 61%);
}
.progress.is-danger::-ms-fill {
  background-color: hsl(348, 86%, 61%);
}
.progress.is-danger:indeterminate {
  background-image: linear-gradient(to right, hsl(348, 86%, 61%) 30%, hsl(0, 0%, 93%) 30%);
}
.progress.is-twitter::-webkit-progress-value {
  background-color: #4099ff;
}
.progress.is-twitter::-moz-progress-bar {
  background-color: #4099ff;
}
.progress.is-twitter::-ms-fill {
  background-color: #4099ff;
}
.progress.is-twitter:indeterminate {
  background-image: linear-gradient(to right, #4099ff 30%, hsl(0, 0%, 93%) 30%);
}
.progress:indeterminate {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: moveIndeterminate;
  animation-timing-function: linear;
  background-color: hsl(0, 0%, 93%);
  background-image: linear-gradient(to right, hsl(0, 0%, 29%) 30%, hsl(0, 0%, 93%) 30%);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 150% 150%;
}
.progress:indeterminate::-webkit-progress-bar {
  background-color: transparent;
}
.progress:indeterminate::-moz-progress-bar {
  background-color: transparent;
}
.progress:indeterminate::-ms-fill {
  animation-name: none;
}
.progress.is-small {
  height: 0.75rem;
}
.progress.is-medium {
  height: 1.25rem;
}
.progress.is-large {
  height: 1.5rem;
}

@keyframes moveIndeterminate {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}
.table {
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 21%);
}
.table td,
.table th {
  border: 1px solid hsl(0, 0%, 86%);
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}
.table td.is-white,
.table th.is-white {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 4%);
}
.table td.is-black,
.table th.is-black {
  background-color: hsl(0, 0%, 4%);
  border-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 100%);
}
.table td.is-light,
.table th.is-light {
  background-color: hsl(0, 0%, 96%);
  border-color: hsl(0, 0%, 96%);
  color: rgba(0, 0, 0, 0.7);
}
.table td.is-dark,
.table th.is-dark {
  background-color: hsl(0, 0%, 21%);
  border-color: hsl(0, 0%, 21%);
  color: #fff;
}
.table td.is-primary,
.table th.is-primary {
  background-color: #59c9a5;
  border-color: #59c9a5;
  color: #fff;
}
.table td.is-info,
.table th.is-info {
  background-color: hsl(207, 61%, 53%);
  border-color: hsl(207, 61%, 53%);
  color: #fff;
}
.table td.is-success,
.table th.is-success {
  background-color: hsl(153, 53%, 53%);
  border-color: hsl(153, 53%, 53%);
  color: #fff;
}
.table td.is-warning,
.table th.is-warning {
  background-color: hsl(44, 100%, 77%);
  border-color: hsl(44, 100%, 77%);
  color: rgba(0, 0, 0, 0.7);
}
.table td.is-danger,
.table th.is-danger {
  background-color: hsl(348, 86%, 61%);
  border-color: hsl(348, 86%, 61%);
  color: #fff;
}
.table td.is-twitter,
.table th.is-twitter {
  background-color: #4099ff;
  border-color: #4099ff;
  color: #fff;
}
.table td.is-narrow,
.table th.is-narrow {
  white-space: nowrap;
  width: 1%;
}
.table td.is-selected,
.table th.is-selected {
  background-color: #59c9a5;
  color: #fff;
}
.table td.is-selected a,
.table td.is-selected strong,
.table th.is-selected a,
.table th.is-selected strong {
  color: currentColor;
}
.table td.is-vcentered,
.table th.is-vcentered {
  vertical-align: middle;
}
.table th {
  color: hsl(0, 0%, 21%);
}
.table th:not([align]) {
  text-align: left;
}
.table tr.is-selected {
  background-color: #59c9a5;
  color: #fff;
}
.table tr.is-selected a,
.table tr.is-selected strong {
  color: currentColor;
}
.table tr.is-selected td,
.table tr.is-selected th {
  border-color: #fff;
  color: currentColor;
}
.table thead {
  background-color: transparent;
}
.table thead td,
.table thead th {
  border-width: 0 0 2px;
  color: hsl(0, 0%, 21%);
}
.table tfoot {
  background-color: transparent;
}
.table tfoot td,
.table tfoot th {
  border-width: 2px 0 0;
  color: hsl(0, 0%, 21%);
}
.table tbody {
  background-color: transparent;
}
.table tbody tr:last-child td,
.table tbody tr:last-child th {
  border-bottom-width: 0;
}
.table.is-bordered td,
.table.is-bordered th {
  border-width: 1px;
}
.table.is-bordered tr:last-child td,
.table.is-bordered tr:last-child th {
  border-bottom-width: 1px;
}
.table.is-fullwidth {
  width: 100%;
}
.table.is-hoverable tbody tr:not(.is-selected):hover {
  background-color: hsl(0, 0%, 98%);
}
.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: hsl(0, 0%, 98%);
}
.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(even) {
  background-color: hsl(0, 0%, 96%);
}
.table.is-narrow td,
.table.is-narrow th {
  padding: 0.25em 0.5em;
}
.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background-color: hsl(0, 0%, 98%);
}

.table-container {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  overflow-y: hidden;
  max-width: 100%;
}

.tags {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.tags .tag {
  margin-bottom: 0.5rem;
}
.tags .tag:not(:last-child) {
  margin-right: 0.5rem;
}
.tags:last-child {
  margin-bottom: -0.5rem;
}
.tags:not(:last-child) {
  margin-bottom: 1rem;
}
.tags.are-medium .tag:not(.is-normal):not(.is-large) {
  font-size: 1rem;
}
.tags.are-large .tag:not(.is-normal):not(.is-medium) {
  font-size: 1.25rem;
}
.tags.is-centered {
  justify-content: center;
}
.tags.is-centered .tag {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}
.tags.is-right {
  justify-content: flex-end;
}
.tags.is-right .tag:not(:first-child) {
  margin-left: 0.5rem;
}
.tags.is-right .tag:not(:last-child) {
  margin-right: 0;
}
.tags.has-addons .tag {
  margin-right: 0;
}
.tags.has-addons .tag:not(:first-child) {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.tags.has-addons .tag:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tag:not(body) {
  align-items: center;
  background-color: hsl(0, 0%, 96%);
  border-radius: 4px;
  color: hsl(0, 0%, 29%);
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
}
.tag:not(body) .delete {
  margin-left: 0.25rem;
  margin-right: -0.375rem;
}
.tag:not(body).is-white {
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 4%);
}
.tag:not(body).is-black {
  background-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 100%);
}
.tag:not(body).is-light {
  background-color: hsl(0, 0%, 96%);
  color: rgba(0, 0, 0, 0.7);
}
.tag:not(body).is-dark {
  background-color: hsl(0, 0%, 21%);
  color: #fff;
}
.tag:not(body).is-primary {
  background-color: #59c9a5;
  color: #fff;
}
.tag:not(body).is-info {
  background-color: hsl(207, 61%, 53%);
  color: #fff;
}
.tag:not(body).is-success {
  background-color: hsl(153, 53%, 53%);
  color: #fff;
}
.tag:not(body).is-warning {
  background-color: hsl(44, 100%, 77%);
  color: rgba(0, 0, 0, 0.7);
}
.tag:not(body).is-danger {
  background-color: hsl(348, 86%, 61%);
  color: #fff;
}
.tag:not(body).is-twitter {
  background-color: #4099ff;
  color: #fff;
}
.tag:not(body).is-normal {
  font-size: 0.75rem;
}
.tag:not(body).is-medium {
  font-size: 1rem;
}
.tag:not(body).is-large {
  font-size: 1.25rem;
}
.tag:not(body) .icon:first-child:not(:last-child) {
  margin-left: -0.375em;
  margin-right: 0.1875em;
}
.tag:not(body) .icon:last-child:not(:first-child) {
  margin-left: 0.1875em;
  margin-right: -0.375em;
}
.tag:not(body) .icon:first-child:last-child {
  margin-left: -0.375em;
  margin-right: -0.375em;
}
.tag:not(body).is-delete {
  margin-left: 1px;
  padding: 0;
  position: relative;
  width: 2em;
}
.tag:not(body).is-delete::before, .tag:not(body).is-delete::after {
  background-color: currentColor;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}
.tag:not(body).is-delete::before {
  height: 1px;
  width: 50%;
}
.tag:not(body).is-delete::after {
  height: 50%;
  width: 1px;
}
.tag:not(body).is-delete:hover, .tag:not(body).is-delete:focus {
  background-color: #e8e8e8;
}
.tag:not(body).is-delete:active {
  background-color: #dbdbdb;
}
.tag:not(body).is-rounded {
  border-radius: 9999px;
}

a.tag:hover {
  text-decoration: underline;
}

.title,
.subtitle {
  word-break: break-word;
}
.title em,
.title span,
.subtitle em,
.subtitle span {
  font-weight: inherit;
}
.title sub,
.subtitle sub {
  font-size: 0.75em;
}
.title sup,
.subtitle sup {
  font-size: 0.75em;
}
.title .tag,
.subtitle .tag {
  vertical-align: middle;
}

.title {
  color: hsl(0, 0%, 21%);
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}
.title strong {
  color: inherit;
  font-weight: inherit;
}
.title:not(.is-spaced) + .subtitle {
  margin-top: -1.25rem;
}
.title.is-1 {
  font-size: 3rem;
}
.title.is-2 {
  font-size: 2.5rem;
}
.title.is-3 {
  font-size: 2rem;
}
.title.is-4 {
  font-size: 1.5rem;
}
.title.is-5 {
  font-size: 1.25rem;
}
.title.is-6 {
  font-size: 1rem;
}
.title.is-7 {
  font-size: 0.75rem;
}

.subtitle {
  color: hsl(0, 0%, 29%);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}
.subtitle strong {
  color: hsl(0, 0%, 21%);
  font-weight: 600;
}
.subtitle:not(.is-spaced) + .title {
  margin-top: -1.25rem;
}
.subtitle.is-1 {
  font-size: 3rem;
}
.subtitle.is-2 {
  font-size: 2.5rem;
}
.subtitle.is-3 {
  font-size: 2rem;
}
.subtitle.is-4 {
  font-size: 1.5rem;
}
.subtitle.is-5 {
  font-size: 1.25rem;
}
.subtitle.is-6 {
  font-size: 1rem;
}
.subtitle.is-7 {
  font-size: 0.75rem;
}

.heading {
  display: block;
  font-size: 11px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.number {
  align-items: center;
  background-color: hsl(0, 0%, 96%);
  border-radius: 9999px;
  display: inline-flex;
  font-size: 1.25rem;
  height: 2em;
  justify-content: center;
  margin-right: 1.5rem;
  min-width: 2.5em;
  padding: 0.25rem 0.5rem;
  text-align: center;
  vertical-align: top;
}

.switch {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-right: 0.5em;
}
.switch + .switch:last-child {
  margin-right: 0;
}
.switch input[type=checkbox] {
  position: absolute;
  left: 0;
  opacity: 0;
  outline: none;
  z-index: -1;
}
.switch input[type=checkbox] + .check {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 2.75em;
  height: 1.575em;
  padding: 0.2em;
  background: hsl(0, 0%, 71%);
  border-radius: 4px;
  transition: background 150ms ease-out, box-shadow 150ms ease-out;
}
.switch input[type=checkbox] + .check.is-white-passive, .switch input[type=checkbox] + .check:hover {
  background: hsl(0, 0%, 100%);
}
.switch input[type=checkbox] + .check.input[type=checkbox] + .switch input[type=checkbox] + .check.check {
  background: "pink";
}
.switch input[type=checkbox] + .check.is-black-passive, .switch input[type=checkbox] + .check:hover {
  background: hsl(0, 0%, 4%);
}
.switch input[type=checkbox] + .check.input[type=checkbox] + .switch input[type=checkbox] + .check.check {
  background: "pink";
}
.switch input[type=checkbox] + .check.is-light-passive, .switch input[type=checkbox] + .check:hover {
  background: hsl(0, 0%, 96%);
}
.switch input[type=checkbox] + .check.input[type=checkbox] + .switch input[type=checkbox] + .check.check {
  background: "pink";
}
.switch input[type=checkbox] + .check.is-dark-passive, .switch input[type=checkbox] + .check:hover {
  background: hsl(0, 0%, 21%);
}
.switch input[type=checkbox] + .check.input[type=checkbox] + .switch input[type=checkbox] + .check.check {
  background: "pink";
}
.switch input[type=checkbox] + .check.is-primary-passive, .switch input[type=checkbox] + .check:hover {
  background: #59c9a5;
}
.switch input[type=checkbox] + .check.input[type=checkbox] + .switch input[type=checkbox] + .check.check {
  background: "pink";
}
.switch input[type=checkbox] + .check.is-info-passive, .switch input[type=checkbox] + .check:hover {
  background: hsl(207, 61%, 53%);
}
.switch input[type=checkbox] + .check.input[type=checkbox] + .switch input[type=checkbox] + .check.check {
  background: "pink";
}
.switch input[type=checkbox] + .check.is-success-passive, .switch input[type=checkbox] + .check:hover {
  background: hsl(153, 53%, 53%);
}
.switch input[type=checkbox] + .check.input[type=checkbox] + .switch input[type=checkbox] + .check.check {
  background: "pink";
}
.switch input[type=checkbox] + .check.is-warning-passive, .switch input[type=checkbox] + .check:hover {
  background: hsl(44, 100%, 77%);
}
.switch input[type=checkbox] + .check.input[type=checkbox] + .switch input[type=checkbox] + .check.check {
  background: "pink";
}
.switch input[type=checkbox] + .check.is-danger-passive, .switch input[type=checkbox] + .check:hover {
  background: hsl(348, 86%, 61%);
}
.switch input[type=checkbox] + .check.input[type=checkbox] + .switch input[type=checkbox] + .check.check {
  background: "pink";
}
.switch input[type=checkbox] + .check.is-twitter-passive, .switch input[type=checkbox] + .check:hover {
  background: #4099ff;
}
.switch input[type=checkbox] + .check.input[type=checkbox] + .switch input[type=checkbox] + .check.check {
  background: "pink";
}
.switch input[type=checkbox] + .check:before {
  content: "";
  display: block;
  border-radius: 4px;
  width: 1.175em;
  height: 1.175em;
  background: hsl(0, 0%, 96%);
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.05);
  transition: transform 150ms ease-out;
  will-change: transform;
  transform-origin: left;
}
.switch input[type=checkbox] + .check.is-elastic:before {
  transform: scaleX(1.5);
  border-radius: 4px;
}
.switch input[type=checkbox]:checked + .check {
  background: #59c9a5;
}
.switch input[type=checkbox]:checked + .check.is-white {
  background: hsl(0, 0%, 100%);
}
.switch input[type=checkbox]:checked + .check.is-black {
  background: hsl(0, 0%, 4%);
}
.switch input[type=checkbox]:checked + .check.is-light {
  background: hsl(0, 0%, 96%);
}
.switch input[type=checkbox]:checked + .check.is-dark {
  background: hsl(0, 0%, 21%);
}
.switch input[type=checkbox]:checked + .check.is-primary {
  background: #59c9a5;
}
.switch input[type=checkbox]:checked + .check.is-info {
  background: hsl(207, 61%, 53%);
}
.switch input[type=checkbox]:checked + .check.is-success {
  background: hsl(153, 53%, 53%);
}
.switch input[type=checkbox]:checked + .check.is-warning {
  background: hsl(44, 100%, 77%);
}
.switch input[type=checkbox]:checked + .check.is-danger {
  background: hsl(348, 86%, 61%);
}
.switch input[type=checkbox]:checked + .check.is-twitter {
  background: #4099ff;
}
.switch input[type=checkbox]:checked + .check:before {
  transform: translate3d(100%, 0, 0);
}
.switch input[type=checkbox]:checked + .check.is-elastic:before {
  transform: translate3d(50%, 0, 0) scaleX(1.5);
}
.switch input[type=checkbox]:focus, .switch input[type=checkbox]:active {
  outline: none;
}
.switch input[type=checkbox]:focus + .check, .switch input[type=checkbox]:active + .check {
  box-shadow: 0 0 0.5em rgba(122, 122, 122, 0.6);
}
.switch input[type=checkbox]:focus + .check.is-white-passive, .switch input[type=checkbox]:active + .check.is-white-passive {
  box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.8);
}
.switch input[type=checkbox]:focus + .check.is-black-passive, .switch input[type=checkbox]:active + .check.is-black-passive {
  box-shadow: 0 0 0.5em rgba(10, 10, 10, 0.8);
}
.switch input[type=checkbox]:focus + .check.is-light-passive, .switch input[type=checkbox]:active + .check.is-light-passive {
  box-shadow: 0 0 0.5em rgba(245, 245, 245, 0.8);
}
.switch input[type=checkbox]:focus + .check.is-dark-passive, .switch input[type=checkbox]:active + .check.is-dark-passive {
  box-shadow: 0 0 0.5em rgba(54, 54, 54, 0.8);
}
.switch input[type=checkbox]:focus + .check.is-primary-passive, .switch input[type=checkbox]:active + .check.is-primary-passive {
  box-shadow: 0 0 0.5em rgba(89, 201, 165, 0.8);
}
.switch input[type=checkbox]:focus + .check.is-info-passive, .switch input[type=checkbox]:active + .check.is-info-passive {
  box-shadow: 0 0 0.5em rgba(62, 142, 208, 0.8);
}
.switch input[type=checkbox]:focus + .check.is-success-passive, .switch input[type=checkbox]:active + .check.is-success-passive {
  box-shadow: 0 0 0.5em rgba(72, 199, 142, 0.8);
}
.switch input[type=checkbox]:focus + .check.is-warning-passive, .switch input[type=checkbox]:active + .check.is-warning-passive {
  box-shadow: 0 0 0.5em rgba(255, 224, 138, 0.8);
}
.switch input[type=checkbox]:focus + .check.is-danger-passive, .switch input[type=checkbox]:active + .check.is-danger-passive {
  box-shadow: 0 0 0.5em rgba(241, 70, 104, 0.8);
}
.switch input[type=checkbox]:focus + .check.is-twitter-passive, .switch input[type=checkbox]:active + .check.is-twitter-passive {
  box-shadow: 0 0 0.5em rgba(64, 153, 255, 0.8);
}
.switch input[type=checkbox]:focus:checked + .check, .switch input[type=checkbox]:active:checked + .check {
  box-shadow: 0 0 0.5em rgba(89, 201, 165, 0.8);
}
.switch input[type=checkbox]:focus:checked + .check.is-white, .switch input[type=checkbox]:active:checked + .check.is-white {
  box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.8);
}
.switch input[type=checkbox]:focus:checked + .check.is-black, .switch input[type=checkbox]:active:checked + .check.is-black {
  box-shadow: 0 0 0.5em rgba(10, 10, 10, 0.8);
}
.switch input[type=checkbox]:focus:checked + .check.is-light, .switch input[type=checkbox]:active:checked + .check.is-light {
  box-shadow: 0 0 0.5em rgba(245, 245, 245, 0.8);
}
.switch input[type=checkbox]:focus:checked + .check.is-dark, .switch input[type=checkbox]:active:checked + .check.is-dark {
  box-shadow: 0 0 0.5em rgba(54, 54, 54, 0.8);
}
.switch input[type=checkbox]:focus:checked + .check.is-primary, .switch input[type=checkbox]:active:checked + .check.is-primary {
  box-shadow: 0 0 0.5em rgba(89, 201, 165, 0.8);
}
.switch input[type=checkbox]:focus:checked + .check.is-info, .switch input[type=checkbox]:active:checked + .check.is-info {
  box-shadow: 0 0 0.5em rgba(62, 142, 208, 0.8);
}
.switch input[type=checkbox]:focus:checked + .check.is-success, .switch input[type=checkbox]:active:checked + .check.is-success {
  box-shadow: 0 0 0.5em rgba(72, 199, 142, 0.8);
}
.switch input[type=checkbox]:focus:checked + .check.is-warning, .switch input[type=checkbox]:active:checked + .check.is-warning {
  box-shadow: 0 0 0.5em rgba(255, 224, 138, 0.8);
}
.switch input[type=checkbox]:focus:checked + .check.is-danger, .switch input[type=checkbox]:active:checked + .check.is-danger {
  box-shadow: 0 0 0.5em rgba(241, 70, 104, 0.8);
}
.switch input[type=checkbox]:focus:checked + .check.is-twitter, .switch input[type=checkbox]:active:checked + .check.is-twitter {
  box-shadow: 0 0 0.5em rgba(64, 153, 255, 0.8);
}
.switch .control-label {
  padding-left: 0.5em;
}
.switch:hover input[type=checkbox] + .check {
  background: rgba(181, 181, 181, 0.9);
}
.switch:hover input[type=checkbox] + .check.is-white-passive {
  background: rgba(255, 255, 255, 0.9);
}
.switch:hover input[type=checkbox] + .check.is-black-passive {
  background: rgba(10, 10, 10, 0.9);
}
.switch:hover input[type=checkbox] + .check.is-light-passive {
  background: rgba(245, 245, 245, 0.9);
}
.switch:hover input[type=checkbox] + .check.is-dark-passive {
  background: rgba(54, 54, 54, 0.9);
}
.switch:hover input[type=checkbox] + .check.is-primary-passive {
  background: rgba(89, 201, 165, 0.9);
}
.switch:hover input[type=checkbox] + .check.is-info-passive {
  background: rgba(62, 142, 208, 0.9);
}
.switch:hover input[type=checkbox] + .check.is-success-passive {
  background: rgba(72, 199, 142, 0.9);
}
.switch:hover input[type=checkbox] + .check.is-warning-passive {
  background: rgba(255, 224, 138, 0.9);
}
.switch:hover input[type=checkbox] + .check.is-danger-passive {
  background: rgba(241, 70, 104, 0.9);
}
.switch:hover input[type=checkbox] + .check.is-twitter-passive {
  background: rgba(64, 153, 255, 0.9);
}
.switch:hover input[type=checkbox]:checked + .check {
  background: rgba(89, 201, 165, 0.9);
}
.switch:hover input[type=checkbox]:checked + .check.is-white {
  background: rgba(255, 255, 255, 0.9);
}
.switch:hover input[type=checkbox]:checked + .check.is-black {
  background: rgba(10, 10, 10, 0.9);
}
.switch:hover input[type=checkbox]:checked + .check.is-light {
  background: rgba(245, 245, 245, 0.9);
}
.switch:hover input[type=checkbox]:checked + .check.is-dark {
  background: rgba(54, 54, 54, 0.9);
}
.switch:hover input[type=checkbox]:checked + .check.is-primary {
  background: rgba(89, 201, 165, 0.9);
}
.switch:hover input[type=checkbox]:checked + .check.is-info {
  background: rgba(62, 142, 208, 0.9);
}
.switch:hover input[type=checkbox]:checked + .check.is-success {
  background: rgba(72, 199, 142, 0.9);
}
.switch:hover input[type=checkbox]:checked + .check.is-warning {
  background: rgba(255, 224, 138, 0.9);
}
.switch:hover input[type=checkbox]:checked + .check.is-danger {
  background: rgba(241, 70, 104, 0.9);
}
.switch:hover input[type=checkbox]:checked + .check.is-twitter {
  background: rgba(64, 153, 255, 0.9);
}
.switch.is-rounded input[type=checkbox] + .check {
  border-radius: 9999px;
}
.switch.is-rounded input[type=checkbox] + .check:before {
  border-radius: 9999px;
}
.switch.is-rounded input[type=checkbox].is-elastic:before {
  transform: scaleX(1.5);
  border-radius: 9999px;
}
.switch.is-outlined input[type=checkbox] + .check {
  background: transparent;
  border: 0.1rem solid hsl(0, 0%, 71%);
}
.switch.is-outlined input[type=checkbox] + .check.is-white-passive {
  border: 0.1rem solid rgba(255, 255, 255, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-white-passive:before {
  background: hsl(0, 0%, 100%);
}
.switch.is-outlined input[type=checkbox] + .check.is-white-passive:hover {
  border-color: rgba(255, 255, 255, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-black-passive {
  border: 0.1rem solid rgba(10, 10, 10, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-black-passive:before {
  background: hsl(0, 0%, 4%);
}
.switch.is-outlined input[type=checkbox] + .check.is-black-passive:hover {
  border-color: rgba(10, 10, 10, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-light-passive {
  border: 0.1rem solid rgba(245, 245, 245, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-light-passive:before {
  background: hsl(0, 0%, 96%);
}
.switch.is-outlined input[type=checkbox] + .check.is-light-passive:hover {
  border-color: rgba(245, 245, 245, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-dark-passive {
  border: 0.1rem solid rgba(54, 54, 54, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-dark-passive:before {
  background: hsl(0, 0%, 21%);
}
.switch.is-outlined input[type=checkbox] + .check.is-dark-passive:hover {
  border-color: rgba(54, 54, 54, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-primary-passive {
  border: 0.1rem solid rgba(89, 201, 165, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-primary-passive:before {
  background: #59c9a5;
}
.switch.is-outlined input[type=checkbox] + .check.is-primary-passive:hover {
  border-color: rgba(89, 201, 165, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-info-passive {
  border: 0.1rem solid rgba(62, 142, 208, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-info-passive:before {
  background: hsl(207, 61%, 53%);
}
.switch.is-outlined input[type=checkbox] + .check.is-info-passive:hover {
  border-color: rgba(62, 142, 208, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-success-passive {
  border: 0.1rem solid rgba(72, 199, 142, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-success-passive:before {
  background: hsl(153, 53%, 53%);
}
.switch.is-outlined input[type=checkbox] + .check.is-success-passive:hover {
  border-color: rgba(72, 199, 142, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-warning-passive {
  border: 0.1rem solid rgba(255, 224, 138, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-warning-passive:before {
  background: hsl(44, 100%, 77%);
}
.switch.is-outlined input[type=checkbox] + .check.is-warning-passive:hover {
  border-color: rgba(255, 224, 138, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-danger-passive {
  border: 0.1rem solid rgba(241, 70, 104, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-danger-passive:before {
  background: hsl(348, 86%, 61%);
}
.switch.is-outlined input[type=checkbox] + .check.is-danger-passive:hover {
  border-color: rgba(241, 70, 104, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-twitter-passive {
  border: 0.1rem solid rgba(64, 153, 255, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check.is-twitter-passive:before {
  background: #4099ff;
}
.switch.is-outlined input[type=checkbox] + .check.is-twitter-passive:hover {
  border-color: rgba(64, 153, 255, 0.9);
}
.switch.is-outlined input[type=checkbox] + .check:before {
  background: hsl(0, 0%, 71%);
}
.switch.is-outlined input[type=checkbox]:checked + .check {
  border-color: #59c9a5;
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-white {
  background: transparent;
  border-color: hsl(0, 0%, 100%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-white:before {
  background: hsl(0, 0%, 100%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-black {
  background: transparent;
  border-color: hsl(0, 0%, 4%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-black:before {
  background: hsl(0, 0%, 4%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-light {
  background: transparent;
  border-color: hsl(0, 0%, 96%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-light:before {
  background: hsl(0, 0%, 96%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-dark {
  background: transparent;
  border-color: hsl(0, 0%, 21%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-dark:before {
  background: hsl(0, 0%, 21%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-primary {
  background: transparent;
  border-color: #59c9a5;
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-primary:before {
  background: #59c9a5;
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-info {
  background: transparent;
  border-color: hsl(207, 61%, 53%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-info:before {
  background: hsl(207, 61%, 53%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-success {
  background: transparent;
  border-color: hsl(153, 53%, 53%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-success:before {
  background: hsl(153, 53%, 53%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-warning {
  background: transparent;
  border-color: hsl(44, 100%, 77%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-warning:before {
  background: hsl(44, 100%, 77%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-danger {
  background: transparent;
  border-color: hsl(348, 86%, 61%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-danger:before {
  background: hsl(348, 86%, 61%);
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-twitter {
  background: transparent;
  border-color: #4099ff;
}
.switch.is-outlined input[type=checkbox]:checked + .check.is-twitter:before {
  background: #4099ff;
}
.switch.is-outlined input[type=checkbox]:checked + .check:before {
  background: #59c9a5;
}
.switch.is-outlined:hover input[type=checkbox] + .check {
  background: transparent;
  border-color: rgba(181, 181, 181, 0.9);
}
.switch.is-outlined:hover input[type=checkbox]:checked + .check {
  background: transparent;
  border-color: rgba(89, 201, 165, 0.9);
}
.switch.is-outlined:hover input[type=checkbox]:checked + .check.is-white {
  border-color: rgba(255, 255, 255, 0.9);
}
.switch.is-outlined:hover input[type=checkbox]:checked + .check.is-black {
  border-color: rgba(10, 10, 10, 0.9);
}
.switch.is-outlined:hover input[type=checkbox]:checked + .check.is-light {
  border-color: rgba(245, 245, 245, 0.9);
}
.switch.is-outlined:hover input[type=checkbox]:checked + .check.is-dark {
  border-color: rgba(54, 54, 54, 0.9);
}
.switch.is-outlined:hover input[type=checkbox]:checked + .check.is-primary {
  border-color: rgba(89, 201, 165, 0.9);
}
.switch.is-outlined:hover input[type=checkbox]:checked + .check.is-info {
  border-color: rgba(62, 142, 208, 0.9);
}
.switch.is-outlined:hover input[type=checkbox]:checked + .check.is-success {
  border-color: rgba(72, 199, 142, 0.9);
}
.switch.is-outlined:hover input[type=checkbox]:checked + .check.is-warning {
  border-color: rgba(255, 224, 138, 0.9);
}
.switch.is-outlined:hover input[type=checkbox]:checked + .check.is-danger {
  border-color: rgba(241, 70, 104, 0.9);
}
.switch.is-outlined:hover input[type=checkbox]:checked + .check.is-twitter {
  border-color: rgba(64, 153, 255, 0.9);
}
.switch.is-small {
  border-radius: 2px;
  font-size: 0.75rem;
}
.switch.is-medium {
  font-size: 1.25rem;
}
.switch.is-large {
  font-size: 1.5rem;
}
.switch[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
  color: hsl(0, 0%, 48%);
}

.loading-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 999;
}
.loading-overlay.is-active {
  display: flex;
}
.loading-overlay.is-full-page {
  position: fixed;
}
.loading-overlay.is-full-page .loading-icon:after {
  top: calc(50% - 2.5em);
  left: calc(50% - 2.5em);
  width: 5em;
  height: 5em;
}
.loading-overlay .loading-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #7f7f7f;
  background: rgba(255, 255, 255, 0.5);
}
.loading-overlay .loading-icon {
  position: relative;
}
.loading-overlay .loading-icon:after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid hsl(0, 0%, 86%);
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
  position: absolute;
  top: calc(50% - 1.5em);
  left: calc(50% - 1.5em);
  width: 3em;
  height: 3em;
  border-width: 0.25em;
}

.datepicker {
  font-size: 0.875rem;
}
.datepicker .dropdown,
.datepicker .dropdown-trigger {
  width: 100%;
}
.datepicker .dropdown .input[readonly],
.datepicker .dropdown-trigger .input[readonly] {
  cursor: pointer;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
}
.datepicker .dropdown .input[readonly]:focus, .datepicker .dropdown .input[readonly].is-focused, .datepicker .dropdown .input[readonly]:active, .datepicker .dropdown .input[readonly].is-active,
.datepicker .dropdown-trigger .input[readonly]:focus,
.datepicker .dropdown-trigger .input[readonly].is-focused,
.datepicker .dropdown-trigger .input[readonly]:active,
.datepicker .dropdown-trigger .input[readonly].is-active {
  box-shadow: 0 0 0 0.125em rgba(89, 201, 165, 0.25);
}
.datepicker .dropdown.is-disabled {
  opacity: 1;
}
.datepicker .dropdown-content {
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
.datepicker .dropdown-item, .datepicker .dropdown .dropdown-menu .has-link a, .dropdown .dropdown-menu .has-link .datepicker a {
  font-size: inherit;
}
.datepicker .datepicker-header {
  padding-bottom: 0.875rem;
  margin-bottom: 0.875rem;
  border-bottom: 1px solid hsl(0, 0%, 86%);
}
.datepicker .datepicker-footer {
  margin-top: 0.875rem;
  padding-top: 0.875rem;
  border-top: 1px solid hsl(0, 0%, 86%);
}
.datepicker .datepicker-table {
  display: table;
  margin: 0 auto 0 auto;
}
.datepicker .datepicker-table .datepicker-cell {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
}
.datepicker .datepicker-table .datepicker-header {
  display: table-header-group;
}
.datepicker .datepicker-table .datepicker-header .datepicker-cell {
  color: hsl(0, 0%, 48%);
  font-weight: 600;
}
.datepicker .datepicker-table .datepicker-body {
  display: table-row-group;
}
.datepicker .datepicker-table .datepicker-body .datepicker-row {
  display: table-row;
}
.datepicker .datepicker-table .datepicker-body .datepicker-months {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 17rem;
}
.datepicker .datepicker-table .datepicker-body .datepicker-months .datepicker-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  height: 2.5rem;
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-unselectable {
  color: hsl(0, 0%, 71%);
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
  border: solid 1px rgba(89, 201, 165, 0.5);
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable {
  color: hsl(0, 0%, 29%);
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable:hover:not(.is-selected), .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable:focus:not(.is-selected) {
  background-color: hsl(0, 0%, 96%);
  color: hsl(0, 0%, 4%);
  cursor: pointer;
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable.is-within-hovered-range.is-first-hovered {
  background-color: hsl(0, 0%, 48%);
  color: hsl(0, 0%, 86%);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable.is-within-hovered-range.is-within-hovered {
  background-color: hsl(0, 0%, 96%);
  color: hsl(0, 0%, 4%);
  border-radius: 0;
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable.is-within-hovered-range.is-last-hovered {
  background-color: hsl(0, 0%, 48%);
  color: hsl(0, 0%, 86%);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
  background-color: #59c9a5;
  color: #fff;
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected.is-first-selected {
  background-color: #59c9a5;
  color: #fff;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected.is-within-selected {
  background-color: rgba(89, 201, 165, 0.5);
  border-radius: 0;
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected.is-last-selected {
  background-color: #59c9a5;
  color: #fff;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-nearby:not(.is-selected) {
  color: hsl(0, 0%, 71%);
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-week-number {
  cursor: default;
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell {
  padding: 0.3rem 0.75rem 0.75rem;
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event {
  position: relative;
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events {
  bottom: 0.425rem;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 0 0.35rem;
  position: absolute;
  width: 100%;
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-white {
  background-color: hsl(0, 0%, 100%);
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-black {
  background-color: hsl(0, 0%, 4%);
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-light {
  background-color: hsl(0, 0%, 96%);
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-dark {
  background-color: hsl(0, 0%, 21%);
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-primary {
  background-color: #59c9a5;
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-info {
  background-color: hsl(207, 61%, 53%);
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-success {
  background-color: hsl(153, 53%, 53%);
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-warning {
  background-color: hsl(44, 100%, 77%);
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-danger {
  background-color: hsl(348, 86%, 61%);
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-twitter {
  background-color: #4099ff;
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event.dots .event {
  border-radius: 50%;
  height: 0.35em;
  margin: 0 0.1em;
  width: 0.35em;
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event.bars .event {
  height: 0.25em;
  width: 100%;
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.is-selected {
  overflow: hidden;
}
.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.is-selected .events .event.is-primary {
  background-color: #93dcc4;
}
.datepicker.is-small {
  border-radius: 2px;
  font-size: 0.75rem;
}
.datepicker.is-medium {
  font-size: 1.25rem;
}
.datepicker.is-large {
  font-size: 1.5rem;
}
@media screen and (min-width: 1024px) {
  .datepicker .footer-horizontal-timepicker {
    border: none;
    padding-left: 10px;
    margin-left: 5px;
    display: flex;
  }
  .datepicker .dropdown-horizonal-timepicker {
    display: flex;
  }
  .datepicker .content-horizonal-timepicker {
    border-right: 1px solid #dbdbdb;
  }
}

.b-numberinput.field {
  margin-bottom: 0;
}
.b-numberinput.field.is-grouped div.control {
  flex-grow: 1;
  flex-shrink: 1;
}
.b-numberinput.field.has-addons.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}
.b-numberinput input[type=number]::-webkit-inner-spin-button,
.b-numberinput input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.b-numberinput input[type=number] {
  -moz-appearance: textfield;
}
.b-numberinput input[type=number] {
  text-align: center;
}
.b-numberinput .button.is-rounded {
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.select select {
  text-rendering: auto !important;
  padding-right: 2.5em;
}
.select select option {
  color: hsl(0, 0%, 29%);
  padding: 0.25em 0.5em;
}
.select select option:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.select select optgroup {
  color: hsl(0, 0%, 71%);
  font-weight: 400;
  font-style: normal;
  padding: 0.25em 0;
}
.select.is-empty select {
  color: rgba(122, 122, 122, 0.7);
}

.dropdown + .dropdown {
  margin-left: 0.5em;
}
.dropdown .background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  position: fixed;
  background-color: rgba(10, 10, 10, 0.86);
  z-index: 40;
  cursor: pointer;
}
@media screen and (min-width: 1024px) {
  .dropdown .background {
    display: none;
  }
}
.dropdown.dropdown-menu-animation .dropdown-menu {
  display: block;
}
.dropdown .dropdown-menu .dropdown-item.is-disabled, .dropdown .dropdown-menu .has-link a.is-disabled {
  cursor: not-allowed;
}
.dropdown .dropdown-menu .dropdown-item.is-disabled:hover, .dropdown .dropdown-menu .has-link a.is-disabled:hover {
  background: inherit;
  color: inherit;
}
.dropdown .dropdown-menu .has-link a {
  padding-right: 3rem;
  white-space: nowrap;
}
.dropdown.is-hoverable:not(.is-active) .dropdown-menu {
  display: none;
}
.dropdown.is-hoverable:hover .dropdown-menu {
  display: inherit;
}
.dropdown.is-expanded {
  width: 100%;
}
.dropdown.is-expanded .dropdown-trigger {
  width: 100%;
}
.dropdown.is-expanded .dropdown-menu {
  width: 100%;
}
.dropdown.is-expanded.is-mobile-modal .dropdown-menu {
  max-width: 100%;
}
.dropdown:not(.is-disabled) .dropdown-menu .dropdown-item.is-disabled, .dropdown:not(.is-disabled) .dropdown-menu .has-link a.is-disabled {
  opacity: 0.5;
}
.dropdown .navbar-item {
  height: 100%;
}
.dropdown.is-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.dropdown.is-disabled .dropdown-trigger {
  pointer-events: none;
}
.dropdown.is-inline .dropdown-menu {
  position: static;
  display: inline-block;
  padding: 0;
}
.dropdown.is-top-right .dropdown-menu {
  top: auto;
  bottom: 100%;
}
.dropdown.is-top-left .dropdown-menu {
  top: auto;
  bottom: 100%;
  right: 0;
  left: auto;
}
.dropdown.is-bottom-left .dropdown-menu {
  right: 0;
  left: auto;
}
@media screen and (max-width: 1023px) {
  .dropdown.is-mobile-modal > .dropdown-menu {
    position: fixed !important;
    width: calc(100vw - 40px);
    max-width: 460px;
    max-height: calc(100vh - 120px);
    top: 25% !important;
    left: 50% !important;
    bottom: auto !important;
    right: auto !important;
    transform: translate3d(-50%, -25%, 0);
    white-space: normal;
    overflow-y: auto;
    z-index: 50 !important;
  }
  .dropdown.is-mobile-modal > .dropdown-menu > .dropdown-content > .dropdown-item, .dropdown .dropdown-menu .has-link .dropdown.is-mobile-modal > .dropdown-menu > .dropdown-content > a, .dropdown.is-mobile-modal > .dropdown-menu > .dropdown-content > .has-link a {
    padding: 1rem 1.5rem;
  }
}

.timepicker .dropdown-menu {
  min-width: 0;
}
.timepicker .dropdown,
.timepicker .dropdown-trigger {
  width: 100%;
}
.timepicker .dropdown .input[readonly],
.timepicker .dropdown-trigger .input[readonly] {
  cursor: pointer;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
}
.timepicker .dropdown .input[readonly]:focus, .timepicker .dropdown .input[readonly].is-focused, .timepicker .dropdown .input[readonly]:active, .timepicker .dropdown .input[readonly].is-active,
.timepicker .dropdown-trigger .input[readonly]:focus,
.timepicker .dropdown-trigger .input[readonly].is-focused,
.timepicker .dropdown-trigger .input[readonly]:active,
.timepicker .dropdown-trigger .input[readonly].is-active {
  box-shadow: 0 0 0 0.125em rgba(89, 201, 165, 0.25);
}
.timepicker .dropdown.is-disabled {
  opacity: 1;
}
.timepicker .dropdown-item, .timepicker .dropdown .dropdown-menu .has-link a, .dropdown .dropdown-menu .has-link .timepicker a {
  font-size: inherit;
  padding: 0;
}
.timepicker .timepicker-footer {
  padding: 0 0.5rem 0 0.5rem;
}
.timepicker .dropdown-content .control {
  font-size: 1.25em;
  margin-right: 0 !important;
}
.timepicker .dropdown-content .control .select select {
  font-weight: 600;
  padding-right: calc(0.75em - 1px);
  border: 0;
}
.timepicker .dropdown-content .control .select select option:disabled {
  color: rgba(122, 122, 122, 0.7);
}
.timepicker .dropdown-content .control .select:after {
  display: none;
}
.timepicker .dropdown-content .control.is-colon {
  font-size: 1.7em;
}
.timepicker.is-small {
  border-radius: 2px;
  font-size: 0.75rem;
}
.timepicker.is-medium {
  font-size: 1.25rem;
}
.timepicker.is-large {
  font-size: 1.5rem;
}