.popover {
    position: absolute;
    z-index: 1060;
    top: 0;
    left: 0;
    display: block;
    max-width: 276px;
    // Allow breaking very long words so they don't overflow the popover's bounds
    word-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    background-clip: padding-box;

    .arrow {
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5em;
        margin: 0 0.3rem;

        &::before,
        &::after {
            position: absolute;
            display: block;
            content: "";
            border-style: solid;
            border-color: transparent;
        }
    }
}

.tooltip .arrow {
    border-right: 5px solid transparent;
    border-bottom: 6px solid #545669;
    border-left: 1px solid transparent;
}

.tooltip-inner {
    border-radius: 5px;
    background-color: #545669 !important;
    font-size: 0.8em;
}

.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #545669 !important;
}

.bs-popover-top {
    margin-bottom: 0.5rem;

    >.arrow {
        bottom: calc((0.5rem + 1px) * -1);

        &::before {
            bottom: 0;
            border-width: 0.5rem calc(1rem / 2) 0;
            border-top-color: rgba(0, 0, 0, 0.2);
        }

        &::after {
            bottom: 1px;
            border-width: 0.5rem calc(1rem / 2) 0;
            border-top-color: #fff;
        }
    }
}

.bs-popover-right {
    margin-left: 0.5rem;

    >.arrow {
        left: calc((#{0.5rem} + #{1px}) * -1);
        width: 0.5rem;
        height: 1rem;
        margin: 0.3rem 0; // make sure the arrow does not touch the popover's rounded corners

        &::before {
            left: 0;
            border-width: calc(1rem / 2) 0.5rem calc(1rem / 2) 0;
            border-right-color: rgba(0, 0, 0, 0.2);
        }

        &::after {
            left: 1px;
            border-width: calc(1rem / 2) 0.5rem calc(1rem / 2) 0;
            border-right-color: #fff;
        }
    }
}

.bs-popover-bottom {
    margin-top: 0.5rem;

    >.arrow {
        top: calc((#{0.5rem} + #{1px}) * -1);

        &::before {
            top: 0;
            border-width: 0 calc(1rem / 2) 0.5rem calc(1rem / 2);
            border-bottom-color: rgb(0, 0, 0, 0.2);
        }

        &::after {
            top: 1px;
            border-width: 0 calc(1rem / 2) 0.5rem calc(1rem / 2);
            border-bottom-color: #fff;
        }
    }

    // This will remove the popover-header's border just below the arrow
    .popover-header::before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 1rem;
        margin-left: -1rem / 2;
        content: "";
        border-bottom: 1px solid #fff;
    }
}

.bs-popover-left {
    margin-right: 0.5rem;

    >.arrow {
        right: calc((#{0.5rem} + #{1px}) * -1);
        width: 0.5rem;
        height: 1rem;
        margin: 0.3rem 0; // make sure the arrow does not touch the popover's rounded corners

        &::before {
            right: 0;
            border-width: calc(1rem / 2) 0 calc(1rem / 2) 0.5rem;
            border-left-color: rgb(0, 0, 0, 0.2);
        }

        &::after {
            right: 1px;
            border-width: calc(1rem / 2) 0 calc(1rem / 2) 0.5rem;
            border-left-color: #fff;
        }
    }
}

.bs-popover-auto {
    &[x-placement^="top"] {
        margin-bottom: 0.5rem;

        >.arrow {
            bottom: calc((0.5rem + 1px) * -1);


            &::before {
                bottom: 0;
                border-width: 0.5rem calc(1rem / 2) 0;
                border-top-color: rgba(0, 0, 0, 0.2);
            }

            &::after {
                bottom: 1px;
                border-width: 0.5rem calc(1rem / 2) 0;
                border-top-color: #fff;
            }
        }
    }

    &[x-placement^="right"] {
        margin-left: 0.5rem;

        >.arrow {
            left: calc((#{0.5rem} + #{1px}) * -1);
            width: 0.5rem;
            height: 1rem;
            margin: 0.3rem 0; // make sure the arrow does not touch the popover's rounded corners

            &::before {
                left: 0;
                border-width: calc(1rem / 2) 0.5rem calc(1rem / 2) 0;
                border-right-color: rgba(0, 0, 0, 0.2);
            }

            &::after {
                left: 1px;
                border-width: calc(1rem / 2) 0.5rem calc(1rem / 2) 0;
                border-right-color: #fff;
            }
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 0.5rem;

        >.arrow {
            top: calc((#{0.5rem} + #{1px}) * -1);

            &::before {
                top: 0;
                border-width: 0 calc(1rem / 2) 0.5rem calc(1rem / 2);
                border-bottom-color: rgba(0, 0, 0, 0.2);
            }

            &::after {
                top: 1px;
                border-width: 0 calc(1rem / 2) 0.5rem calc(1rem / 2);
                border-bottom-color: #fff;
            }
        }

        // This will remove the popover-header's border just below the arrow
        .popover-header::before {
            position: absolute;
            top: 0;
            left: 50%;
            display: block;
            width: 1rem;
            margin-left: -1rem / 2;
            content: "";
            border-bottom: 1px solid #fff;
        }
    }

    &[x-placement^="left"] {
        margin-right: 0.5rem;

        >.arrow {
            right: calc((#{0.5rem} + #{1px}) * -1);
            width: 0.5rem;
            height: 1rem;
            margin: 0.3rem 0; // make sure the arrow does not touch the popover's rounded corners

            &::before {
                right: 0;
                border-width: calc(1rem / 2) 0 calc(1rem / 2) 0.5rem;
                border-left-color: rgba(0, 0, 0, 0.2);
            }

            &::after {
                right: 1px;
                border-width: calc(1rem / 2) 0 calc(1rem / 2) 0.5rem;
                border-left-color: #fff;
            }
        }
    }
}

// Offset the popover to account for the popover arrow
.popover-header {
    margin-bottom: 0; // Reset the default from Reboot
    padding: 0.5rem 0.75rem;
    color: #212529;
    border-bottom: 1px solid darken(#fff, 5%);
    background-color: #fff;

    &:empty {
        display: none;
    }
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
}