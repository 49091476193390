.switch-form button {
    padding: 12px 45px;
    transition: transform 80ms ease-in;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid $primary;
    border-radius: 20px;
    background-color: $primary;
    font-size: 12px;
    font-weight: bold;
}

.switch-form .btn-outline {
    cursor: pointer;
    color: $primary;
    border-color: $primary;
    background-color: #fff;
}

.switch-form .btn-outline:hover {
    color: #fff;
    border-color: $primary;
    background-color: $primary;
}

.switch-form button:active {
    transform: scale(0.95);
}

.switch-form button:focus {
    outline: none;
}

.switch-form button.ghost {
    border-color: #fff;
    background-color: transparent;
}

.switch-form form {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 50px;
    text-align: center;
    background-color: #fff;
    align-items: center;
    justify-content: center;
}

.switch-form input {
    width: 100%;
    margin: 8px 0 4px 0;
    padding: 9px 16px;
    border: none;
    background-color: #eee;
}

.switch-form {
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
    border-radius: 10px;
    background-color: #fff;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in-container {
    z-index: 2;
    left: 0;
    width: 50%;
}

.switch-form.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

.sign-up-container {
    z-index: 1;
    left: 0;
    width: 50%;
    opacity: 0;
}

.switch-form.right-panel-active .sign-up-container {
    z-index: 5;
    transform: translateX(100%);
    animation: show 0.6s;
    opacity: 1;
}

@keyframes show {

    0%,
    49.99% {
        z-index: 1;
        opacity: 0;
    }

    50%,
    100% {
        z-index: 5;
        opacity: 1;
    }
}

.overlay-container {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 50%;
    overflow: hidden;
    width: 50%;
    height: 100%;
    transition: transform 0.6s ease-in-out;
}

.switch-form.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

.overlay {
    position: relative;
    left: -100%;
    width: 200%;
    height: 100%;
    transition: transform 0.6s ease-in-out;
    transform: translateX(0);
    color: #fff;
    background: $primary;
    background: -webkit-linear-gradient(to right, $primary, $primaryDark);
    background: linear-gradient(to right, $primary, $primaryDark);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
}

.switch-form.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding: 0 40px;
    transition: transform 0.6s ease-in-out;
    transform: translateX(0);
    text-align: center;
    align-items: center;
    justify-content: center;
}

.overlay-left {
    transform: translateX(-20%);
}

.switch-form.right-panel-active .overlay-left {
    transform: translateX(0);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.switch-form.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.social-container {
    margin: 20px 0;
}

.social-container a {
    display: inline-flex;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    border: 1px solid #ddd;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.switch-info {
    display: none;
}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .overlay-container {
        display: none;
    }

    .switch-form {
        position: initial;
    }

    .sign-in-container {
        width: 100%;
    }

    .sign-up-container {
        width: 100%;
    }

    .switch-form.right-panel-active .sign-in-container {
        transform: initial;
    }

    .switch-form.right-panel-active .sign-up-container {
        transform: initial;
    }

    .switch-info {
        display: block;
        margin-top: 50px;
    }

    .switch-form form {
        padding: 0 20px;
    }
}
