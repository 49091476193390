.notification-counter-wrapper {
    position: relative;
    width: 50px;
    height: 24px;
}

.notification-counter {
    position: absolute;
    top: -15px;
    right: 5px;
    padding: 0 12px;
    transition: opacity, -webkit-transform;
    transition: transform, opacity;
    transition: transform, opacity, -webkit-transform;
    transition-timing-function: ease-out;
    transition-duration: 0.3s;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    color: white;
    border-radius: 60px;
    background: #3498db;
    font-size: 1.5em;
    font-weight: 700;
}

.notification.notify::before {
    -webkit-animation: ring 1.5s ease;
    animation: ring 1.5s ease;
}

.notification.show-count::after {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 1;
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotate(35deg);
    }
    12.5% {
        -webkit-transform: rotate(-30deg);
    }
    25% {
        -webkit-transform: rotate(25deg);
    }
    37.5% {
        -webkit-transform: rotate(-20deg);
    }
    50% {
        -webkit-transform: rotate(15deg);
    }
    62.5% {
        -webkit-transform: rotate(-10deg);
    }
    75% {
        -webkit-transform: rotate(5deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes ring {
    0% {
        -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
    }
    12.5% {
        -webkit-transform: rotate(-30deg);
        transform: rotate(-30deg);
    }
    25% {
        -webkit-transform: rotate(25deg);
        transform: rotate(25deg);
    }
    37.5% {
        -webkit-transform: rotate(-20deg);
        transform: rotate(-20deg);
    }
    50% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }
    62.5% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
    75% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
