.boxclose {
    display: inline-block;
    padding: 11px 3px;
    cursor: pointer;
    color: #605f61;
    border: 1px solid #605f61;
    border-radius: 5px;
    font-size: 29px;
    line-height: 0;
}

.boxclose::before {
    content: "×";
}

.v--modal-overlay {
    z-index: 1100 !important;
}

.v--modal-overlay .v--modal-box {
    overflow-y: auto !important;
}
