.j-cloak {
    display: none;
}

.e-cloak {
    display: none !important;
}

.modal-open {
    overflow: inherit;
}

.content-m-top {
    margin-top: 60px;
}

.tw-z-100 {
    position: relative;
    z-index: 100;
}

/* Remove AM/PM from time input */
.without_ampm::-webkit-datetime-edit-ampm-field {
    display: none;
}

input[type=time]::-webkit-clear-button {
    margin: -10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

/* Remove date field arrows */
input[type=date]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}

#paginationLinks {
    margin-bottom: 50px;
}

/* Vue hiding helper, hides everything untill Vue is loaded */
[v-cloak] {
    display: none;
}

[v-cloak]::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 80px;
    height: 80px;
    content: " ";
    background-image: url(/images/svg/loader.svg);
    background-size: cover;
}

.text-start {
    text-align: left;
}

@media screen and (max-width: 768px) {
    .clickable-icon {
        display: block;
        font-size: 20px;
    }
}

@media screen and (max-width: 1024px) {
    .content-m-bottom {
        margin-bottom: 80px;
    }
}

@media screen and (min-width: 1024px) {
    .content-m-top {
        margin-top: 100px;
    }
}
