.ck-content {
    h2 {
        font-size: 19px;
    }

    h3 {
        font-size: 17px;
    }

    h4 {
        font-size: 15px;
    }

    p {
        font-size: 13px;
    }

    ul {
        list-style-position: inside;
        list-style-type: disc;
    }

    ol {
        list-style-position: inside;
        list-style-type: decimal;
    }

    li {
        list-style: unset;
        font-size: 13px;
    }
}
