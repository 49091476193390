#application .el-table th {
    color: #2e2d29;
    background-color: #f1f5f8;
    font-weight: 700;
}

.el-loading-spinner {
    left: 50%;
}

#application .pagination-bar {
    overflow-y: auto;
}

#application .el-date-editor.el-input,
#application .el-date-editor.el-input__inner {
    width: initial;
    border-radius: 0.25em;
}

#application .el-el-date-editor input {
    display: block;
    padding: 8px 8px 8px 30px;
    color: #4a5568;
    border-width: 1px;
    border-color: #edf2f7;
    border-radius: 0.25rem;
    background-color: #edf2f7;
    font-size: 0.875rem;
    line-height: 1.25;
}

#application button:focus {
    outline: 0;
}

#application .theme--light.v-application {
    background: transparent;
}

#application .v-application a {
    color: #2c2c2c;
}

#application .v-application ol,
#application .v-application ul {
    padding-left: 0;
}

#application .v-application p {
    margin-bottom: 3px;
}
