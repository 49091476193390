/*
 * Standards
 */
.max-h-400 {
    max-height: 400px;
}

/*
 * Lay-out
 */
.bg-framework {
    @apply tw-bg-white tw-rounded-lg tw-shadow #{!important};
}

.bg-framework-secondary {
    @apply tw-bg-gray-100 tw-rounded-lg tw-shadow #{!important};
}

.pin-x-auto {
    left: 50%;
    transform: translateX(-50%);
}

.pin-y-auto {
    top: 50%;
    transform: translateY(-50%);
}

.pin-xy-auto {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sm\:pin-x-auto {
    left: 50%;
    transform: translateX(-50%);
}

.sm\:pin-y-auto {
    top: 50%;
    transform: translateY(-50%);
}

.sm\:pin-xy-auto {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.md\:pin-x-auto {
    left: 50%;
    transform: translateX(-50%);
}

.md\:pin-y-auto {
    top: 50%;
    transform: translateY(-50%);
}

.md\:pin-xy-auto {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lg\:pin-x-auto {
    left: 50%;
    transform: translateX(-50%);
}

.lg\:pin-y-auto {
    top: 50%;
    transform: translateY(-50%);
}

.lg\:pin-xy-auto {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.xl\:pin-x-auto {
    left: 50%;
    transform: translateX(-50%);
}

.xl\:pin-y-auto {
    top: 50%;
    transform: translateY(-50%);
}

.xl\:pin-xy-auto {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*
 * Form Components
 */
#application .input-field {
    @apply tw-appearance-none tw-bg-primary-200 tw-rounded tw-w-full tw-p-2 tw-text-primary-900 tw-border tw-border-primary-200 tw-text-sm #{!important};
}

#application .input-field-tw {
    @apply tw-appearance-none tw-block tw-w-full tw-bg-gray-200 tw-text-gray-700 p-2 tw-text-sm tw-rounded #{!important};
}

#application .input-field-tw-ext {
    @apply tw-appearance-none tw-block tw-w-full tw-bg-gray-300 tw-text-gray-700 p-2 tw-text-sm tw-rounded #{!important};
}

#application .input-field-tw-ext:focus {
    @apply tw-outline-none tw-bg-gray-200 #{!important};
}

#application .input-field-tw:focus {
    background-color: #e2e8f0 !important;
}

#application .input {
    font-size: 0.92rem !important;
}

#application .label-tw {
    @apply tw-tracking-wide tw-text-gray-900 tw-mb-1 tw-text-sm #{!important};

    @screen lg {
        @apply tw-text-sm #{!important};
    }
}

#application .label-tw .label {
    font-size: inherit;
}

#application .label-tw .input {
    display: block;
    width: 100%;
    height: 35px;
    padding: 0.5rem;
    color: #4a5568;
    border-width: 1px;
    border-color: #edf2f7;
    border-radius: 0.25rem;
    background-color: #edf2f7;
    font-size: 0.875rem;
    line-height: 1.25;
}

#application .label-tw .input:hover {
    border: none !important;
}

#application .label-tw .input:active,
#application .label-tw .input:focus {
    border: none !important;
    background-color: #fff !important;
}

#application .label-tw .label:not(:last-child) {
    margin-bottom: 0.22em !important;
}

/*
 * Visual
 */
.scroll-height {
    height: 500px;
    @screen lg {
        height: 250px;
    }
}

/*
 * Buttons
 */
.btn-primary {
    @apply tw-bg-primary-500 tw-p-2 tw-text-white #{!important};
}

.btn-primary:hover {
    @apply tw-opacity-75 #{!important};
}

.btn-outline-primary {
    @apply tw-text-primary-900 tw-border-2 tw-p-2 tw-border-primary-500 tw-outline-none tw-rounded #{!important};
}

.btn-outline-primary:hover {
    @apply tw-bg-primary-500 tw-text-white tw-p-2 tw-border-primary-500 tw-outline-none #{!important};
}

.btn-outline-primary:focus {
    @apply tw-bg-primary-500 tw-text-white tw-p-2 tw-border-primary-500 tw-outline-none #{!important};
}

.btn-blue {
    @apply tw-bg-blue-500 tw-p-2 tw-text-white #{!important};
}

.btn-blue:hover {
    @apply tw-opacity-75 #{!important};
}

.btn-outline-blue {
    @apply tw-text-blue-500 tw-border tw-p-2 tw-border-blue-500 #{!important};
}

.btn-outline-blue:hover {
    @apply tw-bg-blue-500 tw-text-white tw-p-2 tw-border-blue-500 #{!important};
}

.btn-red-secondary {
    @apply tw-bg-red-100 tw-p-2 tw-text-red-700 #{!important};
}

.btn-red-secondary:hover {
    @apply tw-opacity-75 #{!important};
}

.tw-link {
    @apply tw-text-primary-500 #{!important};
}

.tw-link:hover {
    opacity: 0.7 !important;
}

.min-height-block {
    min-height: 250px;
}

@media (min-width: 1024px) {
    .min-height-block {
        min-height: 150px;
    }
}

/*
 * Colors
 */
.male {
    @apply tw-bg-blue-200 #{!important};
}

.female {
    @apply tw-bg-red-200 #{!important};
}

.male-color {
    @apply tw-text-blue-200 #{!important};
}

.male-color-strong {
    color: #6699ff;
}

.female-color {
    @apply tw-text-red-200 #{!important};
}

.female-color-strong {
    color: #ff99cc;
}

.blue {
    @apply tw-bg-blue-200 #{!important};
}

.pink {
    @apply tw-bg-red-200 #{!important};
}

/*
 * Charts
 */
.tw-node {
    width: 170px;
    min-height: 170px;

    @apply tw-p-1 tw-border tw-border-gray-600 tw-rounded #{!important};
}

/*
 * Fonts
 */
.tw-font-theme {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: 16px !important;
}

.v-application {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: 16px !important;
}
